import Select from 'react-select';
import styles from "./Dashboard.module.css"

import ReactAutocomplete from "react-google-autocomplete";

const PopUpAccount = ({ param, popUpPage, popUpControls, validationError, newAccountData, handleNewAccountData, accountContacts, handleNewAccountContacts, handleNewAccountAddress, newAccountAddress }) => {
	return (
		<div className={`${styles.popup_container}`}>

			<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

			{popUpPage == 1 &&
				<>
					<h1 className={styles.medium_font}>Account details</h1>
					<p className={styles.smaller_font}>Please enter the general details for this account.</p>

					<div className={`${styles.popup_form}`}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Company</label>
							<input type="text" name="company" value={newAccountData.company} placeholder="Seek - Laverton" onChange={(input) => handleNewAccountData("company", input)}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>ABN</label>
							<input type="text" name="abn" value={newAccountData.abn} placeholder="51 824 753 556" onChange={(input) => handleNewAccountData("abn", input)}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Primary name</label>
							<input type="text" name="pcontact" value={newAccountData.pcontact} placeholder="John Doe" onChange={(input) => handleNewAccountData("pcontact", input)}/>
						</div>


						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Primary email</label>
							<input type="text" name="email" value={newAccountData.email} placeholder="johndoe@seek.com" onChange={(input) => handleNewAccountData("email", input)} disabled={newAccountData['edit']}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Primary phone</label>
							<input type="text" name="phone" value={(newAccountData && newAccountData.phone) && newAccountData.phone} placeholder="1300 222 222" onChange={(input) => handleNewAccountData("phone", input)}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Invoice cycle</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "Weekly", value: "Weekly"}, {label: "Fortnightly", value: "Fortnightly"}, {label: "Monthly", value: "Monthly"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewAccountData('invoice_cycle', selected)}
								value={{label: newAccountData["invoice_cycle"], value: newAccountData["invoice_cycle"]}}
							/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Payment Terms</label>
							<Select
								closeMenuOnSelect={true}
								options={[{label: "7 days", value: "7 days"}, {label: "14 days", value: "14 days"}, {label: "30 days", value: "30 days"}]}
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										boxShadow: 'none !important',
									   '&:hover': {
									       borderColor: '#fce7eb !important'
									    },
										borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
									}),
								}}
								onChange={(selected) => handleNewAccountData('payment_terms', selected)}
								value={{label: newAccountData["payment_terms"], value: newAccountData["payment_terms"]}}
							/>
						</div>
					</div>

				</>
			}

			{popUpPage == 2 &&
				<>
					<h1 className={styles.medium_font}>Billing address</h1>
					<p className={styles.smaller_font}>Please enter the billing address for this account.</p>

					<div className={`${styles.popup_form}`}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Billing address</label>
							<ReactAutocomplete
							  apiKey="AIzaSyAPGR-rJOJE8h4YwVPlgJQjnUEzJcOQqb4"
							  onPlaceSelected={(place) => handleNewAccountAddress(place)}
							  options={{
							    types: ["address"],
							    componentRestrictions: { country: "au" },
							  }}
							  placeholder="Start typing your address..."
							  value={(newAccountAddress && newAccountAddress.hasOwnProperty('formatted_address')) && newAccountAddress.formatted_address}
							/>
						</div>
					</div>
				</>
			}

			{accountContacts.length > 0 &&
			<>
				{popUpPage === 3 &&
					<>
						<h1 className={styles.medium_font}>Secondary details</h1>
						<p className={styles.smaller_font}>Please enter the secondary contact details for this account.</p>

						<div className={`${styles.popup_form}`}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" name="first_name" value={accountContacts.find(acc => acc.type === "Secondary") && accountContacts.find(acc => acc.type === "Secondary").first_name} placeholder="John" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Secondary")), "first_name", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" name="last_name" value={accountContacts.find(acc => acc.type === "Secondary") && accountContacts.find(acc => acc.type === "Secondary").last_name} placeholder="Doe" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Secondary")), "last_name", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" name="email" value={accountContacts.find(acc => acc.type === "Secondary") && accountContacts.find(acc => acc.type === "Secondary").email} placeholder="johndoe@company.com.au" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Secondary")), "email", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" name="phone" value={accountContacts.find(acc => acc.type === "Secondary") && accountContacts.find(acc => acc.type === "Secondary").phone} placeholder="1300 222 333" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Secondary")), "phone", input)}/>
							</div>
						</div>

					</>
				}

				{popUpPage == 4 &&
					<>
						<h1 className={styles.medium_font}>Accountant details</h1>
						<p className={styles.smaller_font}>Please enter the accountant contact details for this account.</p>

						<div className={`${styles.popup_form}`}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>First name</label>
								<input type="text" name="first_name" value={accountContacts.find(acc => acc.type === "Accountant") && accountContacts.find(acc => acc.type === "Accountant").first_name} placeholder="John" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Accountant")), "first_name", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Last name</label>
								<input type="text" name="last_name" value={accountContacts.find(acc => acc.type === "Accountant") && accountContacts.find(acc => acc.type === "Accountant").last_name} placeholder="Doe" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Accountant")), "last_name", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Email</label>
								<input type="text" name="email" value={accountContacts.find(acc => acc.type === "Accountant") && accountContacts.find(acc => acc.type === "Accountant").email} placeholder="johndoe@company.com.au" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Accountant")), "email", input)}/>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Phone</label>
								<input type="text" name="phone" value={accountContacts.find(acc => acc.type === "Accountant") && accountContacts.find(acc => acc.type === "Accountant").phone} placeholder="1300 222 333" onChange={(input) => handleNewAccountContacts(accountContacts.indexOf(accountContacts.find(acc => acc.type === "Accountant")), "phone", input)}/>
							</div>
						</div>

					</>
				}
			</>
			}

			{(accountContacts.length == 0 && popUpPage == 3) &&
				<>
					<h1 className={styles.medium_font}>Confirm details</h1>
					<p className={styles.smaller_font}>Please confirm all the details you have entered.</p>

					<div className={`${styles.notification} ${styles.warningMessage}`}>
						<p className={styles.smaller_font}>An email will be sent to {newAccountData.email}, prompting them to complete the sign-up process.</p>
					</div>

					<div className={`${styles.popup_form}`}>
						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Company</label>
							<input type="text" value={newAccountData.company} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>ABN</label>
							<input type="text" value={newAccountData.abn} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Primary name</label>
							<input type="text" value={newAccountData.pcontact} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Primary email</label>
							<input type="text" value={newAccountData.email} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Primary phone</label>
							<input type="text" value={newAccountData.phone} disabled={true}/>
						</div>

						<div className={styles.popup_formbox}>
							<label className={styles.smaller_font}>Invoice cycle</label>
							<input type="text" value={newAccountData.invoice_cycle} disabled={true}/>
						</div>
					</div>
				</>
			}

			{validationError &&
				<div className={`${styles.notification} ${styles.validationError}`}>
					<p className={styles.smaller_font}>{validationError}</p>
				</div>
			}

			<div className={styles.popUpControls}>
				<div className={styles.popUpControlsContainer}>
		      		{popUpPage != 1 &&
		      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
		      		}

		      		{accountContacts.length > 0
		      		?
		      			<>
			      		{popUpPage != 4 &&
			      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
			      		}

			      		{popUpPage == 4 &&
			      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
			       		}
			       		</>
			       	:
			       		<>
			      		{popUpPage != 3 &&
			      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
			      		}

			      		{popUpPage == 3 &&
			      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
			       		}
			       		</>
			       	}
	       		</div>
       		</div>


		</div>
	)
}

export default PopUpAccount;