import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import 'animate.css';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs'
import moment from 'moment-timezone';

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ofglogo from '../../../images/logo.png'
import styles from "./Dashboard.module.css"

import NavigationBar from '../Utils/NavigationBar'
import PopUpAccount from './PopUpAccount';
import PopUpSite from './PopUpSite';
import PopUpOrder from './PopUpOrder';
import PopUpPreferences from './PopUpPreferences';
import PopUpEnquiry from './PopUpEnquiry';
import PopUpInteraction from './PopUpInteraction';

import PopUpIndAccount from './PopUpIndAccount';
import PopUpEmailChange from './PopUpEmailChange';
import PopUpIndSite from './PopUpIndSite';
import PopUpIndOrder from './PopUpIndOrder';
import PopUpIndPreference from './PopUpIndPreference';
import PopUpIndEnquiry from './PopUpIndEnquiry';
import PopUpIndInteraction from './PopUpIndInteraction';

import PopUpEmail from './PopUpEmail';
import PopUpInteractionHelp from './PopUpInteractionHelp'
import PopUpTempCancel from './PopUpTempCancel'
import PopUpAllergies from './PopUpAllergies'
import PopUpSiteGroups from './PopUpSiteGroups'
import PopUpClone from './PopUpClone'
import PopUpCustomPricing from './PopUpCustomPricing'
import PopUpFlagged from './PopUpFlagged'
import PopUpPOLimit from './PopUpPOLimit'
import PopUpFlaggedPO from './PopUpFlaggedPO'
import PopUpCredit from './PopUpCredit'
import PopUpChristmasClosures from './PopUpChristmasClosures';


const DashboardPage = ({ param, allAccounts, allSites, allOrders, allPreferences, navType, changeNavType, formatDate, nextDeliveryDate, popUpControls, accountPopup, sitePopup, orderPopup, currentAccount, currentSite, preferencesPopup, indPopUpControls, indAccountPopup, indSitePopup, indOrderPopup, indPreferencePopup, enquiryPopup, allEnquiries, interactionPopup, allInteractions, indEnquiryPopup, indInteractionPopup, searchControl, emailChange, showInteractionHelp, getTotalQuantity, isUserBirthday, accountData, showFlagged, flaggedAvailable, handleFlagged, showPOLimit, flaggedPO, showFlaggedPO, showCredits, axios_config, setAllAccounts, setFlaggedPOAccounts, flaggedPOAccounts, setFlaggedPO, setAllSites, setAllOrders, setAllOrdersOG, setAllPreferences, handleSearch, searchLoading, searchResults, searchOpen, setSearchOpen, setSearchLoading, setSearchResults, searchText, setSearchText }) => {
	const [tableLoading, setTableLoading] = useState(false);
	const [finishingNext, setFinishingNext] = useState(false);
	const [prevScrollTop, setPrevScrollTop] = useState(0);
	const searchContainerRef = useRef(null);
	const lastRowRef = useRef(null);
	const tbodyRef = useRef(null);
	let isLast;

	// Load next 50 when end of results
  	useEffect(() => {
		const accountMap = {
		  Accounts: allAccounts,
		  Sites: allSites,
		  Orders: allOrders,
		  Preferences: allPreferences
		};

		let accountToUse = accountMap[navType] || allAccounts;

    	if (lastRowRef.current && tbodyRef.current) {
      		const observer = new IntersectionObserver((entries) => {
          		entries.forEach(entry => {
            		if (entry.isIntersecting) {
            			const fetchData = async () => {
            				setTableLoading(true)
            				const previousScrollTop = tbodyRef.current.scrollTop

            				const response = await axios.get(`/api/admin/dashboard/main/${param._id}/${navType}/${false}/${accountToUse.length}`, axios_config);
            				if (response.data.finishingNext) {
            					setFinishingNext(true)
            				}

            				switch (navType) {
	            				case 'Accounts':
		            				setAllAccounts(prevAccounts => [...prevAccounts, ...response.data.allAccounts]);
	            					break;
	            				case 'Sites':
									setAllSites(prevSites => [...prevSites, ...response.data.allSites]);
	            					break;
	            				case 'Orders':
									setAllOrders(prevOrders => [...prevOrders, ...response.data.allOrders]);
									setAllOrdersOG(prevOrders => [...prevOrders, ...response.data.allOrders]);
	            					break;
	            				case 'Preferences':
									setAllPreferences(prevPreferences => [...prevPreferences, ...response.data.allPreferences]);
	            					break;
            				}

							setTableLoading(false)
				            setTimeout(() => {
				              	tbodyRef.current.scrollTop = previousScrollTop;
				            }, 0);
            			}
            			
            			if (!finishingNext) {
            				fetchData();
            			}
            		}
          		});
        	}, { threshold: 1.0 });

      		observer.observe(lastRowRef.current);
      		return () => observer.disconnect();
    	}
  	}, [lastRowRef.current, navType, allAccounts, allSites, allOrders, allPreferences]);

  	useEffect(() => {
    	document.addEventListener('mousedown', handleClickOutside);

    	return () => {
      		document.removeEventListener('mousedown', handleClickOutside);
    	};
  	}, []);

  	const handleClickOutside = (event) => {
    	if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
    		setSearchResults([])
    		setSearchOpen(false)
    		setSearchLoading(false)
    		setSearchText(null)
    	}
  	};

	return (
		<div className={`${styles.page_container} ${(accountPopup || sitePopup || orderPopup || preferencesPopup || indAccountPopup || indSitePopup || indOrderPopup || indPreferencePopup || enquiryPopup || interactionPopup || indEnquiryPopup || indInteractionPopup || emailChange || showInteractionHelp || showFlagged || showPOLimit || showFlaggedPO || showCredits) && styles.hidden_container} ${(isUserBirthday(accountData.dob) || isUserBirthday(accountData.work_anniversary)) && styles.page_container_birthday}`}>
			<div className={`${(flaggedAvailable || flaggedPO) ? styles.sites_container_with_warning : styles.sites_container}`}>

				{(flaggedAvailable || flaggedPO) && 
					<div className={styles.flaggedContainer}>
						{flaggedAvailable && 
							<button className={styles.flaggedOrdersWarning} onClick={() => handleFlagged('open')}>
								<i className={`fa-solid fa-flag ${styles.smaller_font}`}></i>
								<p className={styles.medium_font}>There are currently orders/preferences with items included that are no longer in the seasonal menu. Click to view them.</p>
							</button>
						}

						{flaggedPO &&
							<button className={styles.flaggedOrdersWarning} onClick={() => handleFlagged('open_po')}>
								<i className={`fa-solid fa-flag ${styles.smaller_font}`}></i>
								<p className={styles.medium_font}>There are currently accounts with PO limits that have reached 90% or more. Click to view them.</p>
							</button>
						}
					</div>
				}

				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						
						{navType == 'Accounts' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add account</p>
							</button>
						}
						{navType == 'Sites' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add site</p>
							</button>
						}
						{navType == 'Orders' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add order</p>
							</button>
						}
						{navType == 'Preferences' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add preference</p>
							</button>
						}
						{navType == 'Enquiries' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add enquiry</p>
							</button>
						}
						{navType == 'Interactions' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add interaction</p>
							</button>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Accounts' && styles.sitenav_active}`} onClick={() => changeNavType('Accounts')}><p>Accounts</p></button>
						<button className={`${styles.sitenav_box} ${navType == 'Sites' && styles.sitenav_active}`} onClick={() => changeNavType('Sites')}><p>Sites</p></button>
						<button className={`${styles.sitenav_box} ${navType == 'Orders' && styles.sitenav_active}`} onClick={() => changeNavType('Orders')}><p>Orders</p></button>
						<button className={`${styles.sitenav_box} ${navType == 'Preferences' && styles.sitenav_active}`} onClick={() => changeNavType('Preferences')}><p>Preferences</p></button>
						<button className={`${styles.sitenav_box} ${navType == 'Interactions' && styles.sitenav_active}`} onClick={() => changeNavType('Interactions')}><p>Interactions</p></button>
						<button className={`${styles.sitenav_box} ${navType == 'Enquiries' && styles.sitenav_active}`} onClick={() => changeNavType('Enquiries')}><p>Enquiries</p></button>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder=
										{navType === "Accounts"
											? "Search for account code, company, email or status..."
										: navType === "Sites"
											? "Search for site code, site name, street or status..."
										: navType === "Orders"
											? "Search for order code, product, delivery date (mm/dd/yyyy) or status..."
										: navType === "Preferences"
											? "Search for preference code, product, delivery day, frequency or status..."
										: navType === "Interactions"
											? "Search for contact name, email, phone or comment..."
										: navType === "Enquiries"
											? "Search for company, contact name, street or reason..."
											: "Search for any of the column headings..."
										}

								    onKeyUp={(input) => handleSearch(input)}
								    defaultValue={searchText ? searchText : ""}
								/>
							</div>
						</div>

						{searchOpen &&
							<div className={styles.search_main_container} ref={searchContainerRef}>
								{searchLoading
									?
										<>
											{(Array.from({ length: 8 }, (_, i) => i + 1)).map((_) => (
												<div className={styles.search_result}>
													<div className={styles.search_placeholder_icon}></div>

													<div className={`${styles.search_result_content} ${styles.search_result_placeholder_content}`}>
														<div className={styles.search_placeholder_main}></div>
														<div className={styles.search_placeholder_sub}></div>
													</div>
												</div>
											))}
										</>
									:
										<>
											{searchResults.length > 0
												?
													<>
														{searchResults.map((result) => (
															<button className={styles.search_result} onClick={() => indPopUpControls('open', result)}>
																<div className={styles.search_placeholder_icon}>
																	{navType === "Accounts"
																		? <i className={`fa-solid fa-user ${styles.big_font}`}></i>
																	: navType === "Sites"
																		? <i className={`fa-solid fa-building ${styles.big_font}`}></i>
																	: navType === "Orders"
																		? <i className={`fa-solid fa-box-open ${styles.big_font}`}></i>
																	: navType === "Preferences"
																		? <i className={`fa-solid fa-cart-shopping ${styles.big_font}`}></i>
																	: navType === "Interactions"
																		? <i className={`fa-solid fa-comments ${styles.big_font}`}></i>
																	: navType === "Enquiries"
																		? <i className={`fa-solid fa-envelopes-bulk ${styles.big_font}`}></i>
																		: <i className={`fa-solid fa-user ${styles.big_font}`}></i>
																	}
																</div>

																<div className={styles.search_result_content}>
																	<div className={styles.search_result_maincontent}>
																		<div className={styles.search_val_main}>
																			{navType === "Accounts"
																				? <p className={styles.smallish_font}>{result.company} ({result.account_code})</p>
																			: navType === "Sites"
																				? <p className={styles.smallish_font}>{result.site_name} ({result.site_code})</p>
																			: navType === "Orders"
																				? <p className={styles.smallish_font}>{result.order_code} - {formatDate(result.delivery_date, false)}</p>
																			: navType === "Preferences"
																				? <p className={styles.smallish_font}>{result.preference_code} | {result.delivery_day} - {result.frequency}</p>
																			: navType === "Interactions"
																				? <p className={styles.smallish_font}>{result.contact.first_name} {result.contact.last_name} | {result.contact.phone}</p>
																			: navType === "Enquiries"
																				? <p className={styles.smallish_font}>{result.company} | {result.contact.first_name} {result.contact.last_name}</p>
																				: <p className={styles.smallish_font}>{result.company} ({result.account_code})</p>
																			}
																		</div>

																		<div className={styles.search_val_sub}>
																			{navType === "Accounts"
																				? <p className={styles.small_font}>{result.email}</p>
																			: navType === "Sites"
																				? <p className={styles.small_font}>{(result.site_location.hasOwnProperty('level') && result.site_location.level) && `${/\d/.test(result.site_location.level) ? "Level " : ""}${result.site_location.level}, `}{result.site_location.street}, {result.site_location.city} {result.site_location.state} {result.site_location.postcode}, {result.site_location.country}</p>
																			: navType === "Orders"
																				? <p className={styles.small_font}>{result.order_type} - {result.total_qty} items</p>
																			: navType === "Preferences"
																				? <p className={styles.small_font}>{result.preference_type} - {result.total_qty} items</p>
																			: navType === "Interactions"
																				? <p className={styles.small_font}>{result.contact.email}</p>
																			: navType === "Enquiries"
																				? <p className={styles.small_font}>{result.contact.email}</p>
																				: <p className={styles.small_font}>{result.email}</p>
																			}
																		</div>
																	</div>

																	<div className={styles.search_val_right}>
														    			<div className={`${styles.site_status} ${styles.marginOffset} ${result.status === "Pending" && styles.status_pending} ${(result.status === "Active" || result.status === "Completed") && styles.status_active} ${result.status === "Inactive" && styles.status_inactive}`}>
														    				<p className={styles.smaller_font}>{result.status}</p>
														    			</div>
																	</div>
																</div>
															</button>
														))}
													</>
												: <p className={`${styles.no_results}`}>We could not find any results! Try something else...</p>
											}
										</>
								}
							</div>
						}
					</div>

					<div className={`${styles.table_container} ${(searchOpen) && styles.hidden_container}`}>
						<table className={styles.sites_table} id="main_table" align="center">						    
						    {navType == "Accounts" &&
						    	<>
								    <thead>
								      	<tr className={styles.table_rowth}>
								      		<th>Account code</th>
								      		<th>Company</th>
								        	<th>Email</th>
								        	<th>Primary contact</th>
								        	<th>Status</th>
								      	</tr>
								    </thead>

						    		<tbody ref={tbodyRef}>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
												    {(allAccounts && allAccounts.length > 0) &&
												    	<>
														    {allAccounts.filter(acc => acc.role == "Account").map((AA, key) => {
														    	isLast = key === allAccounts.filter(acc => acc.role == "Account").length - 1;

														    	return (
														    		<tr onClick={() => indPopUpControls('open', AA)} key={key} ref={isLast ? lastRowRef : null}>
															    		<td>{AA.account_code}</td>
															    		<td>{AA.company}</td>
															    		<td>{AA.email}</td>
															    		<td>{AA.contacts.length > 0 ? AA.contacts[0].first_name + ' ' + AA.contacts[0].last_name : '-'}</td>
															    		<td className={styles.site_statustd}>
															    			<div className={`${styles.site_status} ${AA.status == "Pending" && styles.status_pending} ${(AA.status == "Active" || AA.status == "Completed") && styles.status_active} ${AA.status == "Inactive" && styles.status_inactive}`}>
															    				<p className={styles.smaller_font}>{AA.status}</p>
															    			</div>
															    		</td>
															    	</tr>
														    	)
															})}
														</>
													}
												</>
										}
									</tbody>
								</>
							}

						    {navType == "Sites" &&
						    	<>
							    	<thead>
							      		<tr className={styles.table_rowth}>
							      			<th colspan="1">Site code</th>
							      			<th colspan="2">Site name</th>
							        		<th colspan="2">Address</th>
							        		<th colspan="1">Status</th>
							      		</tr>
							    	</thead>
						    		<tbody ref={tbodyRef}>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
										    		{(allSites && allSites.length > 0)
										    			?
										    				<>
											    				{allSites.map((AS, key) => {
											    					isLast = key === allSites.length - 1;

															    	return (
															    		<tr onClick={() => indPopUpControls('open', AS)} key={key} ref={isLast ? lastRowRef : null}>
																    		<td colspan="1">{AS.site_code}</td>
																    		<td colspan="2">{AS.site_name}</td>
															    			<td colspan="2">{(AS.site_location.hasOwnProperty('level') && AS.site_location.level) && `${/\d/.test(AS.site_location.level) ? "Level " : ""}${AS.site_location.level}, `}{AS.site_location.street}, {AS.site_location.city}</td>
																    		<td colspan="1" className={styles.site_statustd}>
																    			<div className={`${styles.site_status} ${AS.status == "Pending" && styles.status_pending} ${(AS.status == "Active" || AS.status == "Completed") && styles.status_active} ${AS.status == "Inactive" && styles.status_inactive}`}>
																    				<p className={styles.smaller_font}>{AS.status}</p>
																    			</div>
																    		</td>
																    	</tr>
															    	)
																})}
															</>
														: <h1 className={styles.create_first}>This account does not have a site yet!</h1>
													}
												</>
										}
									</tbody>
								</>
							}

						    {navType == "Orders" &&
						    	<>
							    	<thead>
							      		<tr className={styles.table_rowth}>
							      			<th>Order code</th>
							      			<th>Order type</th>
							        		<th>Delivery date</th>
							        		<th>Product</th>
							        		<th>Quantity</th>
							        		<th>Status</th>
							      		</tr>
							    	</thead>

						    		<tbody ref={tbodyRef}>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
										    		{(allOrders && allOrders.length > 0)
										    			?
										    				<>
											    				{allOrders.map((AO, key) => {
											    					isLast = key === allOrders.length - 1;

											    					return (
											    						<tr onClick={() => indPopUpControls('open', AO)} key={key} ref={isLast ? lastRowRef : null}>
																    		<td>{AO.order_code}</td>
																    		<td>{(AO.recurring && "Ongoing") || (AO.recurring_temp && "Temporary") || (AO.onetime && "One-time")}</td>

																    		<td>{formatDate(AO.delivery_date, false)}</td>
																    		<td>{AO.order_type}</td>
																    		<td>{AO.total_qty} items</td>
																    		<td className={styles.site_statustd}>
																    			<div className={`${styles.site_status} ${AO.status == "Pending" && styles.status_pending} ${(AO.status == "Active" || AO.status == "Completed") && styles.status_active} ${AO.status == "Inactive" && styles.status_inactive}`}>
																    				<p className={styles.smaller_font}>{AO.status}</p>
																    			</div>
																    		</td>
												    					</tr>
											    					)
																})}
															</>
														: <h1 className={styles.create_first}>No orders available!</h1>
													}
												</>
										}
									</tbody>
								</>
							}

						    {navType == "Preferences" &&
						    	<>
							    	<thead>
							      		<tr className={styles.table_rowth}>
							      			<th>Order code</th>
							      			<th>Next delivery</th>
							      			<th>Frequency</th>
							        		<th>Product</th>
							        		<th>Quantity</th>
							        		<th>Status</th>
							      		</tr>
							    	</thead>
						    		<tbody ref={tbodyRef}>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
										    		{(allPreferences && allPreferences.length > 0)
										    			?
										    				<>
											    				{allPreferences.map((AP, key) => {
											    					isLast = key === allPreferences.length - 1;

											    					return (
											    						<tr onClick={() => indPopUpControls('open', AP)} key={key} ref={isLast ? lastRowRef : null}>
																    		<td>{AP.preference_code}</td>
																    		<td>{AP.delivery_day && formatDate(nextDeliveryDate(AP.delivery_day, AP.commencement_date, AP.last_generated), true) || '-'}</td>
																    		<td>{AP.frequency}</td>
																    		<td>{AP.preference_type}</td>
																    		<td>{getTotalQuantity(AP)} items</td>
																    		<td className={styles.site_statustd}>
																    			<div className={`${styles.site_status} ${AP.status == "Pending" && styles.status_pending} ${(AP.status == "Active" || AP.status == "Completed") && styles.status_active} ${AP.status == "Inactive" && styles.status_inactive}`}>
																    				<p className={styles.smaller_font}>{AP.status}</p>
																    			</div>
																    		</td>
																    	</tr>
											    					)
																})}
															</>
														: <h1 className={styles.create_first}>No preferences available!</h1>
													}
												</>
										}
									</tbody>
								</>
							}

						    {navType == "Enquiries" &&
						    	<>

							    <thead>
							      <tr className={styles.table_rowth}>
							      	<th>Company</th>
							      	<th>Enquiry contact</th>
							      	<th>Address</th>
							        <th>Reason</th>
							        <th>Date created</th>
							      </tr>
							    </thead>
						    	<tbody>
							    {(allEnquiries && allEnquiries.length > 0)
							    	?
							    	<>
								    {allEnquiries.map((AE, key) => {
								    	return (
								    		<tr onClick={() => indPopUpControls('open', AE)} key={key}>
									    		<td>{AE.company}</td>
									    		<td>{AE.contact.first_name} {AE.contact.last_name}</td>
									    		<td>{AE.address}</td>
								    			<td>{AE.reason}</td>
								    			<td>{formatDate(AE.date_created)}</td>
									    	</tr>
								    	)
									})}
									</>
									: <h1 className={styles.create_first}>There have been no enquiries created yet</h1>
								}
								</tbody>
								</>
							}

						    {navType == "Interactions" &&
						    	<>

							    <thead>
							      <tr className={styles.table_rowth}>
							      	<th>Enquiry contact</th>
							      	<th>Enquiry email</th>
							      	<th>Enquiry phone</th>
							        <th>Comment</th>
							      </tr>
							    </thead>
						    	<tbody>
							    {(allInteractions && allInteractions.length > 0)
							    	?
							    	<>
								    {allInteractions.map((II, key) => {
								    	return (
								    		<tr onClick={() => indPopUpControls('open', II)} key={key}>
									    		<td>{II.contact.first_name} {II.contact.last_name}</td>
									    		<td>{II.contact.email}</td>
									    		<td>{II.contact.phone}</td>
								    			<td>{II.comment}</td>
									    	</tr>
								    	)
									})}
									</>
									: <h1 className={styles.create_first}>There have been no interactions created yet</h1>
								}
								</tbody>
								</>
							}
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

const Dashboard = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		localStorage.removeItem("session_token");
		window.location = "/login";
	};

  	const isExternal = sessionStorage.getItem('arrivedExternally') === 'true';
  	const navigationData = JSON.parse(sessionStorage.getItem('navData'));

	const [isLoading, setIsLoading] = useState(true);
	const [indLoading, setIndLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [allAccounts, setAllAccounts] = useState(null);
	const [allSites, setAllSites] = useState(null);
	const [allSitesOG, setAllSitesOG] = useState(null);
	const [allOrders, setAllOrders] = useState(null);
	const [allOrdersOG, setAllOrdersOG] = useState(null);
	const [allPreferences, setAllPreferences] = useState(null);
	const [inventoryData, setInventoryData] = useState(null);
	const [menuData, setMenuData] = useState(null);
	const [allEnquiries, setAllEnquiries] = useState(null);
	const [allInteractions, setAllInteractions] = useState(null);
	const [allTiers, setAllTiers] = useState(null)
	const [changingStatus, setChangingStatus] = useState(false);
	const [key, setKey] = useState(0);
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	const [isSuperAdmin, setIsSuperAdmin] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const [isAccount, setIsAccount] = useState(false)
	const [isAuthenticated, setIsAuthenticated] = useState(false);

 	const [navType, setNavType] = useState('Accounts');
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);
 	const [currentAccount, setCurrentAccount] = useState(null)
 	const [currentSite, setCurrentSite] = useState(null)
 	const [flaggedAvailable, setFlaggedAvailable] = useState(false)
 	const [showFlagged, setShowFlagged] = useState(false)
 	const [flagNav, setFlagNav] = useState("Orders")
	const [flaggedOrders, setFlaggedOrders] = useState([])
	const [flaggedPreferences, setFlaggedPreferences] = useState([])
	const [flaggedPO, setFlaggedPO] = useState(false)
	const [showFlaggedPO, setShowFlaggedPO] = useState(false)
	const [flaggedPOAccounts, setFlaggedPOAccounts] = useState([])
	const [searchOpen, setSearchOpen] = useState(false)
	const [searchLoading, setSearchLoading] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const [searchText, setSearchText] = useState(null)

 	// accounts
 	const [accountPopup, setAccountPopup] = useState(false);
 	const [newAccountData, setNewAccountData] = useState({});
 	const [indAccountPopup, setIndAccountPopup] = useState(false);
 	const [indAccount, setIndAccount] = useState(null);
 	const [accountContacts, setAccountContacts] = useState([]);
 	const [emailChange, setEmailChange] = useState(false);
 	const [newAccountEmail, setNewAccountEmail] = useState(null);
 	const [showSiteGroups, setShowSiteGroups] = useState(false);
 	const [siteGroupsData, setSiteGroupsData] = useState({})
 	const [accountPrefSelected, setAccountPrefSelected] = useState(null)
 	const [showPrefItems, setShowPrefItems] = useState(false)
 	const [accountPrefHeadSelected, setAccountPrefHeadSelected] = useState(null)
 	const [showPrefHead, setShowPrefHead] = useState(false)
 	const [showPOLimit, setShowPOLimit] = useState(false)
 	const [newPOLimit, setNewPOLimit] = useState({})
 	const [showCredits, setShowCredits] = useState(false)
 	const [newCredit, setNewCredit] = useState({})
 	const [newAccountAddress, setNewAccountAddress] = useState(null);

 	// sites
 	const [sitePopup, setSitePopup] = useState(false);
 	const [newSiteData, setNewSiteData] = useState({});
 	const [newSiteAddress, setNewSiteAddress] = useState(null);
 	const [indSitePopup, setIndSitePopup] = useState(false);
 	const [indSite, setIndSite] = useState(null);
 	const [indSiteNav, setIndSiteNav] = useState('Details');
 	const [showAllergies, setShowAllergies] = useState(false)
 	const [allergiesData, setAllergiesData] = useState({})
 	const [showCustomPricing, setShowCustomPricing] = useState(false)
 	const [tierNav, setTierNav] = useState("Fruit")
 	const [siteFruitTiers, setSiteFruitTiers] = useState([])
 	const [siteProcalTiers, setSiteProcalTiers] = useState([])
 	const [weekOffset, setWeekOffset] = useState(0)
 	const [currentDateSelection, setCurrentDateSelection] = useState(getCurrentWeekDays(weekOffset))
 	const [christmasClosuresData, setChristmasClosuresData] = useState([])
 	const [showChristmasClosures, setShowChristmasClosures] = useState(false)

 	// orders
 	const [orderPopup, setOrderPopup] = useState(false);
 	const [newOrderData, setNewOrderData] = useState({});
 	const [mainOrder, setMainOrder] = useState({});
 	const [flexiMainOrder, setFlexiMainOrder] = useState([]);
 	const [mainObj, setMainObj] = useState({});
 	const [flexiMainObj, setFlexiMainObj] = useState([]);
 	const [orderCategory, setOrderCategory] = useState(null);
 	const [orderTypeNav, setOrderTypeNav] = useState('Fruit');
 	const [flexiOptions, setFlexiOptions] = useState({})
 	const [seasonalExtraItems, setSeasonalExtraItems] = useState([])
 	const [prevOrderType, setPrevOrderType] = useState(null)
 	const [deliveryDayOptions, setDeliveryDayOptions] = useState(null)
 	const [accountSites, setAccountSites] = useState(null)
 	const [indOrderPopup, setIndOrderPopup] = useState(false);
 	const [indOrder, setIndOrder] = useState(null);
 	const [boxLoops, setBoxLoops] = useState(0);
 	const [currentBoxLoop, setCurrentBoxLoop] = useState(0);
 	const [boxActive, setBoxActive] = useState(0)
 	const [fruitTiers, setFruitTiers] = useState([])
 	const [procalTiers, setProcalTiers] = useState([])
 	const [bananaWarning, setBananaWarning] = useState(false)
 	const [exclusionsSelected, setExclusionsSelected] = useState([])
 	const [overdueWarning, setOverdueWarning] = useState(false)

 	// preferences
 	const [preferencesPopup, setPreferencesPopup] = useState(false);
 	const [indPreferencePopup, setIndPreferencePopup] = useState(false);
 	const [indPreference, setIndPreference] = useState(null);
 	const [preferenceExisting, setPreferenceExisting] = useState(null)
 	const [showTempCancel, setShowTempCancel] = useState(false)
 	const [tempCancelData, setTempCancelData] = useState({})
 	const [showClone, setShowClone] = useState(false)
 	const [newCloneData, setNewCloneData] = useState({})
 	const [weekActive, setWeekActive] = useState(0)
 	const [inactiveDatePopup, setInactiveDatePopup] = useState(false)
 	const [inactiveDate, setInactiveDate] = useState(null)
 	const [prefItemKeys, setPrefItemKeys] = useState(null)

 	// enquiries
 	const [enquiryPopup, setEnquiryPopup] = useState(false);
 	const [newEnquiryData, setNewEnquiryData] = useState({});
 	const [newEnquiryAddress, setNewEnquiryAddress] = useState(null);
 	const [indEnquiryPopup, setIndEnquiryPopup] = useState(false)
 	const [indEnquiry, setIndEnquiry] = useState(null)

 	// interactions
 	const [interactionPopup, setInteractionPopup] = useState(false);
 	const [newInteractionData, setNewInteractionData] = useState({});
 	const [sitesAvailable, setSitesAvailable] = useState([]);
 	const [indInteractionPopup, setIndInteractionPopup] = useState(false)
 	const [indInteraction, setIndInteraction] = useState(null)
 	const [emailFile, setEmailFile] = useState(null);
 	const [emailBody, setEmailBody] = useState(null);
	const [showInteractionHelp, setShowInteractionHelp] = useState(false);
	const [interactionNav, setInteractionNav] = useState("Gmail")


	const param = useParams();
	const url = `/api/admin/dashboard/main/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const session_token = localStorage.getItem('session_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`,
	   	'x-session-token': session_token
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(`${url}/${navType}/${true}`, axios_config);
					setAccountData(data.data);
					setAllAccounts(data.data.allAccounts)
					setAllSites(data.data.allSites)
					setAllSitesOG(data.data.allSites)
					setAllOrders(data.data.allOrders)
					setAllOrdersOG(data.data.allOrders)
					setAllPreferences(data.data.allPreferences)
					setInventoryData(data.data.allInventory)
					setMenuData(data.data.allMenu)
					setAllInteractions(data.data.allInteractions)
					setAllEnquiries(data.data.allEnquiries)
					setProcalTiers(data.data.allTiers.filter(tier => tier.type === "Milk" && tier.location === "General")[0].items)
					setFruitTiers(data.data.allTiers.filter(tier => tier.type === "Fruit" && tier.location === "General")[0].items)
					setAllTiers(data.data.allTiers)

					setFlaggedOrders(data.data.flaggedOrders)
					setFlaggedPreferences(data.data.flaggedPreferences)

					if ((data.data.flaggedOrders && data.data.flaggedOrders.length > 0) || (data.data.flaggedPreferences && data.data.flaggedPreferences.length > 0)) {
						setFlaggedAvailable(true)
					} else {
						setFlaggedAvailable(false)
					}
					
				 	setIsSuperAdmin(data.data.role && data.data.role === 'SuperAdmin');
				 	setIsAdmin(data.data.role && data.data.role === 'Admin');
				 	setIsAccount(data.data.role && data.data.role === 'Account');

					setIsAuthenticated(true)

					if (isExternal) {
						changeNavType(navigationData.type)

						const navToFound = data.data.allInteractions.find(nav => nav._id.toString() === navigationData._id.toString());
						indPopUpControls('open', navToFound, null, navigationData.type);
					}

				} catch(e) {
					setIsAuthenticated(false);
					if (e.response && e.response.data && e.response.data.logout) {
						handleLogout()
					}
				}
				setIsLoading(false)

			} catch (error) {
				setAccountData(error);
			}
		};

		const lateLoad = async () => {
			try {
				const data = await axios.get(`${url}/LateLoad/${false}`, axios_config);

				if (data.data.accPoOver && data.data.accPoOver.length > 0) {
					setFlaggedPOAccounts(data.data.accPoOver)
					setFlaggedPO(true)
				} else {
					setFlaggedPO(false)
				}
			} catch (error) {
				setAccountData(error);
				if (error.response && error.response.data && error.response.data.logout) {
					handleLogout()
				}
			}
		}

		initLoad();
		lateLoad();
    	sessionStorage.removeItem('arrivedExternally');
    	sessionStorage.removeItem('navData');

	}, [param, url, key, newSiteData['search_type']]);


	useEffect(() => {
		if (!indSite) {
			setCurrentDateSelection(getCurrentWeekDays(0))
		}
	}, [indSite]);

	const refreshDashboard = () => {
		setKey((prevKey) => prevKey + 1);
	};

	const searchControl = (event, tableId) => {
		if (accountPrefSelected) { tableId = "ind_table" }

	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = tableId === "button_table"
	    	? document.getElementById(tableId).children
	    	: document.getElementById(tableId).children[1].children;


	    if (tableId === "button_table") {
		    for (let i = 0; i < target_div.length; i++) {
		        let mainValue = target_div[i].getElementsByTagName("p")[0].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[0].innerText.trim().replaceAll("  ", " ")
		        let subValue = target_div[i].getElementsByTagName("p")[1].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[1].innerText.trim().replaceAll("  ", " ")
		        let lowerValue = target_div[i].getElementsByTagName("p")[2].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[2].innerText.trim().replaceAll("  ", " ")
		        let higValue = target_div[i].getElementsByTagName("p").length > 3 ? target_div[i].getElementsByTagName("p")[3].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[3].innerText.trim().replaceAll("  ", " ") : null
		        let itemValue = target_div[i].getElementsByTagName("p").length > 4 ? target_div[i].getElementsByTagName("p")[4].textContent.trim().replaceAll("  ", " ") || target_div[i].getElementsByTagName("p")[4].innerText.trim().replaceAll("  ", " ") : null

		        let showItem = false;
		        if (higValue) {
		        	if (itemValue) {
				        [mainValue.toUpperCase(), subValue.toUpperCase(), lowerValue.toUpperCase(), higValue.toUpperCase(), itemValue.toUpperCase()].forEach((value) => {
				            if (value.indexOf(filter) > -1) {
				                showItem = true;
				            }
				        });
		        	} else {
				        [mainValue.toUpperCase(), subValue.toUpperCase(), lowerValue.toUpperCase(), higValue.toUpperCase()].forEach((value) => {
				            if (value.indexOf(filter) > -1) {
				                showItem = true;
				            }
				        });
		        	}
		        } else {
			        [mainValue.toUpperCase(), subValue.toUpperCase(), lowerValue.toUpperCase()].forEach((value) => {
			            if (value.indexOf(filter) > -1) {
			                showItem = true;
			            }
			        });
		        }


		        if (showItem) {
		            target_div[i].style.display = "flex";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
	    } else {
		    for (let i = 0; i < target_div.length; i++) {
		        let txtValues = [];
		        for (let j = 0; j < target_div[i].children.length; j++) {
	                if (target_div[i].children[j].className.includes('status')) {
	                    // handle status
	                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
	                    txtValues.push(txtValue.toUpperCase());
	                } else {
		            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
		            	txtValues.push(txtValue.toUpperCase());
		            }
		        }

		        let showItem = false;
		        txtValues.forEach((value) => {
		            if (value.indexOf(filter) > -1) {
		                showItem = true;
		            }
		        });

		        if (showItem) {
		            target_div[i].style.display = "table";
		        } else {
		            target_div[i].style.display = "none";
		        }
		    }
		}
	};

	const handleSearch = async (input) => {
		setSearchOpen(true)
		const searchTerm = input.target.value

		if (searchTerm.length > 0) {
			setSearchText(searchTerm)
			setSearchLoading(true)

			const response = await axios.get(`/api/admin/dashboard/search/${param._id}`, {
			  	headers: { Authorization: `Bearer ${role_token}`, 'x-session-token': session_token },
			  	params: { searchTerm, navType }
			})

	      	setSearchResults(response.data.results);
	      	setSearchLoading(false);
      	} else {
      		setSearchText(null)
      		setSearchResults([])
      		setSearchOpen(false)
      	}
	}

	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: options[o], value: options[o]})
		}

		return options_arr
	}

	const formatDate = (dateString, showDay, showTime) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    if (!showTime) {
	    	dateObj.setHours(0, 0, 0, 0);
	    }

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay && showTime) {
	        let shours = ('0' + dateObj.getHours()).slice(-2);
	        let sminutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let sseconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year} @ ${shours}:${sminutes}:${sseconds}`;

	    } else if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else if (showTime) {
	        let hours = ('0' + dateObj.getHours()).slice(-2);
	        let minutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let seconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${day}/${month}/${year} @ ${hours}:${minutes}:${seconds}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};

	function getCurrentWeekDays(weekOffset = 0) {
	    const today = new Date();
	    const currentDay = today.getDay();
	    
	    const distanceToMonday = currentDay === 0 ? -6 : 1 - currentDay;
	    const distanceToSunday = currentDay === 0 ? 0 : 7 - currentDay;
	    
	    const monday = new Date(today);
	    monday.setDate(monday.getDate() + distanceToMonday + 7 * weekOffset);

	    const sunday = new Date(today);
	    sunday.setDate(sunday.getDate() + distanceToSunday + 7 * weekOffset);

	    return [monday, sunday];
	}

	const nextDeliveryDate = (day, commencement, lastGenerated) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	    const commencementDate = new Date(commencement);
	    let startDate;

	    if (lastGenerated) {
	        startDate = new Date(lastGenerated);
	    } else {
	        startDate = commencementDate;
	    }

	    startDate.setHours(0, 0, 0, 0);

	    const currentDayNumber = startDate.getDay();
	    const targetDayNumber = daysOfWeek.indexOf(day);

	    if (targetDayNumber === -1) {
	        return null;
	    }

	    let daysToAdd = targetDayNumber - currentDayNumber;
	    if (daysToAdd <= 0) {
	        daysToAdd += 7;
	    }

	    const nextDate = new Date(startDate);
	    nextDate.setDate(startDate.getDate() + daysToAdd);

	    return nextDate;
	};

	const convertToDay = (dateString) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		
		const currentDayNumber = new Date(dateString).getDay();
		const dayName = daysOfWeek[currentDayNumber];

		return dayName
	}

	const filterDates = (date, exclusions) => {
		const dateObj = new Date(date);
		const dayOfWeek = dateObj.toLocaleDateString('en-US', { weekday: 'long' });

		return !exclusions.includes(dayOfWeek);
	};

	const checkDateRange = (dateArr, validDay) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

		let startDate = new Date(dateArr[0]);
		let endDate = new Date(dateArr[1]);

		for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
			let dayOfWeek = daysOfWeek[date.getDay()];

			if (dayOfWeek === validDay) {
				return true;
			}
		}

		return false;
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const isValidPrice = (price) => {
	    let priceFormatted = typeof price === 'string' ? parseFloat(price) : price;

	    if (typeof priceFormatted !== 'number' || isNaN(priceFormatted)) {
	        return false;
	    }

	    if (priceFormatted < 0) {
	        return false;
	    }

	    const decimalPlaces = priceFormatted.toString().split('.')[1];
	    if (decimalPlaces && decimalPlaces.length > 2) {
	        return false;
	    }

	    return true;
	}

	const changeNavType = (type) => {
		setNavType(type)
		setAllSites(allSitesOG)
		setAllOrders(allOrdersOG)
		setCurrentAccount(null)
		setCurrentSite(null)
		refreshDashboard()
	}

	const changeOrderCategory = (type, orderNav) => {
		setOrderCategory(type)
	}

	const popUpControls = async (control) => {
		let indDataPopup;

		try {
			switch(control) {
				case 'open':
					switch (navType) {
						case 'Accounts':
							setAccountPopup(true)
							break;
						case 'Sites':
							setSitePopup(true)
							setIndLoading(true)

							indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/${navType}`, axios_config);
							setNewSiteData({
								companyArr: indDataPopup.data
							})

							setIndLoading(false)
							break;
						case 'Orders':
							setOrderPopup(true)
							setIndLoading(true)

							indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/${navType}`, axios_config);
							setNewOrderData({
								...newOrderData,
								popup: indDataPopup.data
							})

							setIndLoading(false)
							break;
						case 'Preferences':
							setPreferencesPopup(true)
							setIndLoading(true)

							indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);
							setNewOrderData({
								order_condition: 'recurring',
								popup: indDataPopup.data
							})

							setIndLoading(false)
							break;
						case 'Enquiries':
							setEnquiryPopup(true)
							break;
						case 'Interactions':
							setInteractionPopup(true)
							setIndLoading(true)

							indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);
							setNewInteractionData({
								popup: indDataPopup.data
							})

							setIndLoading(false)
							break;
					}
					break;
				case 'prev':
					if ((navType == "Preferences" && popUpPage == 2) || (navType == "Orders" && popUpPage == 3)) {
						setValidationError(null)
						setPrevOrderType(newOrderData['order_type'])
					}

					if ((popUpPage === 3 && navType === "Orders") || (popUpPage === 2 && navType === "Preferences")) {	
						setNewOrderData({
							popup: newOrderData.popup,
							order_condition: newOrderData.order_condition
						})
						setPopUpPage(1)
						setOrderCategory(null)
						setFlexiMainObj([])
						setFlexiMainOrder([])
						setMainOrder({})
						setMainObj({})
						setBoxLoops(0)
						setCurrentBoxLoop(0)
						setBoxActive(0)
						setWeekActive(0)
					}

					if (orderPopup) {
						if (popUpPage > 3 && popUpPage % 2 === 0) {
							setCurrentBoxLoop(currentBoxLoop - 1)
						}

						if (popUpPage === 4) {
							setCurrentBoxLoop(0)
						}
					} else if (preferencesPopup) {
						if (popUpPage > 2 && popUpPage % 2 !== 0) {
							setCurrentBoxLoop(currentBoxLoop - 1)
						}

						if (popUpPage === 3) {
							setCurrentBoxLoop(0)
						}
					}

					setPopUpPage(popUpPage-1);
					break;
				case 'next':
					switch (navType) {
						case 'Accounts':
							if (popUpPage == 1) {
								if (!(newAccountData.hasOwnProperty('company')) || newAccountData['company'] == '') {
									setValidationError(`Please enter the company name.`)
									return false;
								} else {
									let letAllAccs = await axios.get(`/api/admin/dashboard/popup/${param._id}/Sites`, axios_config);

									if (!newAccountData.edit && letAllAccs.data.filter(acc => acc === newAccountData.company).length > 0) {
										setValidationError(`An account with the company name ${newAccountData.company} already exists. Please choose something unique.`)
										return false;
									}
								}

								if (!(newAccountData.hasOwnProperty('abn')) || newAccountData['abn'] == '') {
									setValidationError(`Please enter an ABN.`)
									return false;
								}
								
								if (!(newAccountData.hasOwnProperty('pcontact')) || newAccountData['pcontact'] == '') {
									setValidationError(`Please enter a primary contact full name.`)
									return false;
								}

								if (!(newAccountData.hasOwnProperty('email')) || newAccountData['email'] == '') {
									setValidationError(`Please enter an email address.`)
									return false;
								} else {
									if (!(isValidEmail(newAccountData.email))) {
										setValidationError(`Please enter a valid email address.`)
										return false;
									} else {
										let letAllAccsEmails = await axios.get(`/api/admin/dashboard/popup/${param._id}/Emails`, axios_config);
										let accountEmailExists = letAllAccsEmails.data.filter(acc => acc === newAccountData.email)

										if (accountEmailExists.length > 0 && !newAccountData['edit']) {
											setValidationError(`An account already exists with this email.`)
											return false;
										}
									}
								}

								if (!(newAccountData.hasOwnProperty('phone')) || newAccountData['phone'] == '') {
									setValidationError(`Please enter a phone number.`)
									return false;
								}

								if (!(newAccountData.hasOwnProperty('invoice_cycle')) || newAccountData['invoice_cycle'] == '') {
									setValidationError(`Please select the invoice cycle.`)
									return false;
								}

								if (!(newAccountData.hasOwnProperty('payment_terms')) || newAccountData['payment_terms'] == '') {
									setValidationError(`Please select the payment terms.`)
									return false;
								}
							}

							if (popUpPage === 2) {
								if (!(newAccountAddress)) {
									setValidationError(`Please enter a billing address.`)
									return false;
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Sites':
							if (popUpPage == 1) {
								if (!(newSiteData.hasOwnProperty('account')) || newSiteData['account'] == '') {
									setValidationError(`Please select an account.`)
									return false;
								}

								if (!(newSiteAddress)) {
									setValidationError(`Please enter a site address.`)
									return false;
								}
								
							} else if (popUpPage == 2) {
								if (!(newSiteData.hasOwnProperty('site_contact_fname')) || newSiteData['site_contact_fname'] == '') {
									setValidationError(`Please enter a site contact first name.`)
									return false;
								}

								if (!(newSiteData.hasOwnProperty('site_contact_lname')) || newSiteData['site_contact_lname'] == '') {
									setValidationError(`Please enter a site contact last name.`)
									return false;
								}

								if (!(newSiteData.hasOwnProperty('site_contact_email')) || newSiteData['site_contact_email'] == '') {
									setValidationError(`Please enter a site contact email address.`)
									return false;
								} else {
									if (!(isValidEmail(newSiteData.site_contact_email))) {
										setValidationError(`Please enter a valid email address.`)
										return false;
									}
								}

								if (!(newSiteData.hasOwnProperty('site_contact_phone')) || newSiteData['site_contact_phone'] == '') {
									setValidationError(`Please enter a site contact phone number.`)
									return false;
								}
							} else if (popUpPage == 4) {
								if (!(newSiteData.hasOwnProperty('supplier_platform')) || newSiteData['supplier_platform'] == '' || !(newSiteData.crate)) {
									setValidationError(`Please select whether this site is part of a supplier platform or not.`)
									return false;
								}
								
								if (!(newSiteData.hasOwnProperty('crate')) || newSiteData['crate'] == '' || !(newSiteData.crate)) {
									setValidationError(`Please select whether this is a crate delivery or not.`)
									return false;
								}
								
								if (!(newSiteData.hasOwnProperty('site_pass')) || newSiteData['site_pass'] == '') {
									setValidationError(`Please select whether we have the pass for this site.`)
									return false;
								}
							} 

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Orders':
							if (popUpPage == 1) {
								if (!(newOrderData.hasOwnProperty('order_condition')) || newOrderData['order_condition'] == '') {
									setValidationError(`Please select one of the order types.`)
									return false;
								}
							} else if (popUpPage == 2) {
								if (prevOrderType && newOrderData['order_type'] != prevOrderType) {
									setNewOrderData({order_type: newOrderData['order_type'], order_condition: newOrderData['order_condition']})
									setMainOrder({})
									setMainObj({})
								}

								if (!(newOrderData.hasOwnProperty('order_type')) || newOrderData['order_type'] == '') {
									setValidationError(`Please select one of the order categories.`)
									return false;
								}
							} else if (popUpPage == 3) {
								if (!(newOrderData.hasOwnProperty('account')) || newOrderData['account'] == '') {
									setValidationError(`Please select an account for this order.`)
									return false;
								}

								if (!accountSites || !(accountSites.length > 0)) {
									if (!(newOrderData.edit)) {
										setValidationError(`There are no active sites for this account. In order to create an order, first create a site under this account.`)
										return false;
									}	
								} else if (!(newOrderData.hasOwnProperty('delivery_site')) || newOrderData['delivery_site'] == '') {
									setValidationError(`Please select a site for this order.`)
									return false;
								}

								if (newOrderData['order_condition'] == "recurring_temp") {
									if (newOrderData['delivery_day'] || newOrderData['delivery_day'] == '') {
										if (newOrderData['delivery_date_range'] || newOrderData['delivery_date_range'] == '') {
											if (!(newOrderData['delivery_date_range'].includes(null))) {
												if (!(checkDateRange(newOrderData['delivery_date_range'], newOrderData['delivery_day']))) {
													setValidationError(`Please select a date range that includes your delivery day (${newOrderData['delivery_day']}).`)
													return false;
												}
											} else {
												setValidationError(`Please select a date range.`)
												return false;
											}
										} else {
											setValidationError(`Please select a date range.`)
											return false;
										}
									} else {
										if (deliveryDayOptions) {
											setValidationError(`Please select a delivery day.`)
											return false;
										} else {
											if (!(newOrderData.edit)) {
												setValidationError(`There are no active ongoing orders for this site. Please create an ongoing order first.`)
												return false;
											}
										}
									}
								}

								if (newOrderData['order_condition'] == "onetime") {
									if (!(newOrderData.hasOwnProperty('delivery_date')) || newOrderData['delivery_date'] == '') {
										setValidationError(`Please select a delivery date`)
										return false;
									}
								}


								if (!newOrderData['edit']) {
									let matchingSite = accountSites.find(site => site.site_name === newOrderData.delivery_site)

									if (matchingSite) {
										let siteOrders = matchingSite.orders
										if (siteOrders.length > 0) {
											let matchingOrders = siteOrders.filter(sorder => sorder.order_type === newOrderData.order_type && formatDate(sorder.delivery_date) === formatDate(newOrderData['delivery_date']) && newOrderData.hasOwnProperty('box_type') ? newOrderData.box_type === sorder.box_type : false)
											if (matchingOrders.length > 0) {
												setValidationError(`This site already has an order on ${formatDate(newOrderData.delivery_date)} for ${newOrderData['order_type']}`)
												return false;
											}
										}
									}
								}

								if (newOrderData['order_type'] === 'Fruit') {
									if (!(newOrderData.hasOwnProperty('box_type')) || newOrderData['box_type'] == '') {
										setValidationError(`Please select a box type`)
										return false;
									}

									if (!(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes)) {
										if (isNaN(boxLoops) || parseInt(boxLoops) === 0 || boxLoops === '') {
											// prevent decimals from being used. (check if its a whole number)
											// also prevent negative numbers
											setValidationError("The number of boxes must be a real number greater than 0.")
											return false;
										}
									}
								}

								if (isSuperAdmin && newOrderData['edit']) {
									if (!isValidPrice(newOrderData['price'])) {
										setValidationError("Please enter a valid price.")
										return false;
									}
								}

							} else if (popUpPage == 4) {
								if (newOrderData['order_type'] !== 'Fruit') {
									if (Object.keys(mainObj).length === 0) {
										setValidationError(`Please select at least 1 item.`)
										return false
									}
								}

							} else if (popUpPage === 5) {
								if (newOrderData['order_type'] !== 'Fruit') {
									for (let i=0; i < Object.keys(mainObj).length; i++) {
										let checkQuantity = Object.fromEntries(Object.entries(mainObj[Object.keys(mainObj)[i]]).filter(([key, value]) => value === ''));

										if (Object.keys(checkQuantity).length > 0) {
											let quantityFalse = Object.keys(checkQuantity)[0]
											setValidationError(`Please specify a quantity for ${quantityFalse}.`)
											return false;
										}
									}
								}

								if (boxLoops === 0 && (!(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes))) {
									let totalqty = Object.values(mainObj).flatMap(Object.values).reduce((acc, val) => (acc === '' ? 0 : Number(acc)) + (val === '' ? 0 : Number(val)), 0) || 0
									if (totalqty === 0 || !(!isNaN(totalqty) && !isNaN(parseFloat(totalqty)))) {
										setValidationError(`The total quantity can not be 0 and has to be a real number.`)
										return false;
									} else {
										if (newOrderData.order_type === "Fruit" && newOrderData.box_type === "Flexi" && totalqty < 30) {
											setValidationError(`The minimum box size is 30 pieces.`)
											return false;
										} else {
											if (newOrderData.order_type === "Fruit" && newOrderData.box_type === "Flexi" && totalqty > 55) {
												setValidationError(`The maximum box size is 55 pieces.`)
												return false;
											}
										}
									}
								}
							}

							if (popUpPage > 3 && popUpPage % 2 !== 0) {
								if (newOrderData['box_type'] === "Flexi") {
									setOrderCategory("Fruit")

									for (const category in flexiMainObj[currentBoxLoop]) {
									    if (flexiMainObj[currentBoxLoop].hasOwnProperty(category)) {
									        for (const item in flexiMainObj[currentBoxLoop][category]) {
									            if (flexiMainObj[currentBoxLoop][category].hasOwnProperty(item)) {
									                const value = flexiMainObj[currentBoxLoop][category][item];
									                if (value === "" || value === "0" || isNaN(Number(value))) {
									                    setValidationError(`The quantity for ${item} under ${category} can not be 0 and has to be a real number.`)
									                    return false;
									                } else {
									                	if (item === "Banana" && value === "55") {
									                		setBananaWarning(true)
									                	} else {
									                		setBananaWarning(false)
									                	}
									                }
									            }
									        }
									    }
									}
								} else if (newOrderData['box_type'] === "Seasonal") {
									setOrderCategory("Fruit")

									if (mainObj.hasOwnProperty('Extra Items') && mainObj['Extra Items'].length > currentBoxLoop) {
										for (let item of Object.keys(mainObj['Extra Items'][currentBoxLoop])) {
							                let value = mainObj['Extra Items'][currentBoxLoop][item];

							                if (value === "" || value === "0" || isNaN(Number(value))) {
							                    setValidationError(`The quantity for ${item} can not be 0 and has to be a real number.`)
							                    return false;
							                } else {
							                	if (item === "Banana" && value === "55") {
							                		setBananaWarning(true)
							                	} else {
							                		setBananaWarning(false)
							                	}
							                }
										}
									}

									if (!(newOrderData.hasOwnProperty(`box_qty${currentBoxLoop}`)) || newOrderData[`box_qty${currentBoxLoop}`] === '' || newOrderData[`box_qty${currentBoxLoop}`] === '0' || isNaN(newOrderData[`box_qty${currentBoxLoop}`])) {
										setValidationError('The total quantity can not be 0 and has to be a real number.')
										return false
									} else {
										if (parseInt(newOrderData[`box_qty${currentBoxLoop}`]) < 30) {
											setValidationError(`The minimum box size is 30 pieces.`)
											return false;
										} else {
											if (parseInt(newOrderData[`box_qty${currentBoxLoop}`]) > 55) {
												setValidationError(`The maximum box size is 55 pieces.`)
												return false;
											}
										}
									}
								}
								setCurrentBoxLoop(currentBoxLoop + 1)

							} else if (popUpPage % 2 === 0) {
								if (newOrderData['box_type'] === "Flexi") {
									let allFlexiItems = [];
									if (flexiMainOrder && flexiMainOrder[currentBoxLoop]) {
									    allFlexiItems = [].concat(...Object.values(flexiMainOrder[currentBoxLoop]));
									}

									if (allFlexiItems.length === 0) {
										setValidationError(`Please select at least 1 item.`)
										return false
									}

								} else if (newOrderData['box_type'] === "Seasonal") {
									if (mainOrder.hasOwnProperty('Fruit') && mainOrder['Fruit'][currentBoxLoop]) {

										if ((mainOrder['Fruit'][currentBoxLoop].length + accountSites.find(site => site.site_name === newOrderData.delivery_site).allergies.length) > 3) {
											setValidationError(`The maximum number of exclusions is 3 (including site allergies).`)
											return false
										}
									}
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Preferences':
							if (popUpPage == 1) {
								if (prevOrderType && newOrderData['order_type'] != prevOrderType) {
									setNewOrderData({order_type: newOrderData['order_type'], order_condition: newOrderData['order_condition']})
									setMainOrder({})
									setMainObj({})
								}

								if (!(newOrderData.hasOwnProperty('order_type')) || newOrderData['order_type'] == '') {
									setValidationError(`Please select one of the order categories.`)
									return false;
								}
							} else if (popUpPage == 2) {
								if (!(newOrderData.hasOwnProperty('account')) || newOrderData['account'] == '') {
									setValidationError(`Please select an account for this order.`)
									return false;
								}

								if (!accountSites || !(accountSites.length > 0)) {
									if (!(newOrderData.edit)) {
										setValidationError(`There are no active sites for this account. In order to create an order, first create a site under this account.`)
										return false;	
									}
								} else if (!(newOrderData.hasOwnProperty('delivery_site')) || newOrderData['delivery_site'] == '') {
									setValidationError(`Please select a site for this order.`)
									return false;
								}

								if (!(newOrderData.hasOwnProperty('commencement_date')) || newOrderData['commencement_date'] === '' || newOrderData['commencement_date'] === null) {
									setValidationError("Please select a commencement date for this preference.")
									return false;
								}

								if (newOrderData['order_condition'] == "recurring") {
									if (!(newOrderData.hasOwnProperty('delivery_day')) || newOrderData['delivery_day'] == '') {
										setValidationError(`Please select a delivery day.`)
										return false;
									} else {
										let matchingPrefSite = accountSites.find(site => site.site_name === newOrderData.delivery_site)
										let preferenceCheck = matchingPrefSite.preferences.filter(pref => pref.status === "Active" && pref.frequency === newOrderData.frequency && pref.preference_type === newOrderData.order_type && pref.delivery_day === newOrderData.delivery_day && (pref.hasOwnProperty('box_type') ? (pref.box_type === newOrderData.box_type) : true))

										if (!newOrderData['edit'] && preferenceCheck.length > 0) {
											setValidationError(`Button:Sorry, an order already exists for ${newOrderData.order_type} at ${newOrderData.delivery_site} (${newOrderData.account}) on ${newOrderData.delivery_day}. Click to view.`)
											setPreferenceExisting(preferenceCheck[0])
											return false;
										}
									}
								}

								if (newOrderData['order_condition'] != "onetime" && !(newOrderData.hasOwnProperty('frequency')) || newOrderData['frequency'] == '') {
									setValidationError(`Please select a frequency for this order.`)
									return false;
								}

								if (newOrderData['frequency'] === "Monthly") {
									if (!(newOrderData.hasOwnProperty('monthly_frequency')) || newOrderData['monthly_frequency'] == '') {
										setValidationError(`Please select a monthly frequency for this preference.`)
										return false;
									}
								}

								if (newOrderData['order_type'] === 'Fruit') {
									if (!(newOrderData.hasOwnProperty('box_type')) || newOrderData['box_type'] == '') {
										setValidationError(`Please select a box type`)
										return false;
									}

									if (!(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes)) {
										if (isNaN(boxLoops) || parseInt(boxLoops) === 0 || boxLoops === '') {
											// prevent decimals from being used. (check if its a whole number)
											// also prevent negative numbers
											setValidationError("The number of boxes must be a real number greater than 0.")
											return false;
										}
									}
								}

								if (newOrderData['edit'] && newOrderData.hasOwnProperty('freqDetails') && Object.keys(newOrderData.freqDetails).length > 0) {
									if (!newOrderData.hasOwnProperty('items_to_edit') || newOrderData['items_to_edit'] === '') {
										setValidationError("Please select the week you would like to edit.")
										return false;
									}
								}
							} else if (popUpPage == 3) {
								if (newOrderData['order_type'] !== 'Fruit') {
									if (Object.keys(mainObj).length === 0) {
										setValidationError(`Please select at least 1 item.`)
										return false
									}
								}
							} else if (popUpPage == 4) {
								if (newOrderData['order_type'] !== 'Fruit') {
									for (let i=0; i < Object.keys(mainObj).length; i++) {
										let checkQuantity = Object.fromEntries(Object.entries(mainObj[Object.keys(mainObj)[i]]).filter(([key, value]) => value === ''));

										if (Object.keys(checkQuantity).length > 0) {
											let quantityFalse = Object.keys(checkQuantity)[0]
											setValidationError(`Please specify a quantity for ${quantityFalse}.`)
											return false;
										}
									}
								}

								if (boxLoops === 0 && (!(newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes))) {
									let totalqty = Object.values(mainObj).flatMap(Object.values).reduce((acc, val) => (acc === '' ? 0 : Number(acc)) + (val === '' ? 0 : Number(val)), 0) || 0
									if (totalqty === 0 || !(!isNaN(totalqty) && !isNaN(parseFloat(totalqty)))) {
										setValidationError(`The total quantity can not be 0 and has to be a real number.`)
										return false;
									} else {
										if (newOrderData.order_type === "Fruit" && newOrderData.box_type === "Flexi" && totalqty < 30) {
											setValidationError(`The minimum box size is 30 pieces.`)
											return false;
										} else {
											if (newOrderData.order_type === "Fruit" && newOrderData.box_type === "Flexi" && totalqty > 55) {
												setValidationError(`The maximum box size is 55 pieces.`)
												return false;
											}
										}
									}
								}
							}

							if (popUpPage > 2 && popUpPage % 2 === 0) {
								if (newOrderData['box_type'] === "Flexi") {
									setOrderCategory("Fruit")

									for (const category in flexiMainObj[currentBoxLoop]) {
									    if (flexiMainObj[currentBoxLoop].hasOwnProperty(category)) {
									        for (const item in flexiMainObj[currentBoxLoop][category]) {
									            if (flexiMainObj[currentBoxLoop][category].hasOwnProperty(item)) {
									                const value = flexiMainObj[currentBoxLoop][category][item];
									                if (value === "" || value === "0" || isNaN(Number(value))) {
									                    setValidationError(`The quantity for ${item} under ${category} can not be 0 and has to be a real number.`)
									                    return false;
									                } else {
									                	if (item === "Banana" && value === "55") {
									                		setBananaWarning(true)
									                	} else {
									                		setBananaWarning(false)
									                	}
									                }
									            }
									        }
									    }
									}
								} else if (newOrderData['box_type'] === "Seasonal") {
									setOrderCategory("Fruit")

									if (mainObj.hasOwnProperty('Extra Items') && mainObj['Extra Items'].length > currentBoxLoop) {
										for (let item of Object.keys(mainObj['Extra Items'][currentBoxLoop])) {
							                let value = mainObj['Extra Items'][currentBoxLoop][item];

							                if (value === "" || value === "0" || isNaN(Number(value))) {
							                    setValidationError(`The quantity for ${item} can not be 0 and has to be a real number.`)
							                    return false;
							                } else {
							                	if (item === "Banana" && value === "55") {
							                		setBananaWarning(true)
							                	} else {
							                		setBananaWarning(false)
							                	}
							                }
										}
									}

									if (!(newOrderData.hasOwnProperty(`box_qty${currentBoxLoop}`)) || newOrderData[`box_qty${currentBoxLoop}`] === '' || newOrderData[`box_qty${currentBoxLoop}`] === '0' || isNaN(newOrderData[`box_qty${currentBoxLoop}`])) {
										setValidationError('The total quantity can not be 0 and has to be a real number.')
										return false
									} else {
										if (parseInt(newOrderData[`box_qty${currentBoxLoop}`]) < 30) {
											setValidationError(`The minimum box size is 30 pieces.`)
											return false;
										} else {
											if (parseInt(newOrderData[`box_qty${currentBoxLoop}`]) > 55) {
												setValidationError(`The maximum box size is 55 pieces.`)
												return false;
											}
										}
									}
								}
								setCurrentBoxLoop(currentBoxLoop + 1)

							} else if (popUpPage % 2 !== 0) {
								if (newOrderData['box_type'] === "Flexi") {
									let allFlexiItems = [];
									if (flexiMainOrder && flexiMainOrder[currentBoxLoop]) {
									    allFlexiItems = [].concat(...Object.values(flexiMainOrder[currentBoxLoop]));
									}

									if (allFlexiItems.length === 0) {
										setValidationError(`Please select at least 1 item.`)
										return false
									}

								} else if (newOrderData['box_type'] === "Seasonal") {
									if (mainOrder.hasOwnProperty('Fruit') && mainOrder['Fruit'][currentBoxLoop]) {
										if ((mainOrder['Fruit'][currentBoxLoop].length + accountSites.find(site => site.site_name === newOrderData.delivery_site).allergies.length) > 3) {
											setValidationError(`The maximum number of exclusions is 3 (including site allergies).`)
											return false
										}
									}
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Enquiries':
							if (popUpPage == 1) {
								if (!(newEnquiryData.hasOwnProperty('company')) || newEnquiryData['company'] == '') {
									setValidationError(`Please enter the company that is enquiring.`)
									return false;
								}
								if (!(newEnquiryData.hasOwnProperty('first_name')) || newEnquiryData['first_name'] == '') {
									setValidationError(`Please enter the first name of the enquiry contact.`)
									return false;
								}
								if (!(newEnquiryData.hasOwnProperty('last_name')) || newEnquiryData['last_name'] == '') {
									setValidationError(`Please enter the last name of the enquiry contact.`)
									return false;
								}
								if (!(newEnquiryData.hasOwnProperty('email')) || newEnquiryData['email'] == '') {
									setValidationError(`Please enter the email address of the enquiry contact.`)
									return false;
								} else {
									if (!(isValidEmail(newEnquiryData.email))) {
										setValidationError(`Please enter a valid email address.`)
										return false;
									}
								}
								if ((!newEnquiryAddress) || newEnquiryAddress.formatted_address == '') {
									setValidationError(`Please enter the address of the enquiring company.`)
									return false;
								}
								if (!(newEnquiryData.hasOwnProperty('reason')) || newEnquiryData['reason'] == '') {
									setValidationError(`Please enter the reason we can not supply to this company.`)
									return false;
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Interactions':
							if (popUpPage == 1) {
								if (!(newInteractionData.hasOwnProperty('account')) || newInteractionData['account'] == '') {
									setValidationError(`Please select the account that you are interacting with.`)
									return false;
								}
								if (!(newInteractionData.hasOwnProperty('site')) || newInteractionData['site'] == '') {
									setValidationError(`Please select the site that you are interacting with.`)
									return false;
								}
								if (!(newInteractionData.hasOwnProperty('first_name')) || newInteractionData['first_name'] == '') {
									setValidationError(`Please enter the first name of the interaction contact.`)
									return false;
								}
								if (!(newInteractionData.hasOwnProperty('last_name')) || newInteractionData['last_name'] == '') {
									setValidationError(`Please enter the last name of the interaction contact.`)
									return false;
								}
								if (!(newInteractionData.hasOwnProperty('email')) || newInteractionData['email'] == '') {
									setValidationError(`Please enter the email address of the interaction contact.`)
									return false;
								} else {
									if (!(isValidEmail(newInteractionData.email))) {
										setValidationError(`Please enter a valid email address.`)
										return false;
									}
								}
								if (!(newInteractionData.hasOwnProperty('phone')) || newInteractionData['phone'] == '') {
									setValidationError(`Please enter the phone number of the interaction contact.`)
									return false;
								}
								if (!(newInteractionData.hasOwnProperty('comment')) || newInteractionData['comment'] == '') {
									setValidationError(`Please enter interaction notes.`)
									return false;
								}
								if (!(newInteractionData.hasOwnProperty('tags')) || newInteractionData['tags'] && newInteractionData['tags'].length == 0) {
									setValidationError(`Please select at least 1 tag.`)
									return false;
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						}
					break
				case 'close':
					setAccountPrefSelected(null)
					setShowPrefItems(false)
					setWeekActive(0)
					setBoxActive(0)
					setShowPrefHead(false)
					setAccountPrefHeadSelected(null)

					switch (navType) {
						case 'Accounts':
							if (Object.keys(newAccountData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this new account?`)
								if (confirm_close) {
									setAccountPopup(false);

									// RESET ADD FORM DATA
									setNewAccountData({})
									setNewAccountAddress(null)
									setAccountContacts([])
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setAccountPopup(false);
								setValidationError(null)
							}
							break;
						case 'Sites':
							if (Object.keys(newSiteData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this new site?`)
								if (confirm_close) {
									setSitePopup(false);

									// RESET ADD FORM DATA
									setNewSiteData({})
									setNewSiteAddress(null)
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setSitePopup(false);
								setValidationError(null)
							}
							break;
						case 'Orders':
							if (Object.keys(newOrderData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this order?`)
								if (confirm_close) {
									setOrderPopup(false);

									// RESET ADD FORM DATA
									setNewOrderData({})
									setPopUpPage(1)
									setOrderCategory(null)
									setFlexiMainObj([])
									setFlexiMainOrder([])
									setMainOrder({})
									setMainObj({})
									setValidationError(null)
									setOverdueWarning(false)
								}
							} else {
								setOrderPopup(false);
								setValidationError(null)
							}
							break;
						case 'Preferences':
							if (Object.keys(newOrderData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this preference?`)
								if (confirm_close) {
									setPreferencesPopup(false);

									// RESET ADD FORM DATA
									setNewOrderData({})
									setPopUpPage(1)
									setOrderCategory(null)
									setFlexiMainObj([])
									setFlexiMainOrder([])
									setMainOrder({})
									setMainObj({})
									setPreferenceExisting(null)
									setValidationError(null)
								}
							} else {
								setPreferencesPopup(false);
								setValidationError(null)
							}
							break;
						case 'Enquiries':
							if (Object.keys(newEnquiryData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this new enquiry?`)
								if (confirm_close) {
									setEnquiryPopup(false);

									// RESET ADD FORM DATA
									setNewEnquiryData({})
									setNewEnquiryAddress(null)
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setEnquiryPopup(false);
								setValidationError(null)
							}
							break;
						case 'Interactions':
							if (emailBody) {
								setIndSiteNav("Emails")
								setEmailBody(null)
							} else {
								if (Object.keys(newInteractionData).length > 0) {
									const confirm_close = window.confirm(`Are you sure you would like to discard this new interaction?`)
									if (confirm_close) {
										setInteractionPopup(false);

										// RESET ADD FORM DATA
										setNewInteractionData({})
										setSitesAvailable([])
										setPopUpPage(1)
										setValidationError(null)
									}
								} else {
									setInteractionPopup(false);
									setValidationError(null)
								}
							}
							break;
					}

					if (!emailBody) {
						setIndSiteNav('Details')
					}
					break;
				case 'submit':
					let switchingSite;
					let indControl;
					switch (navType) {
						case 'Accounts':
							setIsLoading(true)
							let accNavPulled = await axios.post(`/api/admin/dashboard/${param._id}`, {
								account_data: newAccountData,
								account_contacts: accountContacts,
								account_address: newAccountAddress
							});
							
							switchingSite = accNavPulled.data
							indControl = 'Accounts'

							setAccountPopup(false);
							setNewAccountData({})
							setAccountContacts([])
							setNewAccountAddress(null)
							setIndAccount(null)
							break;
						case 'Sites':
							setIsLoading(true)
							let siteNavPulled = await axios.post(`/api/admin/dashboard/sites/${param._id}`, {
								site_data: newSiteData,
								site_address: newSiteAddress
							});

							switchingSite = siteNavPulled.data
							indControl = 'Sites'

							setSitePopup(false);
							setNewSiteData({})
							setNewSiteAddress(null)
							setIndSite(null)
							break;
						case 'Orders':
							setIsLoading(true)

							if (newOrderData.hasOwnProperty('popup') && newOrderData) {
								delete newOrderData['popup']
							}

							if (newOrderData['box_type'] === "Flexi") {
								await axios.post(`/api/admin/dashboard/orders/${param._id}`, {
									order_type: orderTypeNav,
									order_info: newOrderData,
									order_data: flexiMainObj
								})

								await axios.post(`/api/admin/warehouse/refreshPurchase/${param._id}`, {
									orderData: newOrderData
								});
							} else {
								if (newOrderData.hasOwnProperty('account_sites')) {
									delete newOrderData['account_sites']
								}
								
								await axios.post(`/api/admin/dashboard/orders/${param._id}`, {
									order_type: orderTypeNav,
									order_info: newOrderData,
									order_data: Object.entries(mainObj).flatMap(([category, items]) => Object.entries(items).map(([item, quantity]) => ({ [item]: quantity }))),
									main_obj_raw: mainObj,
									exclusions: exclusionsSelected
								})

								await axios.post(`/api/admin/warehouse/refreshPurchase/${param._id}`, {
									orderData: newOrderData
								});
							}

							switchingSite = accountSites.find(site => site.site_name.toString() === newOrderData.delivery_site.toString())
							switchingSite._id = switchingSite.site_id;
							delete switchingSite.site_id;

							indControl = 'Sites'
							setOrderPopup(false);
							setNewOrderData({})
							setOrderCategory(null)
							setMainOrder({})
							setMainObj({})
							setMainOrder({})
							setIndOrder(null)
							setFlexiMainOrder([])
							setFlexiMainObj([])
							setBoxLoops(0)
							setCurrentBoxLoop(0)
							setBoxActive(0)
							setWeekActive(0)
							setExclusionsSelected([])
							setOverdueWarning(false)
							break;
						case 'Preferences':
							setIsLoading(true)
							if (newOrderData['box_type'] === "Flexi") {
								await axios.post(`/api/admin/dashboard/orders/${param._id}`, {
									order_type: orderTypeNav,
									order_info: newOrderData,
									order_data: flexiMainObj
								})

								await axios.post(`/api/admin/warehouse/refreshPurchase/${param._id}`, {
									orderData: newOrderData
								});
							} else {
								await axios.post(`/api/admin/dashboard/orders/${param._id}`, {
									order_type: orderTypeNav,
									order_info: newOrderData,
									order_data: Object.entries(mainObj).flatMap(([category, items]) => Object.entries(items).map(([item, quantity]) => ({ [item]: quantity }))),
									main_obj_raw: mainObj,
									exclusions: exclusionsSelected
								})

								await axios.post(`/api/admin/warehouse/refreshPurchase/${param._id}`, {
									orderData: newOrderData
								});
							}

							switchingSite = accountSites.find(site => site.site_name.toString() === newOrderData.delivery_site.toString())
							switchingSite._id = switchingSite.site_id;
							delete switchingSite.site_id;

							indControl = 'Sites'
							setPreferencesPopup(false);
							setNewOrderData({})
							setOrderCategory(null)
							setMainOrder({})
							setMainObj({})
							setPreferenceExisting(null)
							setIndPreference(null)
							setFlexiMainOrder([])
							setFlexiMainObj([])
							setBoxLoops(0)
							setCurrentBoxLoop(0)
							setBoxActive(0)
							setWeekActive(0)
							setExclusionsSelected([])
							break;
						case 'Enquiries':
							setIsLoading(true)
							await axios.post(`/api/admin/dashboard/enquiries/${param._id}`, {
								enquiry_data: newEnquiryData,
								enquiry_address: newEnquiryAddress
							});

							window.location.reload(false);
							break;
						case 'Interactions':
							setIsLoading(true)

							let emailFormData = new FormData();
						    emailFormData.append('emlFile', emailFile);
						    emailFormData.append('interaction_data', JSON.stringify(newInteractionData));

							await axios.post(`/api/admin/dashboard/interactions/${param._id}`, emailFormData, { 
								headers: {
						          'Content-Type': 'multipart/form-data'
						       	}
							});

							switchingSite = sitesAvailable.find(site => site.site_name.toString() === newInteractionData.site.toString())
							switchingSite._id = switchingSite.site_id;
							delete switchingSite.site_id;

							indControl = 'Sites'
							setInteractionPopup(false);
							setNewInteractionData({})
							setSitesAvailable([])
							setIndInteraction(null)
							break;
					}

					refreshDashboard()

					setIndSiteNav('Details')
					setPopUpPage(1)
					setValidationError(null)
					indPopUpControls('open', switchingSite, indControl)
					setIsLoading(false)
					break;
				case 'clone_box':
					let confirmOverride = window.confirm("Are you sure you would like to clone from previous box? This will overwrite the current box selections.")

					if (confirmOverride) {
						if (newOrderData['box_type'] === "Flexi") {
							let flexiMainOrderD = [...flexiMainOrder]
							let flexiMainObjD = [...flexiMainObj]

							flexiMainOrderD.push({ ...flexiMainOrderD[currentBoxLoop - 1] });
							flexiMainObjD.push({ ...flexiMainObjD[currentBoxLoop - 1] });

							setFlexiMainOrder(flexiMainOrderD)
							setFlexiMainObj(flexiMainObjD)
						} else if (newOrderData['box_type'] === "Seasonal") {
							let mainOrderD = {...mainOrder}
							let mainObjD = {...mainObj}
							let newOrderDataD = {...newOrderData}

							mainOrderD['Fruit'].push([ ...mainOrderD['Fruit'][currentBoxLoop - 1] ]);
							mainOrderD['Extra Items'].push([ ...mainOrderD['Extra Items'][currentBoxLoop - 1] ]);
							mainObjD['Fruit'].push({ ...mainObjD['Fruit'][currentBoxLoop - 1] });
							mainObjD['Extra Items'].push({ ...mainObjD['Extra Items'][currentBoxLoop - 1] });
							newOrderDataD[`box_qty${currentBoxLoop}`] = newOrderDataD[`box_qty${currentBoxLoop - 1}`]

							setMainOrder(mainOrderD)
							setMainObj(mainObjD)
							setNewOrderData(newOrderDataD)
						}
					}
					break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const handleNewAccountData = (name, selected) => {
		let newAccountDataTemp;
		if (name === "invoice_cycle" || name === "payment_terms") {
			newAccountDataTemp = { ...newAccountData, [name]: selected.value }
		} else {
			newAccountDataTemp = { ...newAccountData, [name]: selected.target.value }
		}
		
		setNewAccountData(newAccountDataTemp)
	};

	const handleNewAccountContacts = (location, name, input) => {
		let accountContactsTemp = [...accountContacts]
		accountContactsTemp[location][name] = input.target.value
		setAccountContacts(accountContactsTemp)
	};

	const handleNewSiteData = (input, name_passed=null) => {
		if (name_passed === "search_type") {
			setNewSiteAddress(null)
		}

		if (input.target) {
			setNewSiteData({ ...newSiteData, [input.target.name]: input.target.value });
		} else {
			setNewSiteData({ ...newSiteData, [name_passed]: input.value });
		}
	};

	const handleNewSiteAddress = (place) => {
		setNewSiteAddress(place);
	};

	const handleNewAccountAddress = (place) => {
		setNewAccountAddress(place);
	};

	const handleNewOrderData = async (name, selected, delivery_day_passed=null, condition_passed=null, type_passed=null, new_order_data_passed=null) => {
		try {
			let newOrderDataTemp;
			if (selected.hasOwnProperty('value')) {
				if (delivery_day_passed && condition_passed && type_passed) {
					newOrderDataTemp = { ...newOrderData, [name]: selected.value, "delivery_site": delivery_day_passed, "order_condition": condition_passed, "warning": true, "order_type": type_passed };
					setOrderTypeNav(type_passed)
					setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == type_passed).map(invitem => invitem.subcategory))][0])
				} else {
					newOrderDataTemp = { ...newOrderData, [name]: selected.value, "warning": false }
				}
			} else {
				newOrderDataTemp = { ...newOrderData, [name]: selected.target.value, "warning": false };
			}

			if (newOrderData['edit'] && name === "frequency" && indPreference) {
				if (selected.value !== indPreference.frequency) {
					newOrderDataTemp['freq_change'] = {
						old: indPreference.frequency,
						new: selected.value
					}
				} else {
					delete newOrderDataTemp['freq_change']
				}
			}

			if (name === "price") {
				if (parseFloat(selected.target.value) !== indOrder.price) {
					newOrderDataTemp['change_price'] = true
				} else {
					newOrderDataTemp['change_price'] = false
				}
			}

			if (name.includes('box_qty')) {
				let mainOrderDC = { ...mainOrder }
				let mainObjDC = { ...mainObj }

				if (mainOrderDC.hasOwnProperty('Fruit')) {
					if (mainOrderDC['Fruit'].length-1 < currentBoxLoop) {
						mainOrderDC['Fruit'].splice(currentBoxLoop, 0, []);
					}
				} else {
					mainOrderDC['Fruit'] = [[]]

					if (!mainOrderDC.hasOwnProperty('Extra Items')) {
						mainOrderDC['Extra Items'] = [[]]
					}
				}

				if (mainObjDC.hasOwnProperty('Fruit')) {
					if (mainObjDC['Fruit'].length-1 < currentBoxLoop) {
						mainObjDC['Fruit'].splice(currentBoxLoop, 0, {});
					}
				} else {
					mainObjDC['Fruit'] = [{}]

					if (!mainObjDC.hasOwnProperty('Extra Items')) {
						mainObjDC['Extra Items'] = [{}]
					}
				}

				setMainOrder(mainOrderDC)
				setMainObj(mainObjDC)
			}

			if (name === "order_type") {
				setOrderTypeNav(selected.value)
				setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == selected.value).map(invitem => invitem.subcategory))][0])
				setPopUpPage(popUpPage + 1)
			}

			if (name === "box_type") {
				setFlexiOptions({})
				setSeasonalExtraItems({})
				setMainOrder({})
				setMainObj({})

				setOrderCategory("Fruit")
				let extraItems = inventoryData.filter(item => item.category === "Fruit" && item.subcategory === "Extra Items").map(extra => extra.item_name)

				if (selected.value === "Flexi") {
					newOrderDataTemp['auto_assign_boxes'] = false

					let seasonalItem = inventoryData.find(inventoryItem => inventoryItem._id === menuData[0].seasonal_item).item_name
					let currentMenu = [seasonalItem]
					menuData[0].items.map(item => currentMenu.push(inventoryData.find(inventoryItem => inventoryItem._id === item.product).item_name))
					
					let flexiOptions = {
						'Fruit': ["Add all", ...currentMenu],
						'Extra Items': extraItems
					}

					setFlexiOptions(flexiOptions)
				} else {
					delete newOrderDataTemp['auto_assign_boxes']

					setSeasonalExtraItems(extraItems)
				}
				
			}
			if (name === "account") {
				setDeliveryDayOptions(null)
				delete newOrderDataTemp['delivery_site']
				delete newOrderDataTemp['delivery_day']
				delete newOrderDataTemp['frequency']

				let accountSitesArr = []
				let accountSelected = newOrderData.popup.accounts.find(acc => acc.company === selected.value)
				let findIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${accountSelected._id}`, axios_config);

				if (findIndAcc.data.sites_pushed.length > 0) {
					setAccountSites(findIndAcc.data.sites_pushed)
				}	
			}

			if (name == "delivery_site") {
				let delivery_day_options = []
				let siteSelected = accountSites.find(site => site.site_name === selected.value)

				if (siteSelected) {
					if (siteSelected.hasOwnProperty('preferences')) {
						for (let ip in siteSelected.preferences) {
							delivery_day_options.push(`${siteSelected.preferences[ip].delivery_day} - ${siteSelected.preferences[ip].preference_type} ${siteSelected.preferences[ip].hasOwnProperty('box_type') ? '(' + siteSelected.preferences[ip].box_type + ')' : ''}`)
						}
					}
				}

				if (delivery_day_options.length > 0) {
					setDeliveryDayOptions(delivery_day_options)
				}
			}

			if (newOrderData['order_condition'] != 'recurring' && (name == "delivery_day" || delivery_day_passed)) {
				let delivery_day_parsed;
				let box_type_parsed;

				if (new_order_data_passed) {
					newOrderDataTemp = {...new_order_data_passed}
				}

				if (name == "delivery_day") {
					delivery_day_parsed = selected.value.split(' - ')[0]
					newOrderDataTemp['delivery_day'] = delivery_day_parsed

					if (selected.value.includes('(')) {
						box_type_parsed = selected.value.split('(')[1].replace(')', '')
					}
				}

				let accSiteArr = accountSites ? accountSites : new_order_data_passed.account_sites
				let filtered_site = accSiteArr.find(site => site.site_name.toString() === newOrderDataTemp.delivery_site.toString())
				if (filtered_site) {
					let prefFoundFiltered = filtered_site.preferences.filter(pref => (type_passed ? pref.preference_type == type_passed : pref.preference_type == orderTypeNav) && pref.status == "Active" && pref.hasOwnProperty('delivery_day') && (pref.delivery_day == delivery_day_parsed || pref.delivery_day == delivery_day_passed) && (box_type_parsed ? box_type_parsed === pref.box_type : true))

					if (prefFoundFiltered.length > 0) {
						newOrderDataTemp['frequency'] = prefFoundFiltered[0].frequency
						newOrderDataTemp['preference_link'] = prefFoundFiltered[0]._id

						if (prefFoundFiltered[0].preference_type == "Fruit") {
							setOrderCategory("Fruit")

							let extraItems = inventoryData.filter(item => item.category === "Fruit" && item.subcategory === "Extra Items").map(extra => extra.item_name)

							if (prefFoundFiltered[0].box_type === "Flexi") {
								let seasonalItem = inventoryData.find(inventoryItem => inventoryItem._id === menuData[0].seasonal_item).item_name
								let currentMenu = [seasonalItem]
								menuData[0].items.map(item => currentMenu.push(inventoryData.find(inventoryItem => inventoryItem._id === item.product).item_name))

								let flexiOptions = {
									'Fruit': ["Add all", ...currentMenu],
									'Extra Items': extraItems
								}

								setFlexiOptions(flexiOptions)
							} else {
								setSeasonalExtraItems(extraItems)
							}
						} else {
							setOrderTypeNav(prefFoundFiltered[0].preference_type)
							setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == prefFoundFiltered[0].preference_type).map(invitem => invitem.subcategory))][0])
						}


						let mainObjItems = {};
						let mainOrderItems = {};
						let fruitMainObjItems = [];
						let fruitMainOrderItems = [];
						let inclusion_item_ids;
						let exclusion_item_ids;
						let exclusion_items;
						let extra_item_names = []

						if (prefFoundFiltered[0].preference_type === "Fruit") {
							for (let l = 0; l < prefFoundFiltered[0].items.length; l++) {
							    let tempMainObjItems = {};
							    let tempMainOrderItems = {};
							    let tempExtraItems = {}

							    prefFoundFiltered[0].items[l].forEach(item => {
							        let inventoryItem = inventoryData.find(data => data._id === item.item);

							        // Determine the property name based on the subcategory
							        let propertyName = inventoryItem.subcategory === "Extra Items" ? "Extra Items" : "Fruit";

							        if (propertyName === "Extra Items") {
							        	tempExtraItems[inventoryItem.item_name] = String(item.quantity)
							        }
							        
							        tempMainObjItems[propertyName] = tempMainObjItems[propertyName] || {};
							        tempMainObjItems[propertyName][inventoryItem.item_name] = String(item.quantity);

							        if (!(tempMainOrderItems.hasOwnProperty(propertyName))) {
							            tempMainOrderItems[propertyName] = [];
							        }

							        tempMainOrderItems[propertyName].push(inventoryItem.item_name);
							    });

							    fruitMainObjItems.push(tempMainObjItems);
							    fruitMainOrderItems.push(tempMainOrderItems);
							    extra_item_names.push(tempExtraItems)
							}


							inclusion_item_ids = prefFoundFiltered[0].items.map(array => array.map(obj => obj.item));
							exclusion_item_ids = exclusion_item_ids = inclusion_item_ids.map(subArray => menuData[0].items.filter(item => !subArray.includes(item.product)).map(filteredItem => filteredItem.product));
							exclusion_items = exclusion_item_ids.map(subArray => inventoryData.filter(item => subArray.includes(item._id)).map(filteredItem => filteredItem.item_name));
						} else {
							prefFoundFiltered[0].items.forEach(item => {
							  	let inventoryItem = inventoryData.find(data => data._id === item.item);
							  	mainObjItems[inventoryItem.subcategory] = mainObjItems[inventoryItem.subcategory] || {};
							  	mainObjItems[inventoryItem.subcategory][inventoryItem.item_name] = String(item.quantity);

							  	if (!(mainOrderItems.hasOwnProperty(inventoryItem.subcategory))) {
							  		mainOrderItems[inventoryItem.subcategory] = []
							  	}

							  	mainOrderItems[inventoryItem.subcategory].push(inventoryItem.item_name);
							});

							inclusion_item_ids = prefFoundFiltered[0].items.map(II => II.item)
							exclusion_item_ids = menuData[0].items.filter(MD => !(inclusion_item_ids.includes(MD.product))).map(MD => MD.product)
							exclusion_items = inventoryData.filter(item => exclusion_item_ids.includes(item._id)).map(item => item.item_name)
						}

						if (prefFoundFiltered[0].preference_type == "Fruit" && prefFoundFiltered[0].box_type == "Seasonal") {
							let mainOrderFiltered = {};
							let mainOrderStructured = {};

							exclusion_item_ids.forEach(subArray => {
							    let tempCategoryMap = {};

							    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
							        if (!tempCategoryMap[item.category]) {
							            tempCategoryMap[item.category] = [];
							        }
							        tempCategoryMap[item.category].push(item.item_name);
							    });

							    if (subArray.length === 0) {
							        if (Object.keys(mainOrderFiltered).length === 0) {
							            tempCategoryMap["Fruit"] = [];
							        } else {
							            Object.keys(mainOrderFiltered).forEach(category => {
							                tempCategoryMap[category] = [];
							            });
							        }
							    }

							    Object.keys(tempCategoryMap).forEach(category => {
							        if (!mainOrderFiltered[category]) {
							            mainOrderFiltered[category] = [];
							        }
							        mainOrderFiltered[category].push(tempCategoryMap[category]);
							    });
							});

							exclusion_item_ids.forEach(subArray => {
							    let tempCategoryMap = {};

							    if (subArray.length === 0) {
							        if (Object.keys(mainOrderStructured).length === 0) {
							            tempCategoryMap["Fruit"] = {};
							        } else {
							            Object.keys(mainOrderStructured).forEach(category => {
							                tempCategoryMap[category] = {};
							            });
							        }
							    }

							    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
							        if (!tempCategoryMap[item.category]) {
							            tempCategoryMap[item.category] = {};
							        }
							        tempCategoryMap[item.category][item.item_name] = "";
							    });

							    Object.keys(tempCategoryMap).forEach(category => {
							        if (!mainOrderStructured[category]) {
							            mainOrderStructured[category] = [];
							        }
							        mainOrderStructured[category].push(tempCategoryMap[category]);
							    });
							});

							if (extra_item_names.length > 0) {
								let mainOrderExtras = extra_item_names.map(obj => Object.keys(obj));

								mainOrderFiltered['Extra Items'] = mainOrderExtras
								mainOrderStructured['Extra Items'] = extra_item_names
							}
							newOrderDataTemp['box_type'] = prefFoundFiltered[0]['box_type']

							// need to add box qtys
							delete newOrderDataTemp['auto_assign_boxes']
							for (let b=0; b < prefFoundFiltered[0].items.length; b++) {
								let mappedQuants = prefFoundFiltered[0].items[b].map(item => item.quantity)
								newOrderDataTemp[`box_qty${b}`] = mappedQuants.reduce((partialSum, a) => partialSum + a, 0)
							}
							newOrderDataTemp['box_loops'] = prefFoundFiltered[0].items.length

							setBoxLoops(prefFoundFiltered[0].items.length)
							setMainOrder(mainOrderFiltered)
							setMainObj(mainOrderStructured)
							setExclusionsSelected(exclusion_items)
						} else if (prefFoundFiltered[0].preference_type == "Fruit" && prefFoundFiltered[0].box_type == "Flexi") {
							newOrderDataTemp['box_type'] = prefFoundFiltered[0]['box_type']
							newOrderDataTemp['auto_assign_boxes'] = false
							newOrderDataTemp['box_loops'] = prefFoundFiltered[0].items.length

							setBoxLoops(prefFoundFiltered[0].items.length)
							setFlexiMainObj(fruitMainObjItems);
							setFlexiMainOrder(fruitMainOrderItems)
						} else {
							setMainObj(mainObjItems);
							setMainOrder(mainOrderItems)
						}
					}
				}
			}

			setNewOrderData(newOrderDataTemp)
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	};

	const handleBoxLoops = (input) => {
		var loopInput = input.target.value
		let newOrderDataTL = { ...newOrderData }

		if (newOrderData.hasOwnProperty('box_type') && newOrderData['box_type'] !== '') {
			switch (newOrderData['box_type']) {
				case 'Seasonal':
					let mainOrderDL = { ...mainOrder }
					let mainObjDL = { ...mainObj }
					let newOrderDataDL = { ...newOrderData }

					if (mainOrderDL['Fruit'] && parseInt(loopInput) > 0 && mainOrderDL['Fruit'].length > loopInput) {
						mainOrderDL['Fruit'] = mainOrderDL['Fruit'].slice(0, loopInput)

						setMainOrder(mainOrderDL)
					}

					if (mainObjDL['Fruit'] && parseInt(loopInput) > 0 && mainObjDL['Fruit'].length > loopInput) {
						mainObjDL['Fruit'] = mainObjDL['Fruit'].slice(0, loopInput)

						setMainObj(mainObjDL)
					}

					for (let orderKey of Object.keys(newOrderDataDL)) {
						if (orderKey.includes('box_qty')) {
							let numberRep = orderKey.split('box_qty')[1]

							if (numberRep > parseInt(loopInput)-1) {
								delete newOrderDataDL[orderKey]
							}
						}
					}


					let exclusionsSelectedDup = [...exclusionsSelected]
					if (exclusionsSelectedDup.length > 0 && exclusionsSelectedDup.length > loopInput) {
					    while (exclusionsSelectedDup.length > loopInput) {
					        exclusionsSelectedDup.splice(exclusionsSelectedDup.length - 1, 1);
					    }
					}

					if (exclusionsSelectedDup.length < loopInput) {
					    while (exclusionsSelectedDup.length < loopInput) {
					        exclusionsSelectedDup.push([])
					    }
					}

					setExclusionsSelected(exclusionsSelectedDup)

					setNewOrderData(newOrderDataDL)
					break;
				case 'Flexi':
					let flexiMainOrderDL = [ ...flexiMainOrder ]
					let flexiMainObjDL = [ ...flexiMainObj ]

					if (flexiMainOrderDL && parseInt(loopInput) > 0 && flexiMainOrderDL.length > loopInput) {
						flexiMainOrderDL = flexiMainOrderDL.slice(0, loopInput)

						setFlexiMainOrder(flexiMainOrderDL)
					}

					if (flexiMainObjDL && parseInt(loopInput) > 0 && flexiMainObjDL.length > loopInput) {
						flexiMainObjDL = flexiMainObjDL.slice(0, loopInput)

						setFlexiMainObj(flexiMainObjDL)
					}

					break;
			}
		}

		newOrderDataTL['box_loops'] = loopInput
		setBoxLoops(loopInput)
		setNewOrderData(newOrderDataTL)
	}


	const handleMainOrder = (selected, exclusions=false) => {
		if (exclusions) {
			let exclusionsSelectedDup = [...exclusionsSelected]

			if (exclusionsSelectedDup.length >= currentBoxLoop+1) {
				exclusionsSelectedDup[currentBoxLoop] = selected.map(val => val.value)
			} else if (exclusionsSelectedDup.length < currentBoxLoop+1) {
				exclusionsSelectedDup.push([selected.map(val => val.value)])
			}

			setExclusionsSelected(exclusionsSelectedDup)
		}

		if (selected && selected.length > 0) {
			if (selected[selected.length-1].value === "Add all") {
				const flexiObj = {};
				for (let f = 1; f < flexiOptions["Fruit"].length; f++) {
				    flexiObj[flexiOptions["Fruit"][f]] = "";
				}

				let flexMainOrderDup = [...flexiMainOrder]
				let flexMainObjDup = [...flexiMainObj]

				if (flexMainOrderDup.length >= currentBoxLoop+1) {
					flexMainOrderDup[currentBoxLoop]["Fruit"] = flexiOptions["Fruit"].slice(1)
				} else {
					flexMainOrderDup.push({"Fruit": flexiOptions["Fruit"].slice(1)})
				}

				if (flexMainObjDup.length >= currentBoxLoop+1) {
					flexMainObjDup[currentBoxLoop]["Fruit"] = flexiObj
				} else {
					flexMainObjDup.push({"Fruit": flexiObj})
				}

				setFlexiMainOrder(flexMainOrderDup)
				setFlexiMainObj(flexMainObjDup)

				let flexiOptionsDup = {...flexiOptions}
				flexiOptionsDup["Fruit"] = flexiOptionsDup["Fruit"].slice(1)
				setFlexiOptions(flexiOptionsDup)
			} else {
				let mainOrderArr;
				if (newOrderData['order_type'] == "Coffee") {
					if (mainOrder.hasOwnProperty(orderCategory)) {
						if (mainOrder[orderCategory].includes(selected[selected.length-1].value)) {
							mainOrderArr = [...mainOrder[orderCategory]].filter(MO => MO !== selected[selected.length-1].value)
						} else {
							mainOrderArr = [...mainOrder[orderCategory], selected[selected.length-1].value]
						}
					} else {
						mainOrderArr = [selected[selected.length-1].value]
					}
				} else {
					mainOrderArr = selected.map(sel => sel.value).filter(item => {
						let itemFromKey = inventoryData.filter(it => it.item_name === item)[0]

						if (itemFromKey.custom_cutoff && itemFromKey.custom_cutoff !== 1 && itemFromKey.custom_cutoff !== 0) {
							if (newOrderData && newOrderData.delivery_date) {
								let overdueItemM = adjustToPreviousDayNoonAEST(newOrderData.delivery_date, itemFromKey.custom_cutoff)
								if (overdueItemM) { setOverdueWarning(true) }

								return !overdueItemM
							} else if (indOrder && indOrder.delivery_date) {
								let overdueItemN = adjustToPreviousDayNoonAEST(indOrder.delivery_date, itemFromKey.custom_cutoff)
								if (overdueItemN) { setOverdueWarning(true) }

								return !overdueItemN
							} else {
								return true
							}
						} else {
							return true
						}
					})
				}

				if (newOrderData['box_type'] === "Flexi") {
					let flexiMainOrderDup = [...flexiMainOrder]

					if (flexiMainOrderDup.length >= currentBoxLoop+1) {
						flexiMainOrderDup[currentBoxLoop][orderCategory] = mainOrderArr
					} else {
						flexiMainOrderDup.push({[orderCategory]: mainOrderArr})
					}

					setFlexiMainOrder(flexiMainOrderDup)
				} else {
					let mainOrderDuplicate = {...mainOrder}

					if (newOrderData['box_type'] === "Seasonal") {
						if (mainOrderDuplicate.hasOwnProperty(orderCategory)) {
							mainOrderDuplicate[orderCategory][currentBoxLoop] = mainOrderArr
						} else {
							mainOrderDuplicate[orderCategory] = [mainOrderArr]
						}
					} else {
						mainOrderDuplicate[orderCategory] = mainOrderArr
					}

					setMainOrder(mainOrderDuplicate)
				}
				

				let mainObjOutput;
				if (newOrderData['box_type'] === "Flexi") {
					let mainArrOutput = {...flexiMainObj[currentBoxLoop]}

					if (mainArrOutput.hasOwnProperty(orderCategory)) {
						mainObjOutput = mainArrOutput[orderCategory]
					} else {
						mainArrOutput[orderCategory] = {}
						mainObjOutput = mainArrOutput[orderCategory]
					}
				} else {
					mainObjOutput = {...mainObj[orderCategory]};
				}

				for (let orderitem of mainOrderArr) {
				  if (!mainObjOutput.hasOwnProperty(orderitem)) {
				    mainObjOutput[orderitem] = '';
				  }
				}

				let updatedMainObj = Object.keys(mainObjOutput)
				  .filter(key => mainOrderArr.includes(key))
				  .reduce((acc, key) => {
				    acc[key] = mainObjOutput[key];
				    return acc;
				  }, {});

				if (newOrderData['box_type'] === "Flexi") {
					let flexiMainObjDup = [...flexiMainObj]

					if (flexiMainObjDup.length >= currentBoxLoop+1) {
						flexiMainObjDup[currentBoxLoop][orderCategory] = updatedMainObj
					} else {
						flexiMainObjDup.push({[orderCategory]: updatedMainObj})
					}

					setFlexiMainObj(flexiMainObjDup)
				} else if (newOrderData['box_type'] === "Seasonal") {
					let mainObjDupS = {...mainObj}

					if (mainObjDupS.hasOwnProperty(orderCategory)) {
						mainObjDupS[orderCategory][currentBoxLoop] = updatedMainObj
					} else {
						mainObjDupS[orderCategory] = [updatedMainObj]
					}

					setMainObj(mainObjDupS)
				} else {
					let mainObjDup = {...mainObj}
					mainObjDup[orderCategory] = updatedMainObj

					setMainObj(mainObjDup)
				}
			}
		} else {
			if (newOrderData['box_type'] === "Flexi") {
				let clearFlexiOrder = [...flexiMainOrder]
				clearFlexiOrder[currentBoxLoop][orderCategory] = []

				let flexiMainObjDF = [...flexiMainObj]
				flexiMainObjDF[currentBoxLoop][orderCategory] = {}

				setFlexiMainOrder(clearFlexiOrder)
				setFlexiMainObj(flexiMainObjDF)

				let flexiOptionsD = {...flexiOptions}
				if (!(flexiOptionsD["Fruit"].includes('Add all'))) {
					flexiOptionsD["Fruit"] = ["Add all", ...flexiOptionsD["Fruit"]]
				}

				setFlexiOptions(flexiOptionsD)
			} else if (newOrderData['box_type'] === "Seasonal") {
				let clearSeasonalOrder = {...mainOrder}
				clearSeasonalOrder[orderCategory][currentBoxLoop] = []

				let mainObjDRS = {...mainObj}
				mainObjDRS[orderCategory][currentBoxLoop] = {}

				setMainOrder(clearSeasonalOrder)
				setMainObj(mainObjDRS)
			} else {
				let mainObjDR = {...mainObj}
				let mainOrderDRFix = {...mainOrder}
				for (let itemKey of Object.keys(mainObjDR[orderCategory])) {
					let itemFoundFromKey = inventoryData.filter(item => item.item_name === itemKey)
					if (itemFoundFromKey[0].custom_cutoff && itemFoundFromKey[0].custom_cutoff !== 1 && itemFoundFromKey[0].custom_cutoff !== 0) {
						if (indOrder && indOrder.delivery_date) {
							// calculate if overdue. If overdue, don't remove.
							let overdueItems = adjustToPreviousDayNoonAEST(indOrder.delivery_date, itemFoundFromKey[0].custom_cutoff)
							if (!overdueItems) {
								delete mainObjDR[orderCategory]
								mainOrderDRFix[orderCategory] = []
							}
						} else if (newOrderData && newOrderData.delivery_date) {
							// calculate if overdue. If overdue, don't remove.
							let overdueItemd = adjustToPreviousDayNoonAEST(newOrderData.delivery_date, itemFoundFromKey[0].custom_cutoff)
							if (!overdueItemd) {
								delete mainObjDR[orderCategory]
								mainOrderDRFix[orderCategory] = []
							}
						} else {
							delete mainObjDR[orderCategory]
							mainOrderDRFix[orderCategory] = []
						}
					} else {
						delete mainObjDR[orderCategory]
						mainOrderDRFix[orderCategory] = []
					}
				}

				setMainOrder(mainOrderDRFix)
				setMainObj(mainObjDR)
			}
		}
	};

	const handleMainObj = (selected) => {
		if (newOrderData.hasOwnProperty('box_type') && newOrderData['box_type']) {
			if (newOrderData['box_type'] === "Flexi") {
				let locate_order_category = Object.keys(flexiMainOrder[currentBoxLoop]).find(key => flexiMainOrder[currentBoxLoop][key].includes(selected.target.name))
				let flexiMainObjDuplicate = [...flexiMainObj]
				flexiMainObjDuplicate[currentBoxLoop][locate_order_category][selected.target.name] = selected.target.value

				setFlexiMainObj(flexiMainObjDuplicate)
			} else {
				let mainObjDuplicateMo = {...mainObj}
				mainObjDuplicateMo['Extra Items'][currentBoxLoop][selected.target.name] = selected.target.value

				setMainObj(mainObjDuplicateMo)
			}
		} else {
			let mainObjDuplicate = {...mainObj}
			
			const findPropertyName = (obj, key) => Object.keys(obj).find(prop => obj[prop].hasOwnProperty(key));
			let orderCategoryFound = findPropertyName(mainObjDuplicate, selected.target.name)
			mainObjDuplicate[orderCategoryFound][selected.target.name] = selected.target.value

			setMainObj(mainObjDuplicate)
		}

	};

	const indPopUpControls = async (control, ind, external=null, externalNavType=null) => {
		let documentMerge;
		let indDataMerge;
		let indDataPopup;
		setSearchResults([])
		setSearchOpen(false)
		setSearchLoading(false)
		setSearchText(null)

		try {
			if (external) {
				switch (external) {
					case 'Accounts':
						changeNavType("Accounts")
						setIndAccountPopup(true);
						setIndLoading(true)

						if (ind._id) {
							indDataMerge = await axios.get(`/api/admin/dashboard/ind/${param._id}/${external}/${ind._id}`, axios_config);
						} else {
							indDataMerge = await axios.get(`/api/admin/dashboard/ind/${param._id}/${external}/${ind.account_id}`, axios_config);
						}
						
						documentMerge = { ...indDataMerge.data, ind }

						setIndAccount(documentMerge)
						setIndLoading(false)
						break;
					case 'Sites':
						changeNavType("Sites")
						setIndSitePopup(true);
						setIndLoading(true)

						let weeksetSend = JSON.stringify(getCurrentWeekDays(0))

						if (ind._id) {
							indDataMerge = await axios.get(`/api/admin/dashboard/ind/${param._id}/${external}/${ind._id}/${weeksetSend}`, axios_config);
						} else {
							indDataMerge = await axios.get(`/api/admin/dashboard/ind/${param._id}/${external}/${ind.site_id}/${weeksetSend}`, axios_config);
						}


						documentMerge = { ...indDataMerge.data, ind }

						if (documentMerge['christmas_closures']) {
							setChristmasClosuresData([dayjs(documentMerge['christmas_closures'][0]), dayjs(documentMerge['christmas_closures'][1])])
						}

						setIndSite(documentMerge)
						setIndLoading(false)
						break;
				}

				return false;
			}

			const navTypeUse = externalNavType ? externalNavType : navType
			let indData;

			switch(control) {
				case 'open':
					switch (navTypeUse) {
						case 'Accounts':
							setIndAccountPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${navTypeUse}/${ind._id}`, axios_config);
							setIndAccount(indData.data)

							setIndLoading(false)
							break;
						case 'Sites':
							setIndSitePopup(true);
							setIndLoading(true)

							let weeksetSend = JSON.stringify(getCurrentWeekDays(0))

							indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${navTypeUse}/${ind._id}/${weeksetSend}`, axios_config);
							setIndSite(indData.data)

							if (indData.data['christmas_closures']) {
								setChristmasClosuresData([dayjs(indData.data['christmas_closures'][0]), dayjs(indData.data['christmas_closures'][1])])
							}
							
							setIndLoading(false)
							break;
						case 'Orders':
							setIndOrderPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${navTypeUse}/${ind._id}`, axios_config);
							setIndOrder(indData.data)

							setIndLoading(false)
							break;
						case 'Preferences':
							setIndPreferencePopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${navTypeUse}/${ind._id}`, axios_config);
							setIndPreference(indData.data)

							let itemKeysP = Object.keys(indData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
							setPrefItemKeys(itemKeysP)

							setIndLoading(false)
							break;
						case 'Enquiries':
							setIndEnquiryPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${navTypeUse}/${ind._id}`, axios_config);
							setIndEnquiry(indData.data)

							setIndLoading(false)
							break;
						case 'Interactions':
							setIndInteractionPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${navTypeUse}/${ind._id}`, axios_config);
							setIndInteraction(indData.data)

							setIndLoading(false)
							break;
					}
					break
				case 'edit':
					switch (navTypeUse) {
						case 'Accounts':
							setIndAccountPopup(false);
							setAccountPopup(true)

							setNewAccountData({
								company: indAccount.company,
								abn: indAccount['abn'] || '',
								email: indAccount.email,
								phone: (indAccount.contacts[0] && indAccount.contacts[0].phone) && indAccount.contacts[0].phone,
								pcontact: `${indAccount.contacts[0].first_name} ${indAccount.contacts[0].last_name ? indAccount.contacts[0].last_name : ''}`,
								edit: true,
								account_id: indAccount._id,
								invoice_cycle: indAccount.invoice_cycle,
								payment_terms: indAccount.payment_terms,

								uid: indAccount.uid,
								rowversion: indAccount.rowversion,
								account_code: indAccount.account_code,
							})

							let indAccountContacts;
							if (indAccount.contacts.length <= 1) {
								indAccountContacts = [...indAccount.contacts, {type: "Secondary"}, {type: "Accountant"}]
							} else {
								indAccountContacts = indAccount.contacts
							}

							setAccountContacts(indAccountContacts)

							if (indAccount.billing_location) {
								setNewAccountAddress({formatted_address: `${indAccount.billing_location.street}, ${indAccount.billing_location.city} ${indAccount.billing_location.state} ${indAccount.billing_location.postcode}, ${indAccount.billing_location.country}`})
							}
							break;
						case 'Sites':
							setIndSitePopup(false);
							setSitePopup(true)
							setIndLoading(true)
							indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Sites`, axios_config);
							setIndLoading(false)

							setNewSiteData({
								companyArr: indDataPopup.data,
								account: indSite.company,
								site_name: indSite.site_name,
								department: indSite.site_location.department,
								site_level: indSite.site_location.level,

								site_contact_fname: indSite.site_contact.first_name,
								site_contact_lname: indSite.site_contact.last_name,
								site_contact_email: indSite.site_contact.email,
								site_contact_phone: indSite.site_contact.phone,

								site_contact_fname_sec: indSite.secondary_site_contact ? indSite.secondary_site_contact.first_name : "",
								site_contact_lname_sec: indSite.secondary_site_contact ? indSite.secondary_site_contact.last_name : "",
								site_contact_email_sec: indSite.secondary_site_contact ? indSite.secondary_site_contact.email : "",
								site_contact_phone_sec: indSite.secondary_site_contact ? indSite.secondary_site_contact.phone : "",

								site_instructions: indSite.site_instructions,

								site_pass: indSite.site_pass,
								pass_number: indSite.pass_number,
								crate: indSite.crate,
								supplier_platform: indSite.supplier_platform || 'No',

								edit: true,
								site_id: indSite._id
							})
							setNewSiteAddress({formatted_address: `${indSite.site_location.street}, ${indSite.site_location.city} ${indSite.site_location.state} ${indSite.site_location.postcode}, ${indSite.site_location.country}`})
							break;
						case 'Orders':
							setIndOrderPopup(false);
							setOrderPopup(true)
							setPopUpPage(3)
							setIndLoading(true)
							let indDataPopupOrder = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);

							let orderAccountSelected = indDataPopupOrder.data.accounts.find(acc => acc.company === indOrder.company)
							let findIndOrderAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${orderAccountSelected._id}`, axios_config);

							if (findIndOrderAcc.data.sites_pushed.length > 0) {
								setAccountSites(findIndOrderAcc.data.sites_pushed)
							}


							setOrderTypeNav(indOrder.order_type)

							if (indOrder.order_type == "Fruit") {
								setOrderCategory("Fruit")

								let extraItems = inventoryData.filter(item => item.category === "Fruit" && item.subcategory === "Extra Items").map(extra => extra.item_name)

								if (indOrder.box_type === "Flexi") {
									let seasonalItem = inventoryData.find(inventoryItem => inventoryItem._id === menuData[0].seasonal_item).item_name
									let currentMenu = [seasonalItem]
									menuData[0].items.map(item => currentMenu.push(inventoryData.find(inventoryItem => inventoryItem._id === item.product).item_name))

									let flexiOptions = {
										'Fruit': ["Add all", ...currentMenu],
										'Extra Items': extraItems
									}

									setFlexiOptions(flexiOptions)
								} else {
									setSeasonalExtraItems(extraItems)
								}
							} else {
								setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == indOrder.order_type).map(invitem => invitem.subcategory))][0])
							}
							
							let editOrderObj = {
								order_condition: (indOrder.onetime && "onetime") || (indOrder.recurring_temp && "recurring_temp") || (indOrder.recurring && "ongoing"),
								warning: "false",
								order_type: indOrder.order_type,
								account: indOrder.company,
								delivery_site: indOrder.site_name,
								delivery_date: indOrder.delivery_date,
								box_loops: indOrder.items.length,
								delivery_day: indOrder.delivery_day,
								popup: indDataPopupOrder.data,

								edit: true,
								order_id: indOrder._id
							}

							if (isSuperAdmin) {
								editOrderObj['price'] = indOrder.price
							}

							if (indOrder.order_type === "Fruit") {
								editOrderObj['box_type'] = indOrder['box_type']

								if (indOrder['box_type'] === "Seasonal") {
									for (let l=0; l < indOrder.items.length; l++) {
										let oFilteredExtras = indOrder.items[l].filter(item => {
											let oItemFound = inventoryData.find(data => data._id.toString() === item.item.toString());
											let oPropertyName = oItemFound.subcategory === "Extra Items" ? "Extra Items" : "Fruit";

											return (oPropertyName !== "Extra Items")
										})
										editOrderObj[`box_qty${l}`] = oFilteredExtras.map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)
									}

									setExclusionsSelected(indOrder.exclusions)
								}

								editOrderObj['box_loops'] = indOrder.items.length
								setBoxLoops(indOrder.items.length)
							}

							setNewOrderData(editOrderObj)

							let mainObjItems = {};
							let mainOrderItems = {};
							let fruitMainObjItems = [];
							let fruitMainOrderItems = [];
							let inclusion_item_ids;
							let exclusion_item_ids;
							let exclusion_items;
							let extra_item_names = []

							if (indOrder.order_type === "Fruit") {
								for (let l = 0; l < indOrder.items.length; l++) {
								    let tempMainObjItems = {};
								    let tempMainOrderItems = {};
								    let tempExtraItems = {}

								    indOrder.items[l].forEach(item => {
								        let inventoryItem = inventoryData.find(data => data._id === item.item);
								        let propertyName = inventoryItem.subcategory === "Extra Items" ? "Extra Items" : "Fruit";

								        if (propertyName === "Extra Items") {
								        	tempExtraItems[inventoryItem.item_name] = String(item.quantity)
								        }
								        
								        tempMainObjItems[propertyName] = tempMainObjItems[propertyName] || {};
								        tempMainObjItems[propertyName][inventoryItem.item_name] = String(item.quantity);

								        if (!(tempMainOrderItems.hasOwnProperty(propertyName))) {
								            tempMainOrderItems[propertyName] = [];
								        }

								        tempMainOrderItems[propertyName].push(inventoryItem.item_name);
								    });

								    fruitMainObjItems.push(tempMainObjItems);
								    fruitMainOrderItems.push(tempMainOrderItems);
								    extra_item_names.push(tempExtraItems)
								}

								inclusion_item_ids = indOrder.items.map(array => array.map(obj => obj.item));
								exclusion_item_ids = indOrder.items.map(subArray => {
									let subArrayRemove = subArray.filter(item => !item.extra).map(item => item.item)
									let subArrFiltered = menuData[0].items.filter(item => !subArrayRemove.includes(item.product))
									let subArrF = subArrFiltered.map(filteredItem => filteredItem.product)
									return subArrF
								})
								exclusion_items = exclusion_item_ids.map(subArray => inventoryData.filter(item => subArray.includes(item._id)).map(filteredItem => filteredItem.item_name));
							} else {
								indOrder.items.forEach(item => {
								  	let inventoryItem = inventoryData.find(data => data._id === item.item);
								  	mainObjItems[inventoryItem.subcategory] = mainObjItems[inventoryItem.subcategory] || {};
								  	mainObjItems[inventoryItem.subcategory][inventoryItem.item_name] = String(item.quantity);

								  	if (!(mainOrderItems.hasOwnProperty(inventoryItem.subcategory))) {
								  		mainOrderItems[inventoryItem.subcategory] = []
								  	}

								  	mainOrderItems[inventoryItem.subcategory].push(inventoryItem.item_name);
								});

								inclusion_item_ids = indOrder.items.map(II => II.item)
								exclusion_item_ids = menuData[0].items.filter(MD => !(inclusion_item_ids.includes(MD.product))).map(MD => MD.product)
								exclusion_items = inventoryData.filter(item => exclusion_item_ids.includes(item._id)).map(item => item.item_name)
							}

							if (indOrder.order_type == "Fruit" && indOrder.box_type == "Seasonal") {
								let mainOrderFiltered = {};
								let mainOrderStructured = {};

								exclusion_item_ids.forEach(subArray => {
								    let tempCategoryMap = {};

								    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
								        if (!tempCategoryMap[item.category]) {
								            tempCategoryMap[item.category] = [];
								        }
								        tempCategoryMap[item.category].push(item.item_name);
								    });

								    if (subArray.length === 0) {
								        if (Object.keys(mainOrderFiltered).length === 0) {
								            tempCategoryMap["Fruit"] = [];
								        } else {
								            Object.keys(mainOrderFiltered).forEach(category => {
								                tempCategoryMap[category] = [];
								            });
								        }
								    }

								    Object.keys(tempCategoryMap).forEach(category => {
								        if (!mainOrderFiltered[category]) {
								            mainOrderFiltered[category] = [];
								        }
								        mainOrderFiltered[category].push(tempCategoryMap[category]);
								    });
								});

								exclusion_item_ids.forEach(subArray => {
								    let tempCategoryMap = {};

								    if (subArray.length === 0) {
								        if (Object.keys(mainOrderStructured).length === 0) {
								            tempCategoryMap["Fruit"] = {}; // Assuming "Fruit" as a default category
								        } else {
								            Object.keys(mainOrderStructured).forEach(category => {
								                tempCategoryMap[category] = {};
								            });
								        }
								    }

								    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
								        if (!tempCategoryMap[item.category]) {
								            tempCategoryMap[item.category] = {};
								        }
								        tempCategoryMap[item.category][item.item_name] = "";
								    });

								    Object.keys(tempCategoryMap).forEach(category => {
								        if (!mainOrderStructured[category]) {
								            mainOrderStructured[category] = [];
								        }
								        mainOrderStructured[category].push(tempCategoryMap[category]);
								    });
								});

								if (extra_item_names.length > 0) {
									let mainOrderExtras = extra_item_names.map(obj => Object.keys(obj));

									mainOrderFiltered['Extra Items'] = mainOrderExtras
									mainOrderStructured['Extra Items'] = extra_item_names
								}

								setMainOrder(mainOrderFiltered)
								setMainObj(mainOrderStructured)

							} else if (indOrder.order_type == "Fruit" && indOrder.box_type == "Flexi") {
								let allItemsSplit = fruitMainOrderItems.reduce((acc, item) => {
								    Object.values(item).forEach(value => {
								        if (Array.isArray(value)) {
								            acc.push(...value);
								        }
								    });
								    return acc;
								}, []);

								let allItemsSplitUnique = [...new Set(allItemsSplit)];
								for (let itemTest of allItemsSplitUnique) {
									let itemTestFromKey = inventoryData.filter(it => it.item_name === itemTest)[0]

									if (itemTestFromKey.custom_cutoff && itemTestFromKey.custom_cutoff !== 1 && itemTestFromKey.custom_cutoff !== 0) {
										let overdueItem = adjustToPreviousDayNoonAEST(indOrder.delivery_date, itemTestFromKey.custom_cutoff)

										if (overdueItem) {
											setOverdueWarning(true)
										}
									}
								}

								setFlexiMainObj(fruitMainObjItems);
								setFlexiMainOrder(fruitMainOrderItems)
							} else {
								let allItemsSplit = Object.values(mainOrderItems).reduce((acc, items) => {
								    if (Array.isArray(items)) {
								        acc.push(...items);
								    }
								    return acc;
								}, []);

								let allItemsSplitUnique = [...new Set(allItemsSplit)];
								for (let itemTest of allItemsSplitUnique) {
									let itemTestFromKey = inventoryData.filter(it => it.item_name === itemTest)[0]

									if (itemTestFromKey.custom_cutoff && itemTestFromKey.custom_cutoff !== 1 && itemTestFromKey.custom_cutoff !== 0) {
										let overdueItem = adjustToPreviousDayNoonAEST(indOrder.delivery_date, itemTestFromKey.custom_cutoff)

										if (overdueItem) {
											setOverdueWarning(true)
										}
									}
								}

								setMainObj(mainObjItems);
								setMainOrder(mainOrderItems)
							}

							setIndLoading(false)
							break;
						case 'Preferences':
							setIndPreferencePopup(false);
							setPreferencesPopup(true)
							setPopUpPage(2)
							setIndLoading(true)
							let indPrefDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);

							let prefAccountSelected = indPrefDataPopup.data.accounts.find(acc => acc.company === indPreference.company)
							let findIndPrefAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${prefAccountSelected._id}`, axios_config);

							if (findIndPrefAcc.data.sites_pushed.length > 0) {
								setAccountSites(findIndPrefAcc.data.sites_pushed)
							}


							setOrderTypeNav(indPreference.preference_type)

							if (indPreference.preference_type == "Fruit") {
								setOrderCategory("Fruit")
								let extraItems = inventoryData.filter(item => item.category === "Fruit" && item.subcategory === "Extra Items").map(extra => extra.item_name)

								if (indPreference.box_type === "Flexi") {
									let seasonalItem = inventoryData.find(inventoryItem => inventoryItem._id === menuData[0].seasonal_item).item_name
									let currentMenu = [seasonalItem]
									menuData[0].items.map(item => currentMenu.push(inventoryData.find(inventoryItem => inventoryItem._id === item.product).item_name))
									
									let flexiOptions = {
										'Fruit': ["Add all", ...currentMenu],
										'Extra Items': extraItems
									}

									setFlexiOptions(flexiOptions)
								} else {
									setSeasonalExtraItems(extraItems)
								}
							} else {
								setOrderCategory([...new Set(inventoryData.filter(inv => inv.category == indPreference.preference_type).map(invitem => invitem.subcategory))][0])
							}	

							let editPrefObj = {
								popup: indPrefDataPopup.data,
								order_condition: "recurring",
								warning: "false",
								order_type: indPreference.preference_type,
								account: indPreference.company,
								delivery_site: indPreference.site_name,
								delivery_day: indPreference.delivery_day ? indPreference.delivery_day : '',
								frequency: indPreference.frequency,
								commencement_date: indPreference.commencement_date,
								box_loops: indPreference.items.length,

								edit: true,
								preference_id: indPreference._id
							}

							if (indPreference.hasOwnProperty('freqDetails') && Object.keys(indPreference.freqDetails).length > 0) {
								const itemKeys = Object.keys(indPreference).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1])).map(item => item.split('_')[1]);

								editPrefObj['freqDetails'] = indPreference.freqDetails
								editPrefObj['itemKeys'] = itemKeys
							} else {
								if (indPreference.preference_type == "Fruit") {
									editPrefObj['box_type'] = indPreference.box_type

									if (indPreference.box_type == "Seasonal") {
										for (let l=0; l < indPreference.items.length; l++) {
											let pFilteredExtras = indPreference.items[l].filter(item => {
												let pItemFound = inventoryData.find(data => data._id.toString() === item.item.toString());
												let pPropertyName = pItemFound.subcategory === "Extra Items" ? "Extra Items" : "Fruit";

												return (pPropertyName !== "Extra Items")
											})

											editPrefObj[`box_qty${l}`] = pFilteredExtras.map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)
										}

										setExclusionsSelected(indPreference.exclusions)
									}

									editPrefObj['box_loops'] = indPreference.items.length
									setBoxLoops(indPreference.items.length)
								}

								setNewOrderData(editPrefObj)

								let pmainObjItems = {};
								let pmainOrderItems = {};
								let pfruitMainObjItems = [];
								let pfruitMainOrderItems = [];
								let pinclusion_item_ids;
								let pexclusion_item_ids;
								let pexclusion_items;
								let pextra_item_names = []

								if (indPreference.preference_type === "Fruit") {
									for (let l = 0; l < indPreference.items.length; l++) {
									    let ptempMainObjItems = {};
									    let ptempMainOrderItems = {};
									    let ptempExtraItems = {}

									    indPreference.items[l].forEach(item => {
									        let inventoryItem = inventoryData.find(data => data._id === item.item);

									        // Determine the property name based on the subcategory
									        let propertyName = inventoryItem.subcategory === "Extra Items" ? "Extra Items" : "Fruit";
									        if (propertyName === "Extra Items") {
									        	ptempExtraItems[inventoryItem.item_name] = String(item.quantity)
									        }
									        
									        ptempMainObjItems[propertyName] = ptempMainObjItems[propertyName] || {};
									        ptempMainObjItems[propertyName][inventoryItem.item_name] = String(item.quantity);

									        if (!(ptempMainOrderItems.hasOwnProperty(propertyName))) {
									            ptempMainOrderItems[propertyName] = [];
									        }

									        ptempMainOrderItems[propertyName].push(inventoryItem.item_name);
									    });

									    pfruitMainObjItems.push(ptempMainObjItems);
									    pfruitMainOrderItems.push(ptempMainOrderItems);
									    pextra_item_names.push(ptempExtraItems)
									}


									pinclusion_item_ids = indPreference.items.map(array => array.map(obj => obj.item));
									pexclusion_item_ids = indPreference.items.map(subArray => {
										let pSubArrayRemove = subArray.filter(item => !item.extra).map(item => item.item)
										let pSubArrFiltered = menuData[0].items.filter(item => !pSubArrayRemove.includes(item.product))
										let pSubArrF = pSubArrFiltered.map(filteredItem => filteredItem.product)
										return pSubArrF
									})
									pexclusion_items = pexclusion_item_ids.map(subArray => inventoryData.filter(item => subArray.includes(item._id)).map(filteredItem => filteredItem.item_name));
								} else {
									indPreference.items.forEach(item => {
									  	let inventoryItem = inventoryData.find(data => data._id === item.item);
									  	pmainObjItems[inventoryItem.subcategory] = pmainObjItems[inventoryItem.subcategory] || {};
									  	pmainObjItems[inventoryItem.subcategory][inventoryItem.item_name] = String(item.quantity);

									  	if (!(pmainOrderItems.hasOwnProperty(inventoryItem.subcategory))) {
									  		pmainOrderItems[inventoryItem.subcategory] = []
									  	}

									  	pmainOrderItems[inventoryItem.subcategory].push(inventoryItem.item_name);
									});

									pinclusion_item_ids = indPreference.items.map(II => II.item)
									pexclusion_item_ids = menuData[0].items.filter(MD => !(pinclusion_item_ids.includes(MD.product))).map(MD => MD.product)
									pexclusion_items = inventoryData.filter(item => pexclusion_item_ids.includes(item._id)).map(item => item.item_name)
								}

								if (indPreference.preference_type == "Fruit" && indPreference.box_type == "Seasonal") {
									let mainOrderFiltered = {};
									let mainOrderStructured = {};

									pexclusion_item_ids.forEach(subArray => {
									    let tempCategoryMap = {};

									    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
									        if (!tempCategoryMap[item.category]) {
									            tempCategoryMap[item.category] = [];
									        }
									        tempCategoryMap[item.category].push(item.item_name);
									    });

									    if (subArray.length === 0) {
									        if (Object.keys(mainOrderFiltered).length === 0) {
									            tempCategoryMap["Fruit"] = [];
									        } else {
									            Object.keys(mainOrderFiltered).forEach(category => {
									                tempCategoryMap[category] = [];
									            });
									        }
									    }

									    Object.keys(tempCategoryMap).forEach(category => {
									        if (!mainOrderFiltered[category]) {
									            mainOrderFiltered[category] = [];
									        }
									        mainOrderFiltered[category].push(tempCategoryMap[category]);
									    });
									});

									pexclusion_item_ids.forEach(subArray => {
									    let tempCategoryMap = {};

									    if (subArray.length === 0) {
									        if (Object.keys(mainOrderStructured).length === 0) {
									            tempCategoryMap["Fruit"] = {}; // Assuming "Fruit" as a default category
									        } else {
									            Object.keys(mainOrderStructured).forEach(category => {
									                tempCategoryMap[category] = {};
									            });
									        }
									    }

									    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
									        if (!tempCategoryMap[item.category]) {
									            tempCategoryMap[item.category] = {};
									        }
									        tempCategoryMap[item.category][item.item_name] = "";
									    });

									    Object.keys(tempCategoryMap).forEach(category => {
									        if (!mainOrderStructured[category]) {
									            mainOrderStructured[category] = [];
									        }
									        mainOrderStructured[category].push(tempCategoryMap[category]);
									    });
									});

									if (pextra_item_names.length > 0) {
										let mainOrderExtras = pextra_item_names.map(obj => Object.keys(obj));

										mainOrderFiltered['Extra Items'] = mainOrderExtras
										mainOrderStructured['Extra Items'] = pextra_item_names
									}

									setMainOrder(mainOrderFiltered)
									setMainObj(mainOrderStructured)

								} else if (indPreference.preference_type == "Fruit" && indPreference.box_type == "Flexi") {
									setFlexiMainObj(pfruitMainObjItems);
									setFlexiMainOrder(pfruitMainOrderItems)

								} else {
									setMainObj(pmainObjItems);
									setMainOrder(pmainOrderItems)
								}
							}

							setNewOrderData(editPrefObj)
							setIndLoading(false)

							break;
						case 'Enquiries':
							setIndEnquiryPopup(false);
							setEnquiryPopup(true)

							setNewEnquiryData({
								company: indEnquiry.company,
								first_name: indEnquiry.contact.first_name,
								last_name: indEnquiry.contact.last_name,
								email: indEnquiry.contact.email,
								phone: indEnquiry.contact.phone,

								reason: indEnquiry.reason,
								notes: indEnquiry.notes,

								edit: true,
								enquiry_id: indEnquiry._id
							})
							setNewEnquiryAddress({formatted_address: `${indEnquiry.address}`})
							break;
						case 'Interactions':
							setIndInteractionPopup(false);
							setInteractionPopup(true)
							setIndLoading(true)
							indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);
							
							let accountSitesArr = []
							let accountSelected = indDataPopup.data.accounts.find(acc => acc.company === indInteraction.account.company)
							let findIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${accountSelected._id}`, axios_config);

							if (findIndAcc.data.sites_pushed.length > 0) {
								setSitesAvailable(findIndAcc.data.sites_pushed)
							}

							setNewInteractionData({
								popup: indDataPopup.data,
								account: indInteraction.account.company,
								site: indInteraction.site.site_name,
								first_name: indInteraction.contact.first_name,
								last_name: indInteraction.contact.last_name,
								email: indInteraction.contact.email,
								phone: indInteraction.contact.phone,
								follow_up_date: indInteraction.follow_up_date,

								comment: indInteraction.comment,
								tags: indInteraction.tags ? indInteraction.tags.map(item => ({ label: item, value: item })) : [],

								edit: true,
								interaction_id: indInteraction._id
							})

							setIndLoading(false)
							break;
					}
					break
				case 'close':
					setIndAccountPopup(false);
					setIndSitePopup(false);
					setIndOrderPopup(false);
					setIndPreferencePopup(false);
					setIndEnquiryPopup(false);
					setIndInteractionPopup(false);
					setIndSiteNav('Details')
					setAccountPrefSelected(null)
					setShowPrefItems(false)
					setShowPrefHead(false)
					setAccountPrefHeadSelected(null)
					setChristmasClosuresData([])
					setWeekActive(0)
					setBoxActive(0)

					switch (navTypeUse) {
						case 'Accounts':
							if (emailChange) {
								setEmailChange(false)
								setNewAccountEmail(null)
								setIndAccountPopup(true)
							} else {
								setIndAccount(null)
								setAccountPrefSelected(null)
								setShowPrefItems(false)
								setWeekActive(0)
								setBoxActive(0)
								setShowPrefHead(false)
								setAccountPrefHeadSelected(null)
							}
							break;
						case 'Sites':
							setIndSite(null)
							setAccountPrefSelected(null)
							setShowPrefHead(false)
							setAccountPrefHeadSelected(null)
							break;
						case 'Orders':
							setIndOrder(null)
							setOrderPopup(false);
							setNewOrderData({})
							setOrderCategory(null)
							setMainOrder({})
							setMainObj({})
							setMainOrder({})
							setFlexiMainOrder([])
							setFlexiMainObj([])
							setBoxLoops(0)
							setCurrentBoxLoop(0)
							setBoxActive(0)
							setBananaWarning(false)
							setExclusionsSelected([])
							break;
						case 'Preferences':
							setIndPreference(null)
							setBoxActive(0)
							setWeekActive(0)
							setBananaWarning(false)
							setExclusionsSelected([])
							break;
						case 'Enquiries':
							setIndEnquiry(null)
							break;
						case 'Interactions':
							setIndInteraction(null)
							break;
					}
					break;
				case 'nav_indaccount':
					setIndAccountPopup(false);
					setIndLoading(true)
					changeNavType(indSiteNav)

					let navSiteDataPopup;
					let findNavIndAcc;
					let accountSitesArrIA;
					let indDataPopupONav

					switch (indSiteNav) {
						case 'Sites':
							setSitePopup(true)
							navSiteDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Sites`, axios_config);

							setNewSiteData({ account: indAccount.company, companyArr: navSiteDataPopup.data })
							break;
						case 'Orders':
							setOrderPopup(true)
							findNavIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indAccount._id}`, axios_config);
							accountSitesArrIA = findNavIndAcc.data.sites_pushed
							indDataPopupONav = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);

							setAccountSites(accountSitesArrIA)
							setNewOrderData({account: indAccount.company, popup: indDataPopupONav.data})
							break;
						case 'Preferences':
							setPreferencesPopup(true)
							findNavIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indAccount._id}`, axios_config);
							accountSitesArrIA = findNavIndAcc.data.sites_pushed
							indDataPopupONav = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);

							setAccountSites(accountSitesArrIA)
							setNewOrderData({account: indAccount.company, order_condition: 'recurring', popup: indDataPopupONav.data})
							break;
					}

					setIndLoading(false)
					break
				case 'nav_indsite':
					setIndSitePopup(false);
					setIndLoading(true)
					changeNavType(indSiteNav)

					let findNavIndSite;
					let accountSitesArrIS;
					let indDataPopupPNav;

					switch (indSiteNav) {
						case 'Orders':
							setOrderPopup(true)
							findNavIndSite = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indSite.account_id}`, axios_config);
							accountSitesArrIS = findNavIndSite.data.sites_pushed
							indDataPopupPNav = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);

							setAccountSites(accountSitesArrIS)
							setNewOrderData({account: indSite.company, delivery_site: indSite.site_name, popup: indDataPopupPNav.data})
							break;
						case 'Preferences':
							setPreferencesPopup(true)
							findNavIndSite = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indSite.account_id}`, axios_config);
							accountSitesArrIS = findNavIndSite.data.sites_pushed
							indDataPopupPNav = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);

							setAccountSites(accountSitesArrIS)
							setNewOrderData({account: indSite.company, delivery_site: indSite.site_name, order_condition: 'recurring', popup: indDataPopupPNav.data})
							break;
					}
					setIndLoading(false)
					break
				case 'flagNav':
					let indFlagNavData;

					setAccountPrefSelected(null)
					setShowPrefItems(false)
					setWeekActive(0)
					setBoxActive(0)
					setShowPrefHead(false)
					setAccountPrefHeadSelected(null)
					setFlagNav("Orders")
					setShowFlagged(false)	

					if (flagNav === "Orders") {
						changeNavType(flagNav)
						setIndSiteNav('Details')

						setIndOrderPopup(true);
						setIndLoading(true)

						indFlagNavData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Orders/${ind._id}`, axios_config);
						setIndOrder(indFlagNavData.data)
						setIndLoading(false)
					} else {
						changeNavType("Preferences")
						setIndSiteNav('Details')

						setIndPreferencePopup(true);
						setIndLoading(true)

						indFlagNavData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Preferences/${ind._id}`, axios_config);
						setIndPreference(indFlagNavData.data)
						setIndLoading(false)
					}
					break;
				case 'navigate':
					if (showFlaggedPO) {
						setShowFlaggedPO(false)
						changeNavType("Accounts")
						setIndSiteNav('Details')

						setIndAccountPopup(true);
						setIndLoading(true)

						indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${ind._id}`, axios_config);
						setIndAccount(indData.data)

						setIndLoading(false)
						return false
					}

					if (navTypeUse === "Accounts" && indSiteNav === "Credit") {
						setShowCredits(false)
						setNewCredit({})

						setIndAccountPopup(false)
						changeNavType("Orders")
						setIndSiteNav('Details')

						setIndOrderPopup(true);
						setIndLoading(true)
						indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Orders/${ind._id}`, axios_config);
						setIndOrder(indData.data)
						setIndLoading(false)

						return false
					}

					switch (navTypeUse) {
						case 'Accounts':
							setIndAccountPopup(false);

							if (indSiteNav === "Sites") {
								changeNavType(indSiteNav)
								setIndSiteNav('Details')

								setIndSitePopup(true);
								setIndLoading(true)

								let weeksetSend = JSON.stringify(getCurrentWeekDays(0))

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${indSiteNav}/${ind.site_id}/${weeksetSend}`, axios_config);
								setIndSite(indData.data)
								setIndLoading(false)

							} else if (indSiteNav === "Orders") {
								changeNavType("Orders")
								setIndSiteNav('Details')

								setIndOrderPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${indSiteNav}/${ind._id}`, axios_config);
								setIndOrder(indData.data)
								setIndLoading(false)

							} else if (indSiteNav === "Preferences") {
								setAccountPrefSelected(null)
								setShowPrefItems(false)
								setWeekActive(0)
								setBoxActive(0)
								setShowPrefHead(false)
								setAccountPrefHeadSelected(null)
								changeNavType("Preferences")
								setIndSiteNav('Details')

								setIndPreferencePopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${indSiteNav}/${ind._id}`, axios_config);
								setIndPreference(indData.data)

								let itemKeysP = Object.keys(indData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
								setPrefItemKeys(itemKeysP)
								setIndLoading(false)
							}
							break;
						case 'Sites':
							setIndSitePopup(false)
							changeNavType(indSiteNav)
							setIndSiteNav('Details')

							if (indSiteNav == "Orders") {
								setIndOrderPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${indSiteNav}/${ind._id}`, axios_config);
								setIndOrder(indData.data)
								setIndLoading(false)

							} else if (indSiteNav == "Preferences") {
								setIndPreferencePopup(true);
								setIndLoading(true)
								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${indSiteNav}/${ind._id}`, axios_config);
								setIndPreference(indData.data)

								let itemKeysP = Object.keys(indData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
								setPrefItemKeys(itemKeysP)
								setIndLoading(false)

							} else if (indSiteNav == "Interactions") {
								setIndInteractionPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/${indSiteNav}/${ind._id}`, axios_config);
								setIndInteraction(indData.data)
								setIndLoading(false)
							} else if (indSiteNav === "Details") {
								changeNavType("Accounts")
								setIndAccountPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${ind.account_id}`, axios_config);
								setIndAccount(indData.data)

								setIndLoading(false)
							}
							break;
						case 'Orders':
							setIndOrderPopup(false)
							changeNavType(indSiteNav)
							setIndSiteNav('Details')

							if (ind === "Account") {
								changeNavType("Accounts")
								setIndAccountPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indOrder.account_id}`, axios_config);
								setIndAccount(indData.data)

								setIndLoading(false)
							} else if (ind === "Site") {
								changeNavType("Sites")
								setIndSitePopup(true);
								setIndLoading(true)

								let weeksetSend = JSON.stringify(getCurrentWeekDays(0))

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Sites/${indOrder.site_id}/${weeksetSend}`, axios_config);
								setIndSite(indData.data)

								setIndLoading(false)
							}
							break;
						case 'Preferences':
							setIndPreferencePopup(false)
							changeNavType(indSiteNav)
							setIndSiteNav('Details')
							
							if (ind === "Account") {
								changeNavType("Accounts")

								setIndAccountPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indPreference.account_id}`, axios_config);
								setIndAccount(indData.data)
								setIndLoading(false)

							} else if (ind === "Site") {
								changeNavType("Sites")

								setIndSitePopup(true);
								setIndLoading(true)

								let weeksetSend = JSON.stringify(getCurrentWeekDays(0))

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Sites/${indPreference.site_id}/${weeksetSend}`, axios_config);
								setIndSite(indData.data)
								setIndLoading(false)
							}
							break;
						case 'Interactions':
							if (ind.hasOwnProperty('account_code')) {
								setIndInteractionPopup(false);
								changeNavType("Accounts")
								setIndSiteNav('Details')

								setIndAccountPopup(true);
								setIndLoading(true)

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${ind._id}`, axios_config);
								setIndAccount(indData.data)
								setIndLoading(false)
							} else {
								setIndInteractionPopup(false);
								changeNavType("Sites")
								setIndSiteNav('Details')


								setIndSitePopup(true);
								setIndLoading(true)

								let weeksetSend = JSON.stringify(getCurrentWeekDays(0))

								indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Sites/${ind._id}/${weeksetSend}`, axios_config);
								setIndSite(indData.data)
								setIndLoading(false)
							}
							break;
					}
					break
				case 'ind_interaction':
					setIndSitePopup(false)
					setIndSite(null)
					setIndSiteNav('Details')
					changeNavType("Interactions")
					setInteractionPopup(true)
					setIndLoading(true)

					indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);
					let interactionAccountSelected = indDataPopup.data.accounts.find(acc => acc.company === indSite.company)
					let findIndInteractionAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${interactionAccountSelected._id}`, axios_config);

					if (findIndInteractionAcc.data.sites_pushed.length > 0) {
						setSitesAvailable(findIndInteractionAcc.data.sites_pushed)
					}

					let interactionObjPreloaded = {
						popup: indDataPopup.data,
						account: indSite.company,
						site: indSite.site_name
					}

					if (indSite.account_contacts && indSite.account_contacts.length > 0) {
					  const properties = ['first_name', 'last_name', 'email', 'phone'];

					  properties.forEach(prop => {
					    if (indSite.account_contacts[0].hasOwnProperty(prop)) {
					      interactionObjPreloaded[prop] = indSite.account_contacts[0][prop];
					    }
					  });
					}

					setNewInteractionData(interactionObjPreloaded)
					setIndLoading(false)
					break;
				case 'nav_pref':
					const confirm_close = window.confirm(`You are about to discard this preference and open the existing preference.`)
					if (confirm_close) {
						setPreferencesPopup(false);
						setNewOrderData({})
						setPopUpPage(1)
						setOrderCategory(null)
						setMainOrder({})
						setMainObj({})
						setValidationError(null)

						setIndPreferencePopup(true)
						setIndLoading(true)
						let indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Preferences/${ind._id}`, axios_config);
						setIndPreference(indData.data)
						let itemKeysP = Object.keys(indData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
						setPrefItemKeys(itemKeysP)
						setIndLoading(false)
					}
					break;
				case 'delete':
					let confirm_status;
					if (navTypeUse === "Sites") {
						confirm_status = window.confirm(`Deleting this site will also delete all their orders, preferences and logs.`)
					} else if (navTypeUse === "Accounts") {
						confirm_status = window.confirm(`Deleting this account will also delete all their sites, orders, preferences and logs.`)
					} else {
						confirm_status = window.confirm(`Are you sure you would like to delete this?`)
					}
					
					if (confirm_status === true) {
						let deleting = await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
							type: navTypeUse,
							ind: ind
						});
						window.location.reload(false)
					} else {
						return false;
					}

					if (navTypeUse === "Orders") {
						await axios.post(`/api/admin/warehouse/refreshPurchase/${param._id}`, {
							orderData: ind
						});
					}
				case 'handleBox':
					setBoxActive(ind)
					break;
				case 'handleWeek':
					setWeekActive(ind)
					break;
				case 'email_change':
					setEmailChange(true)
					setNewAccountEmail(indAccount.email)
					setIndAccountPopup(false)
					break;
				case 'handle_email_change':
					setNewAccountEmail(ind.target.value)
					break;
				case 'handle_email_submit':
					if (newAccountEmail === '') {
						setValidationError("Please enter an email.")
						return false
					} else {
						if (!(isValidEmail(newAccountEmail))) {
							setValidationError("Please enter a valid email address.")
							return false
						}
					}

					if (newAccountEmail === indAccount.email) {
						setValidationError("Please enter an email that is different from the current one.")
						return false
					}

					let confirm_email_change = window.confirm("Are you sure you would like to change the primary email for this account? They will also be sent a new verification email to their new email address.")
					if (confirm_email_change) {
						setIsLoading(true)
						setValidationError(null)

						let accNewSent = await axios.post(`/api/admin/dashboard/change_email/${param._id}`, {
							accountData: indAccount,
							newEmail: newAccountEmail
						});
						
						setEmailChange(false)
						setNewAccountEmail(null)
						refreshDashboard()
						setIndSiteNav('Details')
						setPopUpPage(1)
						setIndAccount(null)

						let switchingSiteNew = accNewSent.data;
						indPopUpControls('open', switchingSiteNew, 'Accounts')

						setIsLoading(false)
					}
					break;
				case 'temp_change':
					setIndLoading(true)
					changeNavType("Orders")
					setIndPreference(null)
					setIndPreferencePopup(false)
					setOrderPopup(true)
					setPopUpPage(3)

					let indDataPopup = await axios.get(`/api/admin/dashboard/popup/${param._id}/Orders`, axios_config);
					let TCPAccountSelected = indDataPopup.data.accounts.find(acc => acc.company === ind.company)
					let findTCPIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${TCPAccountSelected._id}`, axios_config);

					if (findTCPIndAcc.data.sites_pushed.length > 0) {
						setAccountSites(findTCPIndAcc.data.sites_pushed)
					}

					let tempChangePref = {
						popup: indDataPopup.data,
						order_condition: 'recurring_temp',
						order_type: ind.preference_type,
						warning: false,
					    account: ind.company,
					    delivery_site: ind.site_name,
					    account_sites: findTCPIndAcc.data.sites_pushed
					}

					if (ind.preference_type === "Fruit") {
						setBoxLoops(ind.items.length)
						tempChangePref['box_loops'] = ind.items.length
					}

					setNewOrderData(tempChangePref)

					let delivery_day_options = []
					let siteSelected = findTCPIndAcc.data.sites_pushed.find(site => site.site_name === ind.site_name)

					if (siteSelected) {
						if (siteSelected.hasOwnProperty('preferences')) {
							for (let ip in siteSelected.preferences) {
								delivery_day_options.push(`${siteSelected.preferences[ip].delivery_day} - ${siteSelected.preferences[ip].preference_type} ${siteSelected.preferences[ip].hasOwnProperty('box_type') ? '(' + siteSelected.preferences[ip].box_type + ')' : ''}`)
							}
						}
					}

					if (delivery_day_options.length > 0) {
						setDeliveryDayOptions(delivery_day_options)
					}

					setIndLoading(false)
					let formattedDeliveryDay = `${ind.delivery_day} - ${ind.preference_type} ${ind.hasOwnProperty('box_type') ? "(" + ind.box_type + ")" : ""}`
		            handleNewOrderData('delivery_day', {value: formattedDeliveryDay}, null, null, ind.preference_type, tempChangePref);
					break;
				case 'PrefLink':
					setIndOrderPopup(false)
					changeNavType("Preferences")
					setIndSiteNav('Details')
					setIndPreferencePopup(true);
					setIndLoading(true)

					let indPrefLinkData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Preferences/${ind}`, axios_config);
					setIndPreference(indPrefLinkData.data)

					let itemKeysP = Object.keys(indPrefLinkData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
					setPrefItemKeys(itemKeysP)

					setIndLoading(false)
					break;
				case 'clone_account':
					setIndAccountPopup(false);
					setAccountPopup(true)

					setNewAccountData({
						company: indAccount.company,
						abn: indAccount['abn'] || '',
						email: indAccount.email,
						phone: (indAccount.contacts[0] && indAccount.contacts[0].phone) && indAccount.contacts[0].phone,
						pcontact: `${indAccount.contacts[0].first_name} ${indAccount.contacts[0].last_name ? indAccount.contacts[0].last_name : ''}`,
						invoice_cycle: indAccount.invoice_cycle,
						payment_terms: indAccount.payment_terms,
					})

					let indAccountContacts;
					if (indAccount.contacts.length <= 1) {
						indAccountContacts = [...indAccount.contacts, {type: "Secondary"}, {type: "Accountant"}]
					} else {
						indAccountContacts = indAccount.contacts
					}

					setAccountContacts(indAccountContacts)
					setNewAccountAddress({formatted_address: `${indAccount.billing_location.street}, ${indAccount.billing_location.city} ${indAccount.billing_location.state} ${indAccount.billing_location.postcode}, ${indAccount.billing_location.country}`})
					break;
				case 'clone_site':
					setIndSitePopup(false);
					setSitePopup(true)

					setNewSiteData({
						account: indSite.company,

						site_contact_fname: indSite.site_contact.first_name,
						site_contact_lname: indSite.site_contact.last_name,
						site_contact_email: indSite.site_contact.email,
						site_contact_phone: indSite.site_contact.phone,

						site_instructions: indSite.site_instructions,

						site_pass: indSite.site_pass,
						pass_number: indSite.pass_number,
						crate: indSite.crate,
						supplier_platform: indSite.supplier_platform || 'No',
						clone: true,
					})
					setNewSiteAddress({formatted_address: `${indSite.site_location.street}, ${indSite.site_location.city} ${indSite.site_location.state} ${indSite.site_location.postcode}, ${indSite.site_location.country}`})
					break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}	
		}
	}

	const changeIndSiteNav = (type) => {
		setIndSiteNav(type)
		setAccountPrefSelected(null)
		setShowPrefItems(false)
		setWeekActive(0)
		setBoxActive(0)
		setShowPrefHead(false)
		setAccountPrefHeadSelected(null)
	}

	const handleNewEnquiryData = (name, input) => {
		if (input.target) {
			setNewEnquiryData({ ...newEnquiryData, [name]: input.target.value });
		} else {
			setNewEnquiryData({ ...newEnquiryData, [name]: input.value });
		}
	};

	const handleNewEnquiryAddress = (place) => {
		setNewEnquiryAddress(place);
	};

	const handleNewInteractionData = async (name, input) => {
		let interData = { ...newInteractionData }

		try {
			if (name == "account") {
				let accountSitesArr = []
				let accountSelected = newInteractionData.popup.accounts.find(acc => acc.company === input.value)
				let findIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${accountSelected._id}`, axios_config);

				if (findIndAcc.data.sites_pushed.length > 0) {
					setSitesAvailable(findIndAcc.data.sites_pushed)
				}

				interData['site'] = ""

				if (accountSelected.contacts && accountSelected.contacts.length > 0) {
				  const properties = ['first_name', 'last_name', 'email', 'phone'];

				  properties.forEach(prop => {
				    if (accountSelected.contacts[0].hasOwnProperty(prop)) {
				      interData[prop] = accountSelected.contacts[0][prop];
				    }
				  });
				}
			}

			if (name == "upload_email") {
				setEmailFile(input.value.target.files[0])
				return false;
			}

			if (input.target) {
				interData[name] = input.target.value
				setNewInteractionData(interData);
			} else {
				if (name == "tags") {
					interData[name] = input
					setNewInteractionData(interData);
				} else {
					interData[name] = input.value
					setNewInteractionData(interData);
				}
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	};

	const handleInteractionHelp = (control, nav=false) => {
		switch (control) {
			case 'toggle':
				if (interactionPopup) {
					setInteractionPopup(false);
					setShowInteractionHelp(true);
				} else {
					setInteractionPopup(true);
					setShowInteractionHelp(false);
					setInteractionNav("Gmail")
				}
				break;

			case 'nav':
				setInteractionNav(nav)
				break;
		}

	}

	const changeStatus = async (control, type=null, ind=null, status=null) => {
		switch (control) {
			case 'toggle':
				setChangingStatus(!changingStatus)

				if (changingStatus) {
					setInactiveDatePopup(false)
					setInactiveDate(null)
				}
				break;
			case 'set':
				if ((type && (type === "Preference") || type === "Site" || type === "Account") && (status && status === "Inactive") && (!inactiveDatePopup)) {
					// open popup to set a starting date.
					setInactiveDatePopup(true)
					return false;
				}

				const confirm_status = window.confirm(`Are you sure you would like to change the status to ${status}?`)
				if (confirm_status) {
					await axios.post(`/api/admin/dashboard/status/${param._id}`, {
						type: type,
						ind: ind._id,
						status: status,
						inactiveDate: inactiveDate
					});
					window.location.reload(false)
				}
				break;
			case 'set_date':
				setInactiveDate(type)
				break;
		}
	}

    const isAlternatingPage = (page) => {
        const totalAlternatingPages = boxLoops > 0 ? boxLoops * 2 : 2;
        return page > 3 && page <= 3 + totalAlternatingPages;
    }

    const isSubmitPage = (page) => {
    	if (newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes && page === 6) {
    		return true
    	} else {
        	const totalAlternatingPages = boxLoops > 0 ? boxLoops * 2 : 2;
        	return page === 3 + totalAlternatingPages + 1;
    	}
    }

    const isAlternatingPagePref = (page) => {
        const totalAlternatingPages = boxLoops > 0 ? boxLoops * 2 : 2;
        return page > 2 && page <= 2 + totalAlternatingPages;
    }

    const isSubmitPagePref = (page) => {
    	if (newOrderData.hasOwnProperty('auto_assign_boxes') && newOrderData.auto_assign_boxes && page === 5) {
    		return true
    	} else {
        	const totalAlternatingPages = boxLoops > 0 ? boxLoops * 2 : 2;
        	return page === 2 + totalAlternatingPages + 1;
    	}
    }

    const handleOpenEmail = async (control, emailObj) => {
    	switch (control) {
	    	case 'open':
	    		setEmailBody(emailObj)
	    		break;
	    	case 'delete':
	    		let confirm_del_email = window.confirm(`Are you sure you would like to delete this email record?`)

				if (confirm_del_email === true) {
					await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
						type: 'Emails',
						ind: emailObj
					});
					window.location.reload(false)
				}
	    		break;
    	}
    }

	const DisplayHTMLComponent = ({ htmlContent }) => {
	  const sanitizedHTML = DOMPurify.sanitize(htmlContent);

	  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
	};


	const doRangesOverlap = (range1, range2) => {
	    let [start1, end1] = range1.map(date => new Date(date));
	    let [start2, end2] = range2.map(date => new Date(date));
	    return start1 <= end2 && start2 <= end1;
	};

	const handleTempCancel = async (control, ind=null) => {

		try {
			switch (control) {
				case 'toggle':
					setShowTempCancel(!showTempCancel)
					break;
				case 'set':
					setTempCancelData({
						prefId: indPreference._id,
						dates: ind
					})
					break;
				case 'submit':
					if (tempCancelData['dates'] || tempCancelData['dates'] == '') {
						if (!(tempCancelData['dates'].includes(null))) {
							if (!(checkDateRange(tempCancelData['dates'], indPreference['delivery_day']))) {
								setValidationError(`Please select a date range that includes your delivery day (${indPreference['delivery_day']}).`)
								return false;
							} else {
								// check if any existing tempCancels exist
								let tempOverlap = indPreference.tempCancels.some(tempCancel => doRangesOverlap(tempCancel.dates, tempCancelData['dates']));

								if (tempOverlap) {
									setValidationError(`A cancellation exists over these dates for this preference.`)
									return false;
								}
							}
						} else {
							setValidationError(`Please select a date range.`)
							return false;
						}
					} else {
						setValidationError(`Please select a date range.`)
						return false;
					}


					setValidationError(null)
					setIsLoading(true)
					await axios.post(`/api/admin/dashboard/temp_cancel/${param._id}`, {
						tempCancelData: tempCancelData,
					})
					
					setShowTempCancel(false);
					setTempCancelData({})
					refreshDashboard()
					setIndSiteNav('Cancellations')

					setIndLoading(true)
					let indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Preferences/${indPreference._id}`, axios_config);
					setIndPreference(indData.data)
					let itemKeysP = Object.keys(indData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
					setPrefItemKeys(itemKeysP)
					setIndLoading(false)

					setIsLoading(false)
					break;
		    	case 'delete':
		    		let confirm_del_tempcancel = window.confirm(`Are you sure you would like to delete this temporary cancellation?`)

					if (confirm_del_tempcancel === true) {
						setIsLoading(true)
						await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
							type: 'TempCancel',
							ind: ind
						});
						
						refreshDashboard()
						setIndSiteNav('Cancellations')

						setIndLoading(true)
						let indData = await axios.get(`/api/admin/dashboard/ind/${param._id}/Preferences/${indPreference._id}`, axios_config);
						setIndPreference(indData.data)
						let itemKeysP = Object.keys(indData.data).filter(key => key.startsWith('items_') && !isNaN(key.split('_')[1]));
						setPrefItemKeys(itemKeysP)
						setIndLoading(false)

						setIsLoading(false)
					}
		    		break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const handleAllergies = async (control, ind=null) => {
		switch (control) {
			case 'open':
				setShowAllergies(true)
				setAllergiesData({
					site: indSite._id,
					allergies: (indSite.allergies && indSite.allergies.length > 0) ? indSite.allergies.map(item => inventoryData.filter(inv => item.item.toString() === inv._id.toString())[0].item_name) : []
				})
				break;
			case 'close':
				setAllergiesData({})
				setShowAllergies(false)
				break;
			case 'set':
				let allergiesDataDup = {...allergiesData}
				allergiesDataDup['allergies'] = ind.map(sel => sel.value)
				setAllergiesData(allergiesDataDup)
				break;
			case 'submit':
				setIsLoading(true)
				let siteNavPulledAllergy = await axios.post(`/api/admin/dashboard/allergies/${param._id}`, {
					allergiesData: allergiesData,
				})

				setShowAllergies(false);
				setAllergiesData({})
				setIndSite(null)
				refreshDashboard()
				setIndSiteNav('Allergies')
				indPopUpControls('open', siteNavPulledAllergy.data, 'Sites')
				setIsLoading(false)
				break;
		}
	}

	const handleSiteGroups = async (control, ind=null, group_name=null) => {
		let siteGroupsDataDup = {...siteGroupsData}

		switch (control) {
			case 'open':
				let sitesInGroups = indAccount.site_groups ? indAccount.site_groups.flat() : []
				let siteOptions = indAccount.sites.map(site => site.site.toString())

				let sitesFiltered = indAccount.sites_pushed.filter(site => {
					let sitesInGroupsValid = sitesInGroups.includes(site.site_id.toString())
					let siteOptionsValid = siteOptions.includes(site.site_id.toString())

					return (siteOptionsValid && !sitesInGroupsValid)
				})

				let sitesMapped = sitesFiltered.map(site => site.site_name)
				setShowSiteGroups(true)
				setSiteGroupsData({
					site_options: sitesMapped,
					indAccount: indAccount._id
				})
				break;
			case 'close':
				setSiteGroupsData({})
				setValidationError(null)
				setShowSiteGroups(false)
				break;
			case 'set':
				siteGroupsDataDup['sites_selected'] = ind.map(sel => sel.value)
				setSiteGroupsData(siteGroupsDataDup)
				break;
			case 'edit':
				let sitesInGroupsEdit = indAccount.site_groups ? indAccount.site_groups.flat() : []
				let siteOptionsEdit = indAccount.sites.map(site => site.site.toString())

				let sitesFilteredEdit = indAccount.sites_pushed.filter(site => {
					let sitesInGroupsValidEdit = sitesInGroupsEdit.includes(site.site_id.toString())
					let siteOptionsValidEdit = siteOptionsEdit.includes(site.site_id.toString())

					return (siteOptionsValidEdit && !sitesInGroupsValidEdit)
				})

				let sitesSelected = indAccount.sites_pushed.filter(site => ind.includes(site.site_id.toString()))
				let sitesSelectedMapped = sitesSelected.map(site => site.site_name)
				let sitesMappedEdit = sitesFilteredEdit.map(site => site.site_name)

				setShowSiteGroups(true)
				setSiteGroupsData({
					site_options: sitesMappedEdit,
					indAccount: indAccount._id,
					sites_selected: sitesSelectedMapped,
					edit: true,
					group_name: group_name
				})
				break;
			case 'submit':
				if (!(siteGroupsDataDup['sites_selected'] && siteGroupsDataDup['sites_selected'].length > 1)) {
					setValidationError(`A minimum of 2 sites must be selected to form a group.`)
					return false;
				}

				setValidationError(null)
				setIsLoading(true)
				let siteNavPulledGroups = await axios.post(`/api/admin/dashboard/site_groups/${param._id}`, {
					siteGroupsData: siteGroupsData,
				})

				setShowSiteGroups(false);
				setSiteGroupsData({})
				setIndSite(null)
				setIndSitePopup(false)
				setIndAccount(null)
				refreshDashboard()
				setIndSiteNav('Site Pricing')
				indPopUpControls('open', siteNavPulledGroups.data, 'Accounts')
				setIsLoading(false)				
				break;
			case 'delete':
				const confirm_del_gsite = window.confirm(`Are you sure you would like to delete this site group?`)
				if (confirm_del_gsite) {
					setIsLoading(true)
					let siteNavPulledGroupsDel = await axios.post(`/api/admin/dashboard/site_groups/${param._id}`, {
						siteGroupsData: siteGroupsData,
						indAccount: indAccount._id,
						group_name: group_name,
						delete: true
					})

					setShowSiteGroups(false);
					setSiteGroupsData({})
					setIndSite(null)
					setIndSitePopup(false)
					setIndAccount(null)
					refreshDashboard()
					setIndSiteNav('Site Pricing')
					indPopUpControls('open', siteNavPulledGroupsDel.data, 'Accounts')
					setIsLoading(false)	
				}
				break;
		}
	}

	const handleClone = async (control, name=null, target=null) => {

		try {
			switch(control) {
				case 'open':
					setShowClone(true)
					setIndLoading(true)

					let newCloneDataStart = {...newCloneData}
					let findIndAcc = await axios.get(`/api/admin/dashboard/ind/${param._id}/Accounts/${indPreference.account_id}`, axios_config);
					newCloneDataStart['sites'] = findIndAcc.data.sites_pushed

					setNewCloneData(newCloneDataStart)
					setIndLoading(false)
					break;
				case 'close':
					setShowClone(false)
					setNewCloneData({})
					setValidationError(null)
					break;
				case 'set':
					let newCloneDataDup = {...newCloneData}
					newCloneDataDup[name] = target.value

					setNewCloneData(newCloneDataDup)
					break;
				case 'submit':
					if (!(newCloneData.hasOwnProperty('site_selected')) || newCloneData['site_selected'] == '') {
						setValidationError("Please select a delivery site to clone this preference to.")
						return false
					}

					if (!(newCloneData.hasOwnProperty('delivery_day')) || newCloneData['delivery_day'] == '') {
						setValidationError("Please select a delivery day to clone this preference to.")
						return false
					}

					if ((indPreference.site_name === newCloneData.site_selected) && (indPreference.delivery_day === newCloneData.delivery_day)) {
						setValidationError("You can not clone this preference to the same site and day.")
						return false
					}

					let matchingPrefSite = newCloneData.sites.find(site => site.site_name === newCloneData.site_selected)
					let preferenceExistsClone = matchingPrefSite.preferences.filter(pref => ((pref.site_name === newCloneData.site_selected) && (pref.delivery_day === newCloneData.delivery_day) && (pref.preference_type === indPreference.preference_type)))
					if (preferenceExistsClone.length > 0) {
						setValidationError("This site already has an active preference on this day for this type.")
						return false
					}

					setValidationError(null)


					const confirm_clone_item = window.confirm(`Are you sure you would like to clone this preference to another site/day?`)
					if (confirm_clone_item) {
						setIsLoading(true)
						let siteNavPulledClone = await axios.post(`/api/admin/dashboard/clone/${param._id}`, {
							newCloneData: newCloneData,
							indPreference: indPreference
						})

						setShowClone(false)
						setNewCloneData({})
						setIndPreference(null)
						setIndPreferencePopup(false)
						refreshDashboard()
						setIndSiteNav('Details')
						indPopUpControls('open', siteNavPulledClone.data, 'Sites')
						setIsLoading(false)
					}	
					break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	function adjustToPreviousDayNoonAEST(orderDate, daysBefore = 1) {
	    const currentTime = new Date();
	    const orderDateObject = new Date(orderDate);

	    const orderDateAEST = new Date(orderDateObject.getTime() + (10 * 60 * 60 * 1000));
	    const currentTimeAEST = new Date(currentTime.getTime() + (10 * 60 * 60 * 1000));

	    orderDateAEST.setHours(0, 0, 0, 0)
	    orderDateAEST.setDate(orderDateAEST.getDate() + 1);
	    currentTimeAEST.setHours(0, 0, 0, 0)
	    currentTimeAEST.setDate(currentTimeAEST.getDate() + 1);

	    const differenceInBusinessDays = calculateBusinessDays(currentTimeAEST, orderDateAEST);

	    if (differenceInBusinessDays === daysBefore) {
	        const AESTTime = new Date(currentTime.getTime() + (10 * 60 * 60 * 1000));
	        const AESTHour = AESTTime.getUTCHours();
	        const AESTMinute = AESTTime.getUTCMinutes();

	        return AESTHour >= 23 || (AESTHour === 23 && AESTMinute > 0);
	    } else {
	        return differenceInBusinessDays < daysBefore;
	    }
	}

	function calculateBusinessDays(startDate, endDate) {
	    let count = 0;
	    let currentDate = new Date(startDate);

	    while (currentDate < endDate) {
	        const dayOfWeek = currentDate.getUTCDay();
	        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
	            count++;
	        }
	        currentDate.setDate(currentDate.getDate() + 1);
	    }

	    return count;
	}

	const handleEditPrefCombined = (selection) => {
		let editPrefObj = {...newOrderData}
		let specificItemsArray = [...indPreference[`items_${selection.value}`]]
		editPrefObj['items_to_edit'] = selection.value

		if (indPreference.preference_type == "Fruit") {
			editPrefObj['box_type'] = indPreference.box_type

			if (indPreference.box_type == "Seasonal") {
				for (let l=0; l < specificItemsArray.length; l++) {
					let pFilteredExtras = specificItemsArray[l].filter(item => {
						let pItemFound = inventoryData.find(data => data._id.toString() === item.item.toString());
						let pPropertyName = pItemFound.subcategory === "Extra Items" ? "Extra Items" : "Fruit";

						return (pPropertyName !== "Extra Items")
					})

					editPrefObj[`box_qty${l}`] = pFilteredExtras.map(item => parseInt(item.quantity)).reduce((partialSum, a) => partialSum + a, 0)
				}
			}

			editPrefObj['box_loops'] = specificItemsArray.length
			setBoxLoops(specificItemsArray.length)
		}

		setNewOrderData(editPrefObj)

		let pmainObjItems = {};
		let pmainOrderItems = {};
		let pfruitMainObjItems = [];
		let pfruitMainOrderItems = [];
		let pinclusion_item_ids;
		let pexclusion_item_ids;
		let pexclusion_items;
		let pextra_item_names = []

		if (indPreference.preference_type === "Fruit") {
			for (let l = 0; l < specificItemsArray.length; l++) {
			    let ptempMainObjItems = {};
			    let ptempMainOrderItems = {};
			    let ptempExtraItems = {}

			    specificItemsArray[l].forEach(item => {
			        let inventoryItem = inventoryData.find(data => data._id === item.item);

			        // Determine the property name based on the subcategory
			        let propertyName = inventoryItem.subcategory === "Extra Items" ? "Extra Items" : "Fruit";
			        if (propertyName === "Extra Items") {
			        	ptempExtraItems[inventoryItem.item_name] = String(item.quantity)
			        }
			        
			        ptempMainObjItems[propertyName] = ptempMainObjItems[propertyName] || {};
			        ptempMainObjItems[propertyName][inventoryItem.item_name] = String(item.quantity);

			        if (!(ptempMainOrderItems.hasOwnProperty(propertyName))) {
			            ptempMainOrderItems[propertyName] = [];
			        }

			        ptempMainOrderItems[propertyName].push(inventoryItem.item_name);
			    });

			    pfruitMainObjItems.push(ptempMainObjItems);
			    pfruitMainOrderItems.push(ptempMainOrderItems);
			    pextra_item_names.push(ptempExtraItems)
			}


			pinclusion_item_ids = specificItemsArray.map(array => array.map(obj => obj.item));
			pexclusion_item_ids = pexclusion_item_ids = pinclusion_item_ids.map(subArray => menuData[0].items.filter(item => !subArray.includes(item.product)).map(filteredItem => filteredItem.product));
			pexclusion_items = pexclusion_item_ids.map(subArray => inventoryData.filter(item => subArray.includes(item._id)).map(filteredItem => filteredItem.item_name));
		} else {
			specificItemsArray.forEach(item => {
			  	let inventoryItem = inventoryData.find(data => data._id === item.item);
			  	pmainObjItems[inventoryItem.subcategory] = pmainObjItems[inventoryItem.subcategory] || {};
			  	pmainObjItems[inventoryItem.subcategory][inventoryItem.item_name] = String(item.quantity);

			  	if (!(pmainOrderItems.hasOwnProperty(inventoryItem.subcategory))) {
			  		pmainOrderItems[inventoryItem.subcategory] = []
			  	}

			  	pmainOrderItems[inventoryItem.subcategory].push(inventoryItem.item_name);
			});

			pinclusion_item_ids = specificItemsArray.map(II => II.item)
			pexclusion_item_ids = menuData[0].items.filter(MD => !(pinclusion_item_ids.includes(MD.product))).map(MD => MD.product)
			pexclusion_items = inventoryData.filter(item => pexclusion_item_ids.includes(item._id)).map(item => item.item_name)
		}

		if (indPreference.preference_type == "Fruit" && indPreference.box_type == "Seasonal") {
			let mainOrderFiltered = {};
			let mainOrderStructured = {};

			pexclusion_item_ids.forEach(subArray => {
			    let tempCategoryMap = {};

			    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
			        if (!tempCategoryMap[item.category]) {
			            tempCategoryMap[item.category] = [];
			        }
			        tempCategoryMap[item.category].push(item.item_name);
			    });

			    if (subArray.length === 0) {
			        if (Object.keys(mainOrderFiltered).length === 0) {
			            tempCategoryMap["Fruit"] = [];
			        } else {
			            Object.keys(mainOrderFiltered).forEach(category => {
			                tempCategoryMap[category] = [];
			            });
			        }
			    }

			    Object.keys(tempCategoryMap).forEach(category => {
			        if (!mainOrderFiltered[category]) {
			            mainOrderFiltered[category] = [];
			        }
			        mainOrderFiltered[category].push(tempCategoryMap[category]);
			    });
			});

			pexclusion_item_ids.forEach(subArray => {
			    let tempCategoryMap = {};

			    if (subArray.length === 0) {
			        if (Object.keys(mainOrderStructured).length === 0) {
			            tempCategoryMap["Fruit"] = {}; // Assuming "Fruit" as a default category
			        } else {
			            Object.keys(mainOrderStructured).forEach(category => {
			                tempCategoryMap[category] = {};
			            });
			        }
			    }

			    inventoryData.filter(item => subArray.includes(item._id)).forEach(item => {
			        if (!tempCategoryMap[item.category]) {
			            tempCategoryMap[item.category] = {};
			        }
			        tempCategoryMap[item.category][item.item_name] = "";
			    });

			    Object.keys(tempCategoryMap).forEach(category => {
			        if (!mainOrderStructured[category]) {
			            mainOrderStructured[category] = [];
			        }
			        mainOrderStructured[category].push(tempCategoryMap[category]);
			    });
			});

			if (pextra_item_names.length > 0) {
				let mainOrderExtras = pextra_item_names.map(obj => Object.keys(obj));

				mainOrderFiltered['Extra Items'] = mainOrderExtras
				mainOrderStructured['Extra Items'] = pextra_item_names
			}

			setMainOrder(mainOrderFiltered)
			setMainObj(mainOrderStructured)

		} else if (indPreference.preference_type == "Fruit" && indPreference.box_type == "Flexi") {
			setFlexiMainObj(pfruitMainObjItems);
			setFlexiMainOrder(pfruitMainOrderItems)

		} else {
			setMainObj(pmainObjItems);
			setMainOrder(pmainOrderItems)
		}
	}

	function flattenItems(items) {
	    return items.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenItems(val)) : acc.concat(val), []);
	}

	function getTotalQuantity(data) {
	    let totalQuantity = 0;
	    if (data.freqDetails) {
	        for (const key in data) {
	            if (key.startsWith('items_')) {
	                const items = flattenItems(data[key]);
	                items.forEach(item => {
	                    totalQuantity += parseInt(item.quantity);
	                });
	            }
	        }
	    } else {
	        const items = flattenItems(data.items);
	        items.forEach(item => {
	            totalQuantity += parseInt(item.quantity);
	        });
	    }

	    if (data['frequency'] === "Fortnightly") {
	    	return totalQuantity / 2;
	    } else {
	    	return totalQuantity;
	    }
	}

	const handleCustomPricing = async (control, ind=null) => {
		switch (control) {
			case 'open':
				let findSiteFT = allTiers.filter(tier => (tier.type === "Fruit" && tier.location === "Site") && tier.siteLink.toString() === indSite._id.toString())
				let findSitePT = allTiers.filter(tier => (tier.type === "Milk" && tier.location === "Site") && tier.siteLink.toString() === indSite._id.toString())

				if (findSiteFT.length > 0) {
					setSiteFruitTiers(findSiteFT[0].items)
				} else {
					let ftDup = [...fruitTiers]
					setSiteFruitTiers(ftDup)
				}

				if (findSitePT.length > 0) {
					setSiteProcalTiers(findSitePT[0].items)
				} else {
					let ptDup = [...procalTiers]
					setSiteProcalTiers(ptDup)
				}

				setShowCustomPricing(true)
				break;
			case 'close':
				setSiteFruitTiers([])
				setSiteProcalTiers([])
				setShowCustomPricing(false)
				break;
			case 'nav':
				setTierNav(ind)
				break;
		}
	}

	const tierControls = async (type, control, index=null, name=null, input=null) => {
		switch (control) {
			case 'add':
				setSiteProcalTiers([...siteProcalTiers, {
					item: '',
					tier1: '',
					tier2: '',
					tier3: '',
					tier4: '',
					tier5: '',
				}])
				break;
			case 'delete':
				let procalTiersDup = [...siteProcalTiers]
				let spliced = procalTiersDup.splice(index, 1)
				setSiteProcalTiers(procalTiersDup)
				break;
			case 'set':
				if (type === "milk") {
					let procalTiersDupSet = [...siteProcalTiers]
					if (name === "item") {
						procalTiersDupSet[index][name] = input.value
					} else {
						procalTiersDupSet[index][name] = input.target.value
					}

					setSiteProcalTiers(procalTiersDupSet)
				} else {
					let fruitTiersDup = [...siteFruitTiers]
					fruitTiersDup[index][name] = input.target.value

					setSiteFruitTiers(fruitTiersDup)
				}
				break;
			case 'submit':
				const confirm_price = window.confirm(`Are you sure you would like to create custom pricing for this site?`)
				if (confirm_price) {
					await axios.post(`/api/admin/dashboard/tiers/${param._id}`, {
						siteLink: indSite._id,
					  	tierData: [siteProcalTiers, siteFruitTiers]
					});

					window.location.reload(false);
				}
				break;
		}
	}

	function isUserBirthday(dob) {
		const currentDate = new Date();
		const userBirthday = new Date(dob);
		return (
			currentDate.getDate() === userBirthday.getDate() &&
			currentDate.getMonth() === userBirthday.getMonth()
		);
	}

	const handleShowPrefItems = (control, ind=null) => {
		switch (control) {
			case 'open':
				if (accountPrefSelected && accountPrefSelected._id.toString() === ind._id.toString()) {
					setShowPrefItems(false)
					setAccountPrefSelected(null)
					setWeekActive(0)
					setBoxActive(0)
				} else {
					setShowPrefItems(true)
					setAccountPrefSelected(ind)
					setWeekActive(0)
					setBoxActive(0)
				}
				break;
			case 'close':
				setShowPrefItems(false)
				setAccountPrefSelected(null)
				setWeekActive(0)
				setBoxActive(0)
				setShowPrefHead(false)
				setAccountPrefHeadSelected(null)
				break;
			case 'initial_open':
				if (accountPrefHeadSelected && (accountPrefHeadSelected[0]._id.toString() === ind[0]._id.toString())) {
					setShowPrefItems(false)
					setAccountPrefSelected(null)
					setWeekActive(0)
					setBoxActive(0)

					setShowPrefHead(false)
					setAccountPrefHeadSelected(null)
				} else {
					setShowPrefItems(false)
					setAccountPrefSelected(null)
					setWeekActive(0)
					setBoxActive(0)

					setShowPrefHead(true)
					setAccountPrefHeadSelected(ind)
				}
				break;
		}
	}

	function sortSIorders(SIorders) {
	    SIorders.sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));
	    
	    const activeOrders = SIorders.filter(order => order.status !== 'Inactive');
	    const inactiveOrders = SIorders.filter(order => order.status === 'Inactive');

	    return activeOrders.reverse().concat(inactiveOrders);
	}

	function sortSIPrefs(siPrefs) {
	    const getDayIndex = (day) => ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].indexOf(day);

	    return siPrefs.sort((a, b) => {
	        if (a.status === "Inactive" && b.status !== "Inactive") return 1;
	        if (a.status !== "Inactive" && b.status === "Inactive") return -1;
	        
	        if (a.delivery_day && b.delivery_day) {
	            return getDayIndex(a.delivery_day) - getDayIndex(b.delivery_day);
	        }
	        
	        return 0;
	    });
	}

	function sortPrefsIntoDays(siPrefs) {
	  	const getDayIndex = (day) => ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].indexOf(day);

	  	const result = {};

	  	siPrefs.forEach(pref => {
	      	const deliveryDay = pref ? pref.delivery_day : null;

	      	if (deliveryDay) {
	          	if (!result[deliveryDay]) {
	              	result[deliveryDay] = [];
	          	}

	          	result[deliveryDay].push(pref);
	      	}
	  	});
	  
	  	Object.keys(result).forEach(day => {
	      	result[day].sort((a, b) => {
	          	if (a.status === "Inactive" && b.status !== "Inactive") return 1;
	          	if (a.status !== "Inactive" && b.status === "Inactive") return -1;
	          
	          	return 0;
	      	});
	  	});

	  	return result;
	}

	function sortFlaggedOrders(SIorders) {
	    const mappedOrders = SIorders.map(siorder => {
	        return { ...siorder, delivery_date: siorder.delivery_date };
	    });

	    mappedOrders.sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date));
	    
	    return mappedOrders.reverse();
	}

	const handleFlagged = (control, ind?) => {
		switch(control) {
			case 'open':
				setShowFlagged(true)
				break;
			case 'close':
				setAccountPrefSelected(null)
				setShowPrefItems(false)
				setWeekActive(0)
				setBoxActive(0)
				setShowPrefHead(false)
				setAccountPrefHeadSelected(null)

				setFlagNav("Orders")
				setShowFlagged(false)
				break;
			case 'nav':
				setAccountPrefSelected(null)
				setShowPrefItems(false)
				setWeekActive(0)
				setBoxActive(0)
				setShowPrefHead(false)
				setAccountPrefHeadSelected(null)

				setFlagNav(ind)
				break;
			case 'open_po':
				setShowFlaggedPO(true)
				break;
			case 'close_po':
				setShowFlaggedPO(false)
				break;
		}
	}

	const handlePOLimit = async (control, name?, input?) => {
		switch(control) {
			case 'open':
				setIndAccountPopup(false)
				setShowPOLimit(true)
				break;
			case 'close':
				setShowPOLimit(false)
				setNewPOLimit({})
				setIndAccountPopup(true)
				setValidationError(null)
				break;
			case 'set':
				let newPOLimitDup = { ...newPOLimit }

				if (name === "date_range") {
					newPOLimitDup[name] = input
				} else {
					newPOLimitDup[name] = input.target.value
				}
				
				setNewPOLimit(newPOLimitDup)
				break;
			case 'edit':
				let newEditPO = {
					edit: true,
					po_index: input,
					amount: name.amount,
				}

				let editDateRange = [dayjs(name.date_range[0])]
				if (name.hasOwnProperty('reference')) { newEditPO['reference'] = name.reference }
				if (name.date_range[1]) {
					editDateRange.push(dayjs(name.date_range[1]))
				} else {
					editDateRange.push(null)
				}
				newEditPO['date_range'] = editDateRange

				setNewPOLimit(newEditPO)
				setIndAccountPopup(false)
				setShowPOLimit(true)
				break;
			case 'submit':
				if (newPOLimit['date_range'] && newPOLimit['date_range'].length > 0) {
					if (newPOLimit['date_range'][0] === null) {
						setValidationError("Please select at least the start date.")
						return false;
					}
				} else {
					setValidationError("Please select at least the start date.")
					return false;
				}

				if (!newPOLimit.hasOwnProperty('amount') || newPOLimit.amount === '') {
					setValidationError("Please enter an amount.")
					return false;
				} else {
					if (isNaN(newPOLimit.amount) || parseInt(newPOLimit.amount) === 0 || newPOLimit.amount === '') {
						setValidationError("Please enter an amount that is a real number greater than 0.")
						return false;
					}	
				}

				setValidationError(null)
				setIsLoading(true)
				let accNavPulled = await axios.post(`/api/admin/dashboard/po_limits/${param._id}`, {
					newPOLimit: newPOLimit,
					indAccountID: indAccount._id
				})

				setShowPOLimit(false)
				setNewPOLimit({})
				setIndAccount(null)
				refreshDashboard()
				setIndSiteNav('PO Limits')
				setPopUpPage(1)
				setValidationError(null)
				indPopUpControls('open', accNavPulled.data, 'Accounts')
				setIsLoading(false)
				break;
			case 'delete':
				const confirm_del_po = window.confirm("Are you sure you would like to delete this PO Limit?")

				if (confirm_del_po) {
					setIsLoading(true)
					let accNavPulled = await axios.post(`/api/admin/dashboard/po_limits/${param._id}`, {
						newPOLimit: newPOLimit,
						indAccountID: indAccount._id,
						delete: true
					})

					setShowPOLimit(false)
					setNewPOLimit({})
					setIndAccount(null)
					refreshDashboard()
					setIndSiteNav('PO Limits')
					setPopUpPage(1)
					setValidationError(null)
					indPopUpControls('open', accNavPulled.data, 'Accounts')
					setIsLoading(false)
				}
				break;
		}
	}

	function flagPOLimits(accountPoLimits) {
	    const now = new Date();
	    return accountPoLimits.some(limit => {
	        const [startDate, endDate] = limit.date_range;
	        const amount = parseFloat(limit.amount);
	        const remaining = parseFloat(limit.remaining);
	        const usedPercentage = ((amount - remaining) / amount) * 100;

	        const isWithinDateRange = !endDate || new Date(endDate) >= now;
	        const hasUsed90PercentOrMore = usedPercentage >= 90;

	        return isWithinDateRange && hasUsed90PercentOrMore;
	    });
	}


	const handleCredits = async (control, name?, input?) => {
		switch(control) {
			case 'open':
				setIndAccountPopup(false)
				setShowCredits(true)
				break;
			case 'close':
				setShowCredits(false)
				setNewCredit({})

				setIndAccountPopup(true)
				setValidationError(null)
				break;
			case 'set':
				let newCreditDup = { ...newCredit }
				newCreditDup[name] = input.target.value
				
				setNewCredit(newCreditDup)
				break;
			case 'edit':
				let newEditCredit = {
					edit: true,
					credit_index: input,
					amount: name.amount,
					note: name.note,
				}

				setNewCredit(newEditCredit)
				setIndAccountPopup(false)
				setShowCredits(true)
				break;
			case 'submit':
				if (!newCredit.hasOwnProperty('amount') || newCredit.amount === '') {
					setValidationError("Please enter an amount.")
					return false;
				} else {
					if (isNaN(newCredit.amount) || parseInt(newCredit.amount) === 0 || newCredit.amount === '') {
						setValidationError("Please enter an amount that is a real number greater than 0.")
						return false;
					}	
				}

				setValidationError(null)
				setIsLoading(true)
				let accNavPulled = await axios.post(`/api/admin/dashboard/credits/${param._id}`, {
					newCredit: newCredit,
					indAccountID: indAccount._id
				})

				setShowCredits(false)
				setNewCredit({})
				setIndAccount(null)
				refreshDashboard()
				setIndSiteNav('Credit')
				setPopUpPage(1)
				setValidationError(null)
				indPopUpControls('open', accNavPulled.data, 'Accounts')
				setIsLoading(false)
				break;
			case 'delete':
				const confirm_del_credit = window.confirm("Are you sure you would like to delete this credit?")

				if (confirm_del_credit) {
					setIsLoading(true)
					let accNavPulled = await axios.post(`/api/admin/dashboard/credits/${param._id}`, {
						newCredit: newCredit,
						indAccountID: indAccount._id,
						delete: true
					})

					setShowCredits(false)
					setNewCredit({})
					setIndAccount(null)
					refreshDashboard()
					setIndSiteNav('Credit')
					setPopUpPage(1)
					setValidationError(null)
					indPopUpControls('open', accNavPulled.data, 'Accounts')
					setIsLoading(false)
				}
				break;
		}
	}

	const sortSites = (sites) => {
	    function parseLevel(level) {
	        const match = level.match(/^(\d+)/);
	        return match ? parseInt(match[1]) : -1;
	    }

	    function compareLocations(a, b) {
	        const fields = ["street", "city", "state", "postcode", "country"];
	        for (const field of fields) {
	            if (a.site_location[field] < b.site_location[field]) return -1;
	            if (a.site_location[field] > b.site_location[field]) return 1;
	        }

	        const levelA = a.site_location.level ? parseLevel(a.site_location.level) : -1;
	        const levelB = b.site_location.level ? parseLevel(b.site_location.level) : -1;

	        return levelA - levelB;
	    }

	    const sortedSites = sites.sort(compareLocations)
	    return sortedSites
	}

	function getRandomPastelHex() {
    	const base = 220;
    	const r = Math.floor((Math.random() * 35) + base);
    	const g = Math.floor((Math.random() * 35) + base);
    	const b = Math.floor((Math.random() * 35) + base);

    	return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
	}

	const handleDates = async (control) => {
		setIndLoading(true)
		setShowPrefItems(false)
		setAccountPrefSelected(null)
		setWeekActive(0)
		setBoxActive(0)
		setShowPrefHead(false)
		setAccountPrefHeadSelected(null)
		

		try {
			let weeksetSend;
			switch (control) {
				case 'prev':
			        let newOffsetP = weekOffset - 1;
			        setWeekOffset(newOffsetP);
			        setCurrentDateSelection(getCurrentWeekDays(newOffsetP));

			        weeksetSend = JSON.stringify(getCurrentWeekDays(newOffsetP))
					break;
				case 'next':
	        		let newOffsetN = weekOffset + 1;
	        		setWeekOffset(newOffsetN);
	        		setCurrentDateSelection(getCurrentWeekDays(newOffsetN));

	        		weeksetSend = JSON.stringify(getCurrentWeekDays(newOffsetN))
					break;
			}

			const indDataMerge = await axios.get(`/api/admin/dashboard/ind/${param._id}/SiteLazy/${indSite._id}/${weeksetSend}`, axios_config);
			let currentSiteData = {...indSite}
			currentSiteData['orders'] = indDataMerge.data

			setIndSite(currentSiteData)
			setIndLoading(false)
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const handleChristmasClosures = async (control, input) => {
		switch (control) {
			case 'open':
				setShowChristmasClosures(true)
				break;
			case 'close':
				setShowChristmasClosures(false)
				setChristmasClosuresData([])
				break;
			case 'set':
				setChristmasClosuresData(input.value) 
				break;
			case 'submit':
				if ((christmasClosuresData.length !== 2) || (christmasClosuresData.includes(null))) {
					setValidationError("Please select both the start and the end date.")
					return false;
				}

				setIsLoading(true)
				let siteNavPulledChristmasClosures = await axios.post(`/api/admin/dashboard/christmas_closures/${param._id}`, {
					christmasClosuresData: christmasClosuresData,
					indSiteId: indSite._id
				})

				setShowChristmasClosures(false);
				setChristmasClosuresData([])
				setIndSite(null)
				refreshDashboard()
				setIndSiteNav('Details')
				indPopUpControls('open', siteNavPulledChristmasClosures.data, 'Sites')
				setIsLoading(false)
				break;
		}
	}

	return (
		<div key={key}>	
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Dashboard"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<DashboardPage {...{ param, allAccounts, allSites, allOrders, allPreferences, navType, changeNavType, formatDate, nextDeliveryDate, popUpControls, accountPopup, sitePopup, orderPopup, currentAccount, currentSite, preferencesPopup, indPopUpControls, indAccountPopup, indSitePopup, indOrderPopup, indPreferencePopup, enquiryPopup, allEnquiries, interactionPopup, allInteractions, indEnquiryPopup, indInteractionPopup, searchControl, emailChange, showInteractionHelp, getTotalQuantity, isUserBirthday, accountData, showFlagged, flaggedAvailable, handleFlagged, showPOLimit, flaggedPO, showFlaggedPO, showCredits, axios_config, setAllAccounts, setFlaggedPOAccounts, flaggedPOAccounts, setFlaggedPO, setAllSites, setAllOrders, setAllOrdersOG, setAllPreferences, handleSearch, searchLoading, searchResults, searchOpen, setSearchOpen, setSearchLoading, setSearchResults, searchText, setSearchText }} />

								{accountPopup && <PopUpAccount {...{ param, popUpPage, popUpControls, validationError, newAccountData, handleNewAccountData, accountContacts, handleNewAccountContacts, handleNewAccountAddress, newAccountAddress }} />}
								{sitePopup && <PopUpSite {...{ param, popUpPage, popUpControls, newSiteData, handleNewSiteData, handleNewSiteAddress, newSiteAddress, validationError, formatOptions, inventoryData, indLoading }} />}
								{orderPopup && <PopUpOrder {...{ param, orderTypeNav, formatOptions, popUpPage, popUpControls, newOrderData, handleNewOrderData, orderCategory, handleMainOrder, mainOrder, handleMainObj, changeOrderCategory, inventoryData, mainObj, validationError, deliveryDayOptions, filterDates, accountSites, menuData, flexiOptions, isAlternatingPage, isSubmitPage, currentBoxLoop, flexiMainOrder, flexiMainObj, boxLoops, handleBoxLoops, seasonalExtraItems, isSuperAdmin, bananaWarning, adjustToPreviousDayNoonAEST, overdueWarning, indLoading }} />}
								{preferencesPopup && <PopUpPreferences {...{ param, orderTypeNav, formatOptions, popUpPage, popUpControls, newOrderData, handleNewOrderData, orderCategory, handleMainOrder, mainOrder, handleMainObj, changeOrderCategory, inventoryData, mainObj, validationError, deliveryDayOptions, filterDates, accountSites, menuData, flexiOptions, preferenceExisting, indPopUpControls, isAlternatingPagePref, currentBoxLoop, flexiMainOrder, flexiMainObj, isSubmitPagePref, boxLoops, handleBoxLoops, seasonalExtraItems, handleEditPrefCombined, bananaWarning, indLoading }} />}
								{enquiryPopup && <PopUpEnquiry {...{ param, popUpPage, popUpControls, validationError, formatOptions, handleNewEnquiryData, handleNewEnquiryAddress, newEnquiryAddress, newEnquiryData }} />}
								{interactionPopup && <PopUpInteraction {...{ param, popUpPage, popUpControls, validationError, formatOptions, handleNewInteractionData, newInteractionData, sitesAvailable, formatDate, emailFile, handleInteractionHelp, indLoading }} />}

								{(indAccountPopup && !showSiteGroups && !showPOLimit && !showCredits) && <PopUpIndAccount {...{ param, indPopUpControls, indAccount, changeIndSiteNav, indSiteNav, changeStatus, changingStatus, searchControl, formatDate, handleSiteGroups, accountPrefSelected, showPrefItems, handleShowPrefItems, weekActive, boxActive, inventoryData, getTotalQuantity, sortSIorders, handlePOLimit, sortSIPrefs, flagPOLimits, isSuperAdmin, handleCredits, sortPrefsIntoDays, showPrefHead, accountPrefHeadSelected, sortSites, getRandomPastelHex, inactiveDatePopup, inactiveDate, indLoading }} />}
								{emailChange && <PopUpEmailChange {...{ param, validationError, popUpControls, newAccountEmail, indPopUpControls }} />}
								{(indSitePopup && !showAllergies && !showCustomPricing && !showChristmasClosures) && <PopUpIndSite {...{ param, indPopUpControls, indSite, changeIndSiteNav, indSiteNav, formatDate, allInteractions, changeStatus, changingStatus, searchControl, inventoryData, handleAllergies, handleCustomPricing, allTiers, handleShowPrefItems, showPrefItems, accountPrefSelected, weekActive, boxActive, sortSIorders, getTotalQuantity, sortSIPrefs, inactiveDatePopup, inactiveDate, indLoading, currentDateSelection, weekOffset, handleDates, handleChristmasClosures }} />}
								{indOrderPopup && <PopUpIndOrder {...{ param, indPopUpControls, indOrder, convertToDay, formatDate, inventoryData, changeIndSiteNav, indSiteNav, changeStatus, changingStatus, searchControl, boxActive, boxLoops, adjustToPreviousDayNoonAEST, indLoading }} />}
								{(indPreferencePopup && !showTempCancel && !showClone) && <PopUpIndPreference {...{ param, indPopUpControls, indPreference, nextDeliveryDate, formatDate, inventoryData, changeIndSiteNav, indSiteNav, changeStatus, changingStatus, searchControl, boxActive, handleTempCancel, handleClone, weekActive, getTotalQuantity, inactiveDatePopup, inactiveDate, indLoading, prefItemKeys }} />}
								{indInteractionPopup && <PopUpIndInteraction {...{ param, indPopUpControls, indInteraction, changeIndSiteNav, indSiteNav, formatDate, handleOpenEmail, emailBody, searchControl, indLoading }} />}
								{indEnquiryPopup && <PopUpIndEnquiry {...{ param, indPopUpControls, indEnquiry, changeIndSiteNav, indSiteNav, formatDate, indLoading }} />}
								
								{emailBody && <PopUpEmail {...{ param, emailBody, DisplayHTMLComponent, popUpControls, formatDate, handleOpenEmail }} />}
								{showInteractionHelp && <PopUpInteractionHelp {...{ param, handleInteractionHelp, interactionNav }} />}
								{showTempCancel && <PopUpTempCancel {...{ param, handleTempCancel, validationError, tempCancelData }} />}
								{showAllergies && <PopUpAllergies {...{ param, handleAllergies, allergiesData, inventoryData, formatOptions }} />}
								{showSiteGroups && <PopUpSiteGroups {...{ param, handleSiteGroups, siteGroupsData, formatOptions, validationError, indAccount }} />}
								{showClone && <PopUpClone {...{ param, handleClone, validationError, newCloneData, formatOptions, indLoading }} />}
								{showCustomPricing && <PopUpCustomPricing {...{ param, handleCustomPricing, tierNav, siteFruitTiers, siteProcalTiers, inventoryData, formatOptions, validationError, tierControls }} />}
								{showFlagged && <PopUpFlagged {...{ param, searchControl, menuData, flagNav, handleFlagged, flaggedOrders, flaggedPreferences, sortFlaggedOrders, handleShowPrefItems, accountPrefSelected, formatDate, getTotalQuantity, showPrefItems, indPopUpControls, weekActive, boxActive, inventoryData }} />}
								{showPOLimit && <PopUpPOLimit {...{ param, handlePOLimit, validationError, newPOLimit }} />}
								{showFlaggedPO && <PopUpFlaggedPO {...{ param, handleFlagged, flaggedPOAccounts, searchControl, indPopUpControls }} />}
								{showCredits && <PopUpCredit {...{ param, handleCredits, validationError, newCredit }} />}
								{showChristmasClosures && <PopUpChristmasClosures {...{ param, popUpControls, validationError, formatOptions, formatDate, christmasClosuresData, handleChristmasClosures }} />}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Dashboard"} />
			}
		</div>
	);
};


export default Dashboard;