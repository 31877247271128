import { useEffect, useState, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import 'animate.css';

import NavigationBar from '../Utils/NavigationBar'
import Forbidden from '../Forbidden';
import Loading from '../Loading';
import ComingSoon from '../ComingSoon';
import ofglogo from '../../../images/logo.png'
import styles from "./Profile.module.css"


const ProfilePage = ({ param, changeNavType, navType, search_control, accountData, formatDate, isUserBirthday, counts, handleProfilePicture, internalLoading, handleLogout }) => {
	return (
		<div className={`${styles.page_container}`}>
			<div className={`${styles.sites_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>

						<button onClick={() => handleLogout()}>
							<i className="fa-solid fa-right-from-bracket"></i>
							<p className={styles.smaller_font}>Logout</p>
						</button>
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType == 'Profile' && styles.sitenav_active}`} onClick={() => changeNavType('Profile')}><p>Profile</p></button>
					</div>

				</div>

				<div className={`${styles.profile_container}`}>
					<div className={styles.profile_column_one}>
						<label for="profile_pic" disabled={internalLoading} className={styles.profile_column_container}>
							{internalLoading
								?
									<div className={styles.load}>
										<span></span>
										<span></span>
										<span></span>
										<span></span>
									</div>
								:
									<>
										{accountData['profile_img']
											?
												<div className={styles.profile_img_container}>
													<img src={accountData['profile_img']}/>
												</div>
											:
												<>
													<i className="fa-solid fa-camera-retro"></i>
													<input type="file" id="profile_pic" style={{display: "none"}} onChange={(input) => handleProfilePicture('set', input)}/>
												</>
										}
									</>
							}
						</label>
						<input type="file" id="profile_pic" style={{display: "none"}} onChange={(input) => handleProfilePicture('set', input)}/>
					</div>

					<div className={styles.profile_column_two}>
						<div className={styles.profile_info}>
							<h1>{`${accountData.contacts[0].first_name} ${accountData.contacts[0].last_name}`}</h1>
							<p className={styles.small_margin}>{accountData.email}</p>
							<p>{formatDate(accountData.dob)}</p>

							<div className={styles.user_permissions}>
								{accountData.permissions && accountData.permissions.map((AP, key) => {
								    const dynamicClassName = `${styles.user_status} ${styles['status_' + AP.replace(' ', '')]}`;

								    return (
								        <div className={dynamicClassName} key={key}>
								            <p className={styles.smaller_font}>{AP}</p>
								        </div>
								    );
								})}
							</div>
						</div>

						<div className={styles.countContainer}>
							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Orders Created<span>{counts.orders}/100</span></label>
							    <div className={styles.ordersBar}>
							      <div className={styles.ordersProgress} style={{ width: `${Math.max((counts.orders / 100) * 100)}%` }}></div>
							    </div>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Preferences Created<span>{counts.preferences}/100</span></label>
						    	<div className={styles.prefsBar}>
							      <div className={styles.prefsProgress} style={{ width: `${Math.max((counts.preferences / 100) * 100)}%` }}></div>
							    </div>
							</div>

							<div className={styles.popup_formbox}>
								<label className={styles.smaller_font}>Interactions Created<span>{counts.interactions}/100</span></label>
							    <div className={styles.intersBar}>
							      <div className={styles.intersProgress} style={{ width: `${Math.max((counts.interactions / 100) * 100)}%` }}></div>
							    </div>
							</div>
						</div>
					</div>


					{/* <div className={`${styles.profile_row} ${styles.green_row}`}></div> */}
					{/* <div className={`${styles.profile_row} ${styles.blue_row}`}></div> */}
					{/* <div className={`${styles.profile_row} ${styles.red_row}`}></div> */}
				</div>
			</div>
		</div>
	)
}


const Profile = () => {
	const handleLogout = () => {
		const confirmLogout = window.confirm("Are you sure you would like to logout?")

		if (confirmLogout) {
			localStorage.removeItem("token");
			localStorage.removeItem("role_token");
			localStorage.removeItem("session_token");
			window.location = "/login";
		}
	};

	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [counts, setCounts] = useState({});
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [navType, setNavType] = useState('Profile');
 	const [indNav, setIndNav] = useState("Details");
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);
 	

	const param = useParams();
	const url = `/api/admin/profile/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const session_token = localStorage.getItem('session_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`,
	    'x-session-token': session_token
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(url, axios_config);
					setAccountData(data.data.account_data);
					setCounts(data.data.counts)

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
					if (e.response && e.response.data && e.response.data.logout) {
						handleLogout()
					}
				}

				setIsLoading(false)
			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url]);


	const formatDate = (dateString, showDay) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    dateObj.setHours(dateObj.getHours() + 10);

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};
	
	const search_control = (event) => {
		var input, filter, table, tr, td, i, txtValue;
		input = document.getElementById("searchbar");
		filter = input.value.toUpperCase();
		table = document.getElementById("table_main");
		tr = table.getElementsByTagName("tr");

		for (i = 0; i < tr.length; i++) {
			td = tr[i].getElementsByTagName("td")[0];

			if (td) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					tr[i].style.display = "";
				} else {
					tr[i].style.display = "none";
				}
			}
		}
	}

	const changeNavType = (type) => {
		setNavType(type)
	}

	const changeIndNav = async (type) => {
		setIndNav(type)
	}

	function isUserBirthday(dob) {
		const currentDate = new Date();
		const userBirthday = new Date(dob);
		return (
			currentDate.getDate() === userBirthday.getDate() &&
			currentDate.getMonth() === userBirthday.getMonth()
		);
	}

	const handleProfilePicture = async (control, ind) => {
		switch (control) {
			case 'set':
				setInternalLoading(true)
				if (ind.target.files[0]) {
				    const formData = new FormData();
				    formData.append('image', ind.target.files[0]);

					const newAccount = await axios.post(`/api/admin/profile/${param._id}/upload`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					});

					let accountDataDup = {...accountData}
					accountDataDup['profile_img'] = newAccount.data

					setAccountData(accountDataDup)
				}
				setInternalLoading(false)
				break;
		}
	}

	return (
		<>
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Profile"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<ProfilePage {...{ param, changeNavType, navType, search_control, accountData, formatDate, isUserBirthday, counts, handleProfilePicture, internalLoading, handleLogout }} />
								{/* <ComingSoon /> */}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Profile"} />
			}
		</>
	);
};


export default Profile;