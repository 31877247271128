import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";
import axios from "axios";
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import 'animate.css';
import dayjs from 'dayjs'
import pako from 'pako';

import Forbidden from '../Forbidden';
import Loading from '../Loading';
import styles from "./Warehouse.module.css"

import NavigationBar from '../Utils/NavigationBar'
import PopUpInventory from './PopUpInventory';
import PopUpBulk from './PopUpBulk';
import PopUpSuppliers from './PopUpSuppliers';
import PopUpMenu from './PopUpMenu';
import PopUpDelivery from './PopUpDeliveryNew';
import PopUpIndInventory from './PopUpIndInventory';
import PopUpIndSuppliers from './PopUpIndSuppliers';
import PopUpIndDelivery from './PopUpIndDelivery';
import PopUpRunSheet from './PopUpRunSheet';
import PopUpOverflow from './PopUpOverflow';
import PopUpPurchasing from './PopUpPurchasing';
import PopUpIndPurchasing from './PopUpIndPurchasing';
import PopUpDeliveryRecords from './PopUpDeliveryRecords';
import PopUpChristmasClosures from './PopUpChristmasClosures';

import initializeMapWithAccessToken from '../../../utils/mapUtils';


const WarehousePage = ({ param, navType, changeNavType, allInventory, popUpControls, inventoryPopup, suppliersPopup, menuPopup, popUpPage, validationError, formatOptions, handleMenuItem, menuItemArr, handleMenuArr, onDragEndItems, handleSeasonalItem, menuSeasonalItem, allSuppliers, seasonalOptions, allMenu, deliveryPopup, changeDeliveryNav, deliveryNav, handleNewDeliveryData, newDeliveryData, runOptions, handleRunArr, runArr, handleRunItem, onDragEndRun, allSites, allDelivery, formatDate, nextDeliveryDate, indPopUpControls, indDeliveryPopup, indInventoryPopup, indSuppliersPopup, bulkPopup, handleBulk, bulkProducts, overflowPopup, purchasingPopup, allPurchasing, indPurchasingPopup, searchControl, checkQueueLength, changeBulkCategory, bulkCategory, deliveryRecordsPopup, runOptionsOG, sortDeliveries, setAllInventory, axios_config, setAllSuppliers, indLoading, allInvBulk, christmasClosuresPopup, fetchDeliveriesWithQueueLength, handleLogout, searchDeliveryControl, deliveryGroup, setDeliveryGroup }) => {
	const [deliveriesWithQueueLength, setDeliveriesWithQueueLength] = useState(allDelivery || []);
	const [tableLoading, setTableLoading] = useState(false);
	const [finishingNext, setFinishingNext] = useState(false);
	const [prevScrollTop, setPrevScrollTop] = useState(0);
	const searchContainerRef = useRef(null);
	const lastRowRef = useRef(null);
	const tbodyRef = useRef(null);
	let isLast;

	// Load next 50 when end of results
  	useEffect(() => {
  		const tableLoad = async () => {
			const navMap = {
			  Inventory: allInventory,
			  Suppliers: allSuppliers
			};

			let accountToUse = navMap[navType] || allInventory;

	    	if (lastRowRef.current && tbodyRef.current) {
	      		const observer = new IntersectionObserver((entries) => {
	          		entries.forEach(entry => {
	            		if (entry.isIntersecting) {
	            			const fetchData = async () => {
	            				try {
		            				setTableLoading(true)
		            				const previousScrollTop = tbodyRef.current.scrollTop

		            				const response = await axios.get(`/api/admin/warehouse/main/${param._id}/${navType}/${false}/${accountToUse.length}`, axios_config);
		            				if (response.data.finishingNext) {
		            					setFinishingNext(true)
		            				}

		            				switch (navType) {
			            				case 'Inventory':
				            				setAllInventory(prevInventory => [...prevInventory, ...response.data.allInventory]);
			            					break;
			            				case 'Suppliers':
				            				setAllSuppliers(allSuppliers => [...allSuppliers, ...response.data.allSuppliers]);
			            					break;
		            				}

									setTableLoading(false)
						            setTimeout(() => {
						              	tbodyRef.current.scrollTop = previousScrollTop;
						            }, 0);
	            				} catch (e) {
									if (e.response && e.response.data && e.response.data.logout) {
										handleLogout()
									}
	            				}
	            			}
	            			
	            			if (!finishingNext) {
	            				fetchData();
	            			}
	            		}
	          		});
	        	}, { threshold: 1.0 });

	      		observer.observe(lastRowRef.current);
	      		return () => observer.disconnect();
	    	}
  		}

  		if (navType !== "Menu" && navType !== "Delivery" && navType !== "Purchasing") {
  			tableLoad()
  		}
  	}, [lastRowRef.current, navType, allInventory, allSuppliers]);


    useEffect(() => {
        if (navType === 'Delivery' && allDelivery && allDelivery.length > 0) {
            const fetchQueueLengths = async () => {
                const sortedDeliveries = sortDeliveries(allDelivery);
                const deliveriesWithQueue = await Promise.all(
                    sortedDeliveries.map(async (delivery) => {
                        const queueLength = await checkQueueLength(delivery.run_day);
                        return { ...delivery, queueLength };
                    })
                );
                setDeliveriesWithQueueLength(deliveriesWithQueue);
            };

            fetchQueueLengths();
        }
    }, [allDelivery, navType]);

	return (
		<div className={`${styles.page_container} ${(inventoryPopup || suppliersPopup || indDeliveryPopup || indInventoryPopup || indSuppliersPopup || overflowPopup || purchasingPopup || indPurchasingPopup || deliveryRecordsPopup || christmasClosuresPopup) && styles.hidden_container} ${((menuPopup || deliveryPopup || bulkPopup) && styles.menu_page_container)}`}>
			<div className={`${styles.sites_container} ${(menuPopup || deliveryPopup || bulkPopup) && styles.hidden_container}`}>
				<div className={styles.sitepage_container}>
					<div className={styles.page_heading}>
						<h1>{navType}</h1>
						{navType === 'Inventory' &&
							<div className={styles.dual_buttons}>
								<button onClick={() => popUpControls('bulk')}>
									<i className="fa-solid fa-file-pen"></i>
									<p className={styles.smaller_font}>Bulk update</p>
								</button>

								<button onClick={() => popUpControls('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add product</p>
								</button>
							</div>
						}
						{navType === 'Suppliers' &&
							<button onClick={() => popUpControls('open')}>
								<i className="fa-solid fa-plus"></i>
								<p className={styles.smaller_font}>Add supplier</p>
							</button>
						}
						{navType === 'Menu' &&
							<button onClick={() => popUpControls('open')}>
								<i class="fa-solid fa-pen"></i>
								<p className={styles.smaller_font}>Manage menu</p>
							</button>
						}
						{navType === 'Delivery' &&
							<div className={styles.dual_buttons}>
								<button onClick={() => popUpControls('christmas_closures')}>
									<i className="fa-solid fa-xmark"></i>
									<p className={styles.smaller_font}>Christmas Closures</p>
								</button>

								<button onClick={() => popUpControls('open')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add delivery run</p>
								</button>
							</div>
						}
						{navType === 'Purchasing' &&
							<div className={styles.dual_buttons}>
								<button onClick={() => popUpControls('overflow')}>
									<i className="fa-solid fa-percent"></i>
									<p className={styles.smaller_font}>Manage overflow</p>
								</button>

								<button onClick={() => popUpControls('purchasing')}>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add purchase list</p>
								</button>
							</div>
						}
					</div>

					<div className={styles.sitenav}>
						<button className={`${styles.sitenav_box} ${navType === 'Inventory' && styles.sitenav_active}`} onClick={() => changeNavType('Inventory')}><p>Inventory</p></button>
						<button className={`${styles.sitenav_box} ${navType === 'Suppliers' && styles.sitenav_active}`} onClick={() => changeNavType('Suppliers')}><p>Suppliers</p></button>
						<button className={`${styles.sitenav_box} ${navType === 'Menu' && styles.sitenav_active}`} onClick={() => changeNavType('Menu')}><p>Menu</p></button>
						<button className={`${styles.sitenav_box} ${navType === 'Delivery' && styles.sitenav_active}`} onClick={() => changeNavType('Delivery')}><p>Delivery</p></button>
						<button className={`${styles.sitenav_box} ${navType === 'Purchasing' && styles.sitenav_active}`} onClick={() => changeNavType('Purchasing')}><p>Purchasing</p></button>
					</div>
				</div>

				<div className={styles.sites_tablecontainer}>
					<div className={styles.sites_controls}>
						<div className={styles.admin_searchcontainer}>
							<div className={styles.sites_search}>
								<i className="fa-solid fa-magnifying-glass"></i>
								<input
								    type="text"
								    placeholder="Search for any of the column headings..."
								    onKeyUp={(event) => searchControl(event, "main_table")}
								/>
							</div>
						</div>
					</div>

					<div className={styles.table_container}>
						<table className={styles.sites_table} id="main_table">						    
						    {navType === "Inventory" &&
						    	<>
							    	<thead>
							      		<tr className={styles.table_rowth}>
							      			<th>Item code</th>
							      			<th>Item name</th>
							        		<th>Category</th>
							        		<th>Sub-category</th>
							        		<th>Purchase price</th>
							        		<th>Sale price</th>
							      		</tr>
							    	</thead>

						    		<tbody ref={tbodyRef}>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
										    		{(allInventory && allInventory.length > 0) &&
										    			<>
											    			{allInventory.map((AI, key) => {
											    				isLast = key === allInventory.length - 1;

											    				return (
											    					<tr onClick={() => indPopUpControls('open', AI)} key={key} ref={isLast ? lastRowRef : null}>
												    					<td>{AI.item_code}</td>
												    					<td>{AI.item_name}</td>
												    					<td>{AI.category}</td>
												    					<td>{AI.subcategory}</td>
												    					<td>${AI.purchase_price}</td>
												    					<td>${AI.sale_price}</td>
												    				</tr>
											    				)
															})}
														</>
													}
												</>
										}
									</tbody>
								</>
							}

						    {navType === "Suppliers" &&
						    	<>
							    	<thead>
							      		<tr className={styles.table_rowth}>
							      			<th>Supplier code</th>
							      			<th>Supplier name</th>
							      			<th>Service</th>
							        		<th>Ordering email</th>
							        		<th>Contact name</th>
							        		<th>Account reference</th>
							      		</tr>
							    	</thead>

						    		<tbody ref={tbodyRef}>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
										    		{(allSuppliers && allSuppliers.length > 0) &&
										    			<>
											    			{allSuppliers.map((AS, key) => {
											    				isLast = key === allSuppliers.length - 1;

											    				return (
											    					<tr onClick={() => indPopUpControls('open', AS)} key={key} ref={isLast ? lastRowRef : null}>
												    					<td>{AS.supplier_code}</td>
												    					<td>{AS.supplier_name}</td>
												    					<td>{AS.service}</td>
												    					<td>{AS.ordering_email || '-'}</td>
												    					<td>{AS.contact[0] && AS.contact[0].name ? AS.contact[0].name : '-'}</td>
												    					<td>{AS.account_reference || '-'}</td>
												    				</tr>
											    				)
															})}
														</>
													}
												</>
										}
									</tbody>
								</>
							}

						    {navType === "Menu" &&
						    	<>
							    	<thead>
								      	<tr className={styles.table_rowth}>
									      	<th>Order</th>
									      	<th>Item code</th>
									      	<th>Item name</th>
									        <th>Percentage</th>
									        <th>Rounds</th>
									        <th>Item status</th>
								      	</tr>
							    	</thead>

						    		<tbody>
										{tableLoading
											?
												<div className={styles.loadpage_container}>
													<div className={styles.load}>
														<span></span>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
											:
												<>
													{(allMenu.items && allMenu.items.length > 0) &&
														<>
										    				<tr>
												    		{/* <tr onClick={() => indPopUpControls('navigate', allInventory.filter(AI => AI.item_name === allMenu.seasonal)[0])}> */}
													    		<td>Seasonal</td>
													    		<td>{allMenu.seasonal_code}</td>
													    		<td>{allMenu.seasonal}</td>
													    		<td>{100 - allMenu.items.reduce((acc, item) => acc + parseFloat(item.percentage), 0)}%</td>
													    		<td>-</td>

													    		<td className={styles.site_statustd}>
													    			<div className={`${styles.site_status} ${(allMenu.seasonal_active) ? styles.status_active : styles.status_inactive}`}>
													    				<p className={styles.smaller_font}>{allMenu.seasonal_active ? "Active" : "Inactive"}</p>
													    			</div>
													    		</td>
													    	</tr>

													    	{allMenu.items.map((MI, key) => {
														    	return (
														    		<tr>
														    		{/* <tr onClick={() => indPopUpControls('navigate', allInventory.filter(AI => AI.item_name === MI.product)[0])} key={key}> */}
															    		<td>{MI.id+1}</td>
															    		<td>{MI.code}</td>
															    		<td>{MI.product}</td>
															    		<td>{MI.percentage}%</td>
															    		<td>{MI.round}</td>
															    		<td className={styles.site_statustd}>
															    			<div className={`${styles.site_status} ${(MI.active) ? styles.status_active : styles.status_inactive}`}>
															    				<p className={styles.smaller_font}>{MI.active ? "Active" : "Inactive"}</p>
															    			</div>
															    		</td>
															    	</tr>
														    	)
															})}
														</>
										    		}
										    	</>
										}
									</tbody>
								</>
							}

						    {navType === "Delivery" &&
						    	<>
							    	<thead>
							      		<tr className={styles.table_rowth}>
							      			<th>Day</th>
							      			<th>Number</th>
							      			<th>No. deliveries</th>
							      			<th>Queue</th>
							      			<th>Next run</th>
							      			<th>Date created</th>
							      		</tr>
							    	</thead>

						    		<tbody>
						    			{deliveriesWithQueueLength.map((AD, key) => {
						    				return (
									    		<tr onClick={() => indPopUpControls('open', AD)} key={key}>
									    			<td>{AD.run_day}</td>
									    			<td>Run {AD.run_number}</td>
									    			<td>{AD.items.length} deliveries</td>
									    			<td>{AD.queueLength !== undefined ? `${AD.queueLength} deliveries` : "Calculating..."}</td>
									    			<td>{formatDate(nextDeliveryDate(AD.run_day), true)}</td>
									    			<td>{formatDate(AD.date_created, false)}</td>
										    	</tr>
						    				)
										})}
									</tbody>
								</>
							}

						    {navType === "Purchasing" &&
						    	<>
							    	<thead>
								      	<tr className={styles.table_rowth}>
									      	<th>Type</th>
									      	<th>Delivery date</th>
									      	<th>No. items</th>
									      	<th>Estimated cost</th>
									      	<th>Export</th>
								      	</tr>
							    	</thead>

						    		<tbody>
							    		{(allPurchasing && allPurchasing.length > 0) &&
							    			<>
								    			{allPurchasing.map((AP, key) => {
								    				return (
											    		<tr onClick={() => indPopUpControls('open', AP)} key={key}>
											    			<td>{AP.type === "date" ? "Specific Date" : "Date Range"}</td>
											    			<td>{AP.delivery_date ? formatDate(AP.delivery_date, true) : `${formatDate(AP.delivery_date_range[0])} - ${formatDate(AP.delivery_date_range[1])}`}</td>
											    			<td>{AP.items.length} items</td>
											    			<td>${AP.estimated_cost.toFixed(2)}</td>
											    			<td>
											    				<div className={styles.table_button}>
																	<button onClick={() => indPopUpControls('excel', AP)}>
																		<i className="fa-solid fa-file-export"></i>
																		<p className={styles.smaller_font}>Excel spreadsheet</p>
																	</button>
																</div>
											    			</td>
												    	</tr>
								    				)
												})}
											</>
										}
									</tbody>
								</>
							}
						</table>
					</div>
				</div>
			</div>

			{bulkPopup && <PopUpBulk {...{ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleBulk, bulkProducts, changeBulkCategory, bulkCategory, indLoading, allInvBulk }} />}
			{menuPopup && <PopUpMenu {...{ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleMenuItem, menuItemArr, handleMenuArr, onDragEndItems, handleSeasonalItem, menuSeasonalItem, seasonalOptions }} />}
			{deliveryPopup && <PopUpDelivery {...{ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleMenuItem, menuItemArr, handleMenuArr, onDragEndRun, handleSeasonalItem, menuSeasonalItem, seasonalOptions, changeDeliveryNav, deliveryNav, handleNewDeliveryData, newDeliveryData, runOptions, handleRunArr, runArr, handleRunItem, allSites, runOptionsOG, indLoading, searchDeliveryControl, deliveryGroup, setDeliveryGroup }} />}
		</div>
	)
}


const Warehouse = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("role_token");
		localStorage.removeItem("session_token");
		window.location = "/login";
	};

	const [isLoading, setIsLoading] = useState(true);
	const [internalLoading, setInternalLoading] = useState(false);
	const [accountData, setAccountData] = useState({});
	const [allInventory, setAllInventory] = useState(null);
	const [allSuppliers, setAllSuppliers] = useState(null);
	const [allMenu, setAllMenu] = useState(null);
	const [allDelivery, setAllDelivery] = useState(null);
	const [allPreferences, setAllPreferences] = useState(null);
	const [allOrders, setAllOrders] = useState(null);
	const [allSites, setAllSites] = useState(null);
	const [allOverflow, setAllOverflow] = useState(null);
	const [allPurchasing, setAllPurchasing] = useState(null);
	const [allLogs, setAllLogs] = useState(null);
	const [allFleet, setAllFleet] = useState(null)
	const [changingStatus, setChangingStatus] = useState(false)
	const [key, setKey] = useState(0);
	const [collapseDeg, setCollapseDeg] = useState(0)
	const [collapseFilters, setCollapseFilters] = useState(true)
	const [allInvBulk, setAllInvBulk] = useState([])

	const [isAuthenticated, setIsAuthenticated] = useState(false);
 	const isSuperAdmin = accountData && accountData.role && accountData.role.name === 'SuperAdmin';
 	const isAdmin = accountData && accountData.role && accountData.role.name === 'Admin';
 	const isAccount = accountData && accountData.role && accountData.role.name === 'Account';

 	const [navType, setNavType] = useState('Inventory');
 	const [popUpPage, setPopUpPage] = useState(1);
 	const [validationError, setValidationError] = useState(null);
 	const [indNav, setIndNav] = useState("Details");
 	const [indLoading, setIndLoading] = useState(false)

 	// inventory
 	const [inventoryPopup, setInventoryPopup] = useState(false);
 	const [newInventoryData, setNewInventoryData] = useState({});
 	const [subCatOptions, setSubCatOptions] = useState(null);
 	const [indInventoryPopup, setIndInventoryPopup] = useState(false);
 	const [indInventory, setIndInventory] = useState(null);
 	const [bulkPopup, setBulkPopup] = useState(false);
 	const [bulkProducts, setBulkProducts] = useState({})
 	const [itemExisting, setItemExisting] = useState(null)
 	const [bulkCategory, setBulkCategory] = useState('Fruit');

 	// suppliers
 	const [suppliersPopup, setSuppliersPopup] = useState(false);
 	const [newSuppliersData, setNewSuppliersData] = useState({});
 	const [newSuppliersContact, setNewSuppliersContact] = useState({});
 	const [newSuppliersSecondaryContact, setNewSuppliersSecondaryContact] = useState({});
 	const [indSuppliersPopup, setIndSuppliersPopup] = useState(false);
 	const [indSuppliers, setIndSuppliers] = useState(null);

 	// menu
 	const [menuPopup, setMenuPopup] = useState(false);
 	const [seasonalOptions, setSeasonalOptions] = useState([])
 	const [menuItemArr, setMenuItemArr] = useState([])
 	const [menuSeasonalItem, setMenuSeasonalItem] = useState(null)
 	
 	// delivery
 	const [deliveryPopup, setDeliveryPopup] = useState(false);
 	const [deliveryNav, setDeliveryNav] = useState("Run");
 	const [deliveryGroup, setDeliveryGroup] = useState(null)
 	const [newDeliveryData, setNewDeliveryData] = useState({});
 	const [runOptions, setRunOptions] = useState({})
 	const [runOptionsOG, setRunOptionsOG] = useState({})
 	const [runArr, setRunArr] = useState([])
 	const [indDeliveryPopup, setIndDeliveryPopup] = useState(false);
 	const [indDelivery, setIndDelivery] = useState(null);
 	const [runPopup, setRunPopup] = useState(false);
 	const [runDetails, setRunDetails] = useState({})
 	const [mapLoading, setMapLoading] = useState(false);
 	const [boxModelShowing, setBoxModelShowing] = useState("1")
 	const [showIndModel, setShowIndModel] = useState(false)
 	const [deliveryRecordsPopup, setDeliveryRecordsPopup] = useState(false)
 	const [selectedRecordDate, setSelectedRecordDate] = useState(null)
 	const [christmasClosuresPopup, setChristmasClosuresPopup] = useState(false)
 	const [christmasClosuresDataOG, setChristmasClosuresDataOG] = useState([])
 	const [christmasClosuresData, setChristmasClosuresData] = useState([])

 	// purchasing
 	const [overflowPopup, setOverflowPopup] = useState(false)
 	const [overflowData, setOverflowData] = useState({})
 	const [purchasingPopup, setPurchasingPopup] = useState(false)
 	const [purchasingData, setPurchasingData] = useState({})
 	const [indPurchasingPopup, setIndPurchasingPopup] = useState(false);
 	const [indPurchasing, setIndPurchasing] = useState(null);


	const param = useParams();
	const url = `/api/admin/warehouse/main/${param._id}`;

	const role_token = localStorage.getItem('role_token');
	const session_token = localStorage.getItem('session_token');
	const axios_config = {
	  headers: {
	    Authorization: `Bearer ${role_token}`,
	   	'x-session-token': session_token
	  }
	};

	useEffect(() => {
		setIsLoading(true)

		const initLoad = async () => {
			try {
				try {
					const data = await axios.get(`${url}/${navType}/${true}`, axios_config);
					setAccountData(data.data);
					setAllInventory(data.data.allInventory)
					setAllSuppliers(data.data.allSuppliers)

					setAllMenu({
						seasonal: data.data.menuSeasonalItem,
						seasonal_code: data.data.menuSeasonalItemCode,
						seasonal_active: data.data.menuSeasonalItemActive,
						items: data.data.menuItems
					})
					setSeasonalOptions(data.data.seasonalOptionsMapped)
					setAllDelivery(data.data.allDelivery)
					// setAllPreferences(data.data.allPreferences)
					// setAllOrders(data.data.allOrders)
					// setAllSites(data.data.allSites)
					// setAllOverflow(data.data.allOverflow)
					setAllPurchasing(data.data.allPurchasing)
					setAllFleet(data.data.allFleet)
					// setAllLogs(data.data.allLogs)

					if (data.data.christmasClosure) {
						let editDateRange = [dayjs(data.data.christmasClosure.dates[0]), dayjs(data.data.christmasClosure.dates[1])]
						setChristmasClosuresData(editDateRange)
						setChristmasClosuresDataOG(editDateRange)
					}

					setIsAuthenticated(true)
				} catch(e) {
					setIsAuthenticated(false);
					if (e.response && e.response.data && e.response.data.logout) {
						handleLogout()
					}
				}

				setIsLoading(false)

			} catch (error) {
				setAccountData(error);
			}
		};
		initLoad();
	}, [param, url, key]);


	const refreshDashboard = () => {
		setKey((prevKey) => prevKey + 1);
	};

	const searchControl = (event, tableId) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById(tableId).children[1].children;

	    for (let i = 0; i < target_div.length; i++) {
	        let txtValues = [];
	        for (let j = 0; j < target_div[i].children.length; j++) {
                if (target_div[i].children[j].className.includes('status')) {
                    // handle status
                    let txtValue = target_div[i].children[j].getElementsByTagName("p")[0].textContent || target_div[i].children[j].getElementsByTagName("p")[0].innerText
                    txtValues.push(txtValue.toUpperCase());
                } else {
	            	let txtValue = target_div[i].children[j].textContent || target_div[i].children[j].innerText;
	            	txtValues.push(txtValue.toUpperCase());
	            }
	        }

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "table";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const formatDate = (dateString, showDay, showTime) => {
	    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	    const dateObj = new Date(dateString);

	    if (!showTime) {
	    	dateObj.setHours(0, 0, 0, 0);
	    }

	    const year = dateObj.getFullYear();
	    const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
	    const day = ('0' + dateObj.getDate()).slice(-2);

	    const currentDayNumber = dateObj.getDay();

	    if (showDay) {
	        return `${daysOfWeek[currentDayNumber]}, ${day}/${month}/${year}`;
	    } else if (showTime) {
	        let hours = ('0' + dateObj.getHours()).slice(-2);
	        let minutes = ('0' + dateObj.getMinutes()).slice(-2);
	        let seconds = ('0' + dateObj.getSeconds()).slice(-2);

	        return `${day}/${month}/${year} @ ${hours}:${minutes}:${seconds}`;
	    } else {
	        return `${day}/${month}/${year}`;
	    }
	};

	const nextDeliveryDate = (day) => {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

		const currentDate = new Date();
		const currentDayNumber = currentDate.getDay();

		const targetDayNumber = daysOfWeek.indexOf(day);
			if (targetDayNumber === -1) {
			return null;
		}

		let daysToAdd = targetDayNumber - currentDayNumber;
			if (daysToAdd <= 0) {
			daysToAdd += 7;
		}

		const nextDate = new Date(currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
		return nextDate;
	};


	const formatOptions = (options) => {
		let options_arr = []
		for (let o=0; o < options.length; o++) {
			options_arr.push({label: options[o], value: options[o]})
		}

		return options_arr
	}

	const dateToDay = (date) => {
		let dateObj = new Date(date)
		const dayOfWeek = dateObj.getDay();

		const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		const dayName = daysOfWeek[dayOfWeek];

		return dayName
	}

	function getDayNumber(dayName) {
		const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		const dayIndex = daysOfWeek.findIndex(day => day.toLowerCase() === dayName.toLowerCase());
		return dayIndex !== -1 ? dayIndex : null; // Return null if the dayName is invalid
	}

	function validPurchaseDate(dateArr) {
	    const firstDate = new Date(dateArr[0]);
	    const secondDate = new Date(dateArr[1]);

	    const difference = secondDate - firstDate;
	    const daysApart = difference / (1000 * 60 * 60 * 24);
	    const isBefore = firstDate < secondDate

	    return (isBefore && daysApart === 7)
	}

	function sortDeliveries(deliveries) {
	    const runDaysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

	    deliveries.sort((a, b) => {
	        if (a.run_day === "Public Holiday") return 1;
	        if (b.run_day === "Public Holiday") return -1;

	        const dayComparison = runDaysOrder.indexOf(a.run_day) - runDaysOrder.indexOf(b.run_day);
	        if (dayComparison !== 0) return dayComparison;

	        return parseInt(a.run_number) - parseInt(b.run_number);
	    });

	    return deliveries;
	}

	const fetchDeliveriesWithQueueLength = async (deliveries) => {
	    const sortedDeliveries = sortDeliveries(deliveries);
	    return await Promise.all(
	        sortedDeliveries.map(async (delivery) => {
	            const queueLength = await checkQueueLength(delivery.run_day);
	            return { ...delivery, queueLength };
	        })
	    );
	};

	const changeNavType = (type) => {
		setNavType(type)
		refreshDashboard()
	}

	const popUpControls = async (control) => {
		let indDataPopup;

		try {
			switch(control) {
				case 'open':
					switch (navType) {
						case 'Inventory':
							setInventoryPopup(true)
							setIndLoading(true)

							indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/${navType}`, axios_config);
							setNewInventoryData({
								supplier_names: indDataPopup.data.supplierNames,
								subcat_map: indDataPopup.data.subCategoriesMap
							})

							setIndLoading(false)
							break;
						case 'Suppliers':
							setSuppliersPopup(true)
							break;
						case 'Menu':
							setMenuPopup(true)
							setIndLoading(true)

							setMenuSeasonalItem(allMenu.seasonal)
							setMenuItemArr(allMenu.items)

							indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Bulk`, axios_config);
							setAllInvBulk(indDataPopup.data.allInventory)

							setIndLoading(false)
							break;
						case 'Delivery':
							setDeliveryPopup(true)
							break;
					}
					break;
				case 'prev':
					if (navType === "Purchasing") {
						if (popUpPage == 2) {
							setPurchasingData({type: purchasingData.type})
						}
					}
					setPopUpPage(popUpPage-1);
					break;
				case 'next':
					switch (navType) {
						case 'Inventory':
							if (bulkPopup) {
								if (popUpPage == 1) {
									if (bulkProducts.length === 0) {
										setValidationError(`Please select at least 1 product.`)
										return false;
									}
								}
							} else {
								if (popUpPage == 1) {
									if (!(newInventoryData.hasOwnProperty('item_name')) || newInventoryData['item_name'] == '') {
										setValidationError(`Please enter the product name.`)
										return false;
									} else {
										let itemCheck = allInventory.filter(inv => inv.item_name === newInventoryData['item_name'])
										if (itemCheck.length > 0 && !newInventoryData['edit']) {
											setValidationError(`Button:Sorry, an item exists with the name ${newInventoryData['item_name']}. Click to view.`)
											setItemExisting(itemCheck[0])
											return false;
										}
									}

									if (!(newInventoryData.hasOwnProperty('supplier')) || newInventoryData['supplier'] == '') {
										setValidationError(`Please select a supplier or create one through the suppliers tool.`)
										return false;
									}

									if (!(newInventoryData.hasOwnProperty('category')) || newInventoryData['category'] == '') {
										setValidationError(`Please select a category.`)
										return false;
									}

									if (!(newInventoryData.hasOwnProperty('subcategory')) || newInventoryData['subcategory'] == '') {
										if (!(subCatOptions) || !(subCatOptions.length > 0)) {
											setValidationError(`The category you have selected does not have any subcategories available.`)
											return false;
										} else {
											setValidationError(`Please select a subcategory or create a new subcategory by entering it in.`)
											return false;
										}
									}

									if (newInventoryData['category'] == "Fruit" && (!(newInventoryData.hasOwnProperty('is_seasonal')))) {
										setValidationError(`Please select whether this is a seasonal product.`)
										return false;
									}

									if (!(newInventoryData.hasOwnProperty('states_available')) || newInventoryData['states_available'] == '' || newInventoryData['states_available'] == []) {
										setValidationError(`Please select the states this product is available.`)
										return false;
									}

									if (newInventoryData.hasOwnProperty('custom_cutoff')) {
										if (isNaN(newInventoryData.custom_cutoff) || parseInt(newInventoryData.custom_cutoff) === 0 || newInventoryData.custom_cutoff === '' || newInventoryData.custom_cutoff.toString().includes('.')) {
											setValidationError(`The custom cut-off must be a real number that is greater than 0.`)
											return false;
										}
									}
								}

								if (popUpPage == 2) {
									if (!(newInventoryData.hasOwnProperty('purchase_price')) || newInventoryData['purchase_price'] == '') {
										setValidationError(`Please enter the purchase price for this item.`)
										return false;
									}

									if (!(newInventoryData.hasOwnProperty('sale_price')) || newInventoryData['sale_price'] == '') {
										setValidationError(`Please enter the sale price for this item.`)
										return false;
									}

									if (!(newInventoryData.hasOwnProperty('gst')) || newInventoryData['gst'] == '') {
										setValidationError(`Please select either yes or no for GST.`)
										return false;
									}

								}
							}
							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Suppliers':
							if (popUpPage == 1) {
								if (!(newSuppliersData.hasOwnProperty('supplier_name')) || newSuppliersData['supplier_name'] == '') {
									setValidationError(`Please enter the suppliers name.`)
									return false;
								}

								if (!(newSuppliersData.hasOwnProperty('service')) || newSuppliersData['service'] == '') {
									setValidationError(`Please enter the suppliers service.`)
									return false;
								}

								if (newSuppliersData.hasOwnProperty('ordering_email') && newSuppliersData['ordering_email'] && newSuppliersData['ordering_email'] !== '') {
									if (!(isValidEmail(newSuppliersData.ordering_email))) {
										setValidationError(`Please enter a valid email address.`)
										return false;
									}
								}
							}

							if (popUpPage == 2) {
								if (!(newSuppliersContact.hasOwnProperty('name')) || !newSuppliersContact['name'] || newSuppliersContact['name'] == '') {
									setValidationError(`Please enter the contact's full name.`)
									return false;
								}

								if (!(newSuppliersContact.hasOwnProperty('role')) || !newSuppliersContact['role'] || newSuppliersContact['role'] == '') {
									setValidationError(`Please enter the contact's role.`)
									return false;
								}

								if (!(newSuppliersContact.hasOwnProperty('email')) || !newSuppliersContact['email'] || newSuppliersContact['email'] == '') {
									setValidationError(`Please enter the contact's email.`)
									return false;
								} else {
									if (!(isValidEmail(newSuppliersContact.email))) {
										setValidationError(`Please enter a valid email address.`)
										return false;
									}
								}

								if (!(newSuppliersContact.hasOwnProperty('phone')) || !newSuppliersContact['phone'] || newSuppliersContact['phone'] == '') {
									setValidationError(`Please enter the contact's phone.`)
									return false;
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Menu':
							if (popUpPage == 1) {
								if (!(menuSeasonalItem)) {
									setValidationError(`Please choose a seasonal item.`)
									return false;
								}

								let counter = 0
								for (let i=0; i < menuItemArr.length; i++) {
									if (menuItemArr[i].product == '') {
										setValidationError(`Please select a product for item ${menuItemArr[i].id+1}.`)
										return false;
									}

									if (menuItemArr[i].percentage == '') {
										setValidationError(`Please enter a percentage for item ${menuItemArr[i].id+1}.`)
										return false;
									} else {
										if (menuItemArr[i].percentage.includes('%')) {
											setValidationError(`Please remove the % sign in item ${menuItemArr[i].id+1}.`)
											return false;
										}
									}

									if (menuItemArr[i].round == '') {
										setValidationError(`Please select whether item ${menuItemArr[i].id+1} rounds up or down.`)
										return false;
									}

									counter += Number(menuItemArr[i].percentage)
								}

								if (counter > 100) {
									setValidationError(`The percentage can not be over 100%. Current percentage: ${counter}%`)
									return false;
								} else if (counter == 100) {
									setValidationError(`The percentage can not equal 100%. Current percentage: ${counter}%`)
									return false;
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Delivery':
							if (popUpPage == 1) {
								if (!(newDeliveryData.hasOwnProperty('run_day')) || newDeliveryData['run_day'] == '') {
									setValidationError(`Please select a delivery day.`)
									return false;
								} else {
									if (!(Object.keys(runOptions).length > 0) && !(newDeliveryData['edit'])) {
										setValidationError(`This specific delivery day does not have any orders available for this run.`)
										return false;
									}
								}
							}

							if (popUpPage == 2) {
								if (!(Object.keys(runArr).length > 0)) {
									setValidationError(`Please select at least one item.`)
									return false;
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						case 'Purchasing':
							if (overflowPopup) {
								if (popUpPage == 1) {
									if (!(overflowData.hasOwnProperty('day')) || overflowData['day'] == '') {
										setValidationError(`Please select a delivery day.`)
										return false;
									}
								}
							} else {
								if (popUpPage === 1) {
									if (!(purchasingData.hasOwnProperty('type')) || purchasingData['type'] === '') {
										setValidationError(`Please select a purchasing type.`)
										return false;
									}
								}

								if (popUpPage === 2) {
									if (purchasingData.type === "date") {
										if (!(purchasingData.hasOwnProperty('delivery_date')) || purchasingData['delivery_date'] === null || purchasingData['delivery_date'] === '') {
											setValidationError(`Please select a specific delivery date.`)
											return false;
										}
									} else {
										if (!(purchasingData.hasOwnProperty('delivery_date_range')) || purchasingData['delivery_date_range'] === null || purchasingData['delivery_date_range'] === '' || purchasingData.delivery_date_range.length !== 2 || purchasingData.delivery_date_range.includes(null)) {
											setValidationError(`Please select a specific week.`)
											return false;
										} else {
											// ensure that they have selected a WEEK range.
											if (!(validPurchaseDate(purchasingData.delivery_date_range))) {
												setValidationError(`Please ensure the range you have selected is exactly 1 week.`)
												return false;
											}
										}
									}
								}

								if (popUpPage === 3) {
									if (!(purchasingData.hasOwnProperty('categories')) || purchasingData['categories'].length === 0) {
										setValidationError(`Please select at least 1 category.`)
										return false;
									}
								}
							}

							setValidationError(null)
							setPopUpPage(popUpPage+1)
							break;
						}
					break
				case 'close':
					switch (navType) {
						case 'Inventory':
							if (bulkPopup) {
								if (Object.keys(bulkProducts).length > 0) {
									const confirm_close = window.confirm(`Are you sure you would like to discard this version of bulk updating?`)
									if (confirm_close) {
										setBulkPopup(false);

										setBulkProducts({})
										setPopUpPage(1)
										setValidationError(null)
									}
								} else {
									setBulkPopup(false);
									setValidationError(null)
								}
							} else {
								if (Object.keys(newInventoryData).length > 0) {
									const confirm_close = window.confirm(`Are you sure you would like to discard this new product?`)
									if (confirm_close) {
										setInventoryPopup(false);

										setNewInventoryData({})
										setPopUpPage(1)
										setValidationError(null)
									}
								} else {
									setInventoryPopup(false);
									setValidationError(null)
								}
							}
							break;
						case 'Suppliers':
							if (Object.keys(newSuppliersData).length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this new supplier?`)
								if (confirm_close) {
									setSuppliersPopup(false);

									setNewSuppliersData({})
									setNewSuppliersContact({})
									setNewSuppliersSecondaryContact({})
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setSuppliersPopup(false);
								setValidationError(null)
							}
							break;
						case 'Menu':
							if (menuItemArr.length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard these menu changes?`)
								if (confirm_close) {
									setMenuPopup(false);

									setMenuItemArr([])
									setMenuSeasonalItem(null)
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setMenuPopup(false);
								setValidationError(null)
							}
							break;
						case 'Delivery':
							if (runArr.length > 0) {
								const confirm_close = window.confirm(`Are you sure you would like to discard this run?`)
								if (confirm_close) {
									setDeliveryPopup(false);

									setRunArr([])
									setRunOptions({})
									setRunOptionsOG({})
									setNewDeliveryData({})
									setDeliveryNav("Run")
									setDeliveryGroup(null)
									setPopUpPage(1)
									setValidationError(null)
								}
							} else {
								setPopUpPage(1)
								setRunArr([])
								setRunOptions({})
								setRunOptionsOG({})
								setNewDeliveryData({})
								setDeliveryNav("Run")
								setDeliveryGroup(null)
								setDeliveryPopup(false);
								setValidationError(null)
							}
							break;
						case 'Purchasing':
							if (overflowPopup) {
								if (Object.keys(overflowData).length > 0) {
									const confirm_close = window.confirm(`Are you sure you would like to discard editing this overflow?`)
									if (confirm_close) {
										setOverflowPopup(false);

										setOverflowData({})
										setPopUpPage(1)
										setValidationError(null)
									}
								} else {
									setOverflowPopup(false);
									setValidationError(null)
								}
							} else {
								if (Object.keys(purchasingData).length > 0) {
									const confirm_close = window.confirm(`Are you sure you would like to discard this purchase list?`)
									if (confirm_close) {
										setPurchasingPopup(false);

										setPurchasingData({})
										setPopUpPage(1)
										setValidationError(null)
									}
								} else {
									setPurchasingPopup(false);
									setValidationError(null)
								}
							}

							break;
					}
					break;
				case 'submit':
					setIsLoading(true)
					let switchingItem;
					let indControl;
					let itemToSwitch;
					let itemNavPulled;

					switch (navType) {
						case 'Inventory':
							if (bulkPopup) {
								await axios.post(`/api/admin/warehouse/bulk/${param._id}`, {
									bulkData: Object.values(bulkProducts).flat()
								});

								window.location.reload(false);
							} else {
								itemNavPulled = await axios.post(`/api/admin/warehouse/${param._id}`, {
									inventoryData: newInventoryData
								});

								itemToSwitch = allInventory.filter(item => item._id.toString() === itemNavPulled.data._id.toString())[0]
								switchingItem = { ...itemToSwitch, ...itemNavPulled.data };
								indControl = 'Inventory'

								setInventoryPopup(false);
								setNewInventoryData({})
								setSubCatOptions(null)
								setIndInventory(null)
							}
							break;
						case 'Suppliers':
							itemNavPulled = await axios.post(`/api/admin/warehouse/suppliers/${param._id}`, {
								supplierData: newSuppliersData,
								supplierContact: newSuppliersContact,
								secondaryContact: newSuppliersSecondaryContact
							});

							itemToSwitch = allSuppliers.filter(supp => supp._id.toString() === itemNavPulled.data._id.toString())[0]
							switchingItem = { ...itemToSwitch, ...itemNavPulled.data };
							indControl = 'Suppliers'

							setSuppliersPopup(false)
							setNewSuppliersData({})
							setNewSuppliersContact({})
							setNewSuppliersSecondaryContact({})
							setIndSuppliers(null)
							break;
						case 'Menu':
							await axios.post(`/api/admin/warehouse/menu/${param._id}`, {
								menuItems: menuItemArr,
								seasonalItem: menuSeasonalItem
							});

							window.location.reload(false);
							break;
						case 'Delivery':
							itemNavPulled = await axios.post(`/api/admin/warehouse/delivery/${param._id}`, {
								runArr: runArr,
								newDeliveryData: newDeliveryData
							});

							itemToSwitch = allDelivery.filter(delivery => delivery._id.toString() === itemNavPulled.data._id.toString())[0]
							switchingItem = { ...itemToSwitch, ...itemNavPulled.data };
							indControl = 'Delivery'

							setDeliveryPopup(false)
							setDeliveryNav("Run")
							setDeliveryGroup(null)
							setNewDeliveryData({})
							setRunOptions({})
							setRunOptionsOG({})
							setRunArr([])
							setRunDetails({})
							setIndDelivery(null)
							break;
						case 'Purchasing':
							if (overflowPopup) {
								await axios.post(`/api/admin/warehouse/overflow/${param._id}`, {
									overflowData: overflowData
								});

								window.location.reload(false);
							} else {
								itemNavPulled = await axios.post(`/api/admin/warehouse/purchasing/${param._id}`, {
									purchasingData: purchasingData
								});

								itemToSwitch = allPurchasing.filter(purchase => purchase._id.toString() === itemNavPulled.data._id.toString())[0]
								switchingItem = { ...itemToSwitch, ...itemNavPulled.data };
								indControl = 'Purchasing'

								setPurchasingPopup(false)
								setPurchasingData({})
								setIndPurchasing(null)
							}
							break;
					}

					refreshDashboard()

					setIndNav('Details')
					setPopUpPage(1)
					setValidationError(null)
					indPopUpControls('open', switchingItem, indControl)
					setIsLoading(false)
					break;
				case 'bulk':
					setBulkPopup(true)
					setIndLoading(true)

					indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Bulk`, axios_config);
					setAllInvBulk(indDataPopup.data.allInventory)

					setIndLoading(false)
					break;
				case 'overflow':
					setOverflowPopup(true)
					break;
				case 'purchasing':
					setPurchasingPopup(true)
					break;
				case 'christmas_closures': 
					setChristmasClosuresPopup(true)
					break;
				case 'close_christmas_closures':
					setChristmasClosuresPopup(false)
					setChristmasClosuresData(christmasClosuresDataOG)
					break;
				case 'submit_christmas_closures':
					await axios.post(`/api/admin/warehouse/christmas_closures/${param._id}`, {
						christmasClosuresData: christmasClosuresData
					});

					window.location.reload(false);
					break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const handleNewInventoryData = (name, input) => {
		let newInvDataTemp = {...newInventoryData}
 		if (name === "subcategory" && input.value === "Extra Items") {
 			if (newInventoryData['is_seasonal']) {
 				return false;
 			}
		}

		if (name === "is_seasonal" && newInventoryData['subcategory'] === "Extra Items") {
			delete newInvDataTemp['subcategory']
		}

		if (name == "states_available") {
			newInvDataTemp[name] = input.map(obj => obj.value)
			setNewInventoryData(newInvDataTemp);
		} else if (name == "category") {
			let invDup = {...newInventoryData}
			let invDataDup = {
				...invDup,
				item_name: invDup['item_name'],
				supplier: invDup['supplier'],
				states_available: invDup['states_available'],
				[name]: input.value,
				'subcategory': '',
			}

			setNewInventoryData(invDataDup);

			let uniqueAllSubCats = [...new Set([...newInventoryData['subcat_map'][input.value]])];
			setSubCatOptions(uniqueAllSubCats)
		} else {
			if (input.target) {
				newInvDataTemp[name] = input.target.value
				setNewInventoryData(newInvDataTemp);
			} else {
				newInvDataTemp[name] = input.value
				setNewInventoryData(newInvDataTemp);
			}
		}
	};

	const handleNewSuppliersData = (name, input) => {
		if (input.target) {
			setNewSuppliersData({ ...newSuppliersData, [name]: input.target.value });
		} else {
			setNewSuppliersData({ ...newSuppliersData, [name]: input.value });
		}
	};

	const handleNewSuppliersContact = (name, input) => {
		if (input.target) {
			setNewSuppliersContact({ ...newSuppliersContact, [name]: input.target.value });
		} else {
			setNewSuppliersContact({ ...newSuppliersContact, [name]: input.value });
		}
	};

	const handleMenuItem = (control, id) => {
		switch (control) {
			case 'add':
				let menuItemArrID = (menuItemArr.length > 0) ? menuItemArr[menuItemArr.length-1].id + 1 : 0

				let menuItemTemplate = {
					id: menuItemArrID,
					product: '',
					percentage: '',
					code: '',
					active: false,
					round: ''
				}

				setMenuItemArr([ ...menuItemArr, menuItemTemplate ])
				break;
			case 'delete':
				const confirm_close = window.confirm(`Are you sure you would like to discard item ${id+1}?`)
				if (confirm_close) {
					let menuArrDup = [ ...menuItemArr ]
					let menuArrFinal = menuArrDup.filter(MA => MA.id !== id)

					for (let m=0; m < menuArrFinal.length; m++) {
						menuArrFinal[m].id = m
					}
					setMenuItemArr(menuArrFinal)

					setSeasonalOptions(allInvBulk.filter(AI => AI.is_seasonal && AI.is_seasonal === true && !(menuArrFinal.map(MI => MI.product)).includes(AI.item_name) && AI.item_name !== menuSeasonalItem).map(AF => AF.item_name))
				}
				break;
		}
	}

	function isAfterWed1pm() {
	    const now = new Date();
	    const dayOfWeek = now.getDay();
	    const hour = now.getHours();

	    if (dayOfWeek === 3) {
	        if (hour < 13) {
	            return false
	        } else {
	            return true
	        }
	    } else {
	        return false
	    }
	}

	const handleMenuArr = (name, selected, id) => {
		let menuArrDup = [ ...menuItemArr ]
		let menuArrIdx = menuArrDup.indexOf(menuArrDup.filter(MI => MI.id == id)[0])

		menuArrDup[menuArrIdx][name] = selected
		setMenuItemArr(menuArrDup)

		setSeasonalOptions(allInvBulk.filter(AI => AI.is_seasonal && AI.is_seasonal === true && !(menuArrDup.map(MI => MI.product)).includes(AI.item_name) && AI.item_name !== menuSeasonalItem).map(AF => AF.item_name))
	};

	const onDragEndItems = (result) => {
		if (!result.destination) {
	  		return;
		}

		const items = [...menuItemArr];
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		for (let m=0; m < items.length; m++) {
			items[m].id = m
		}

		setMenuItemArr(items);
	};

	const handleSeasonalItem = (selected) => {
		setMenuSeasonalItem(selected)
		setSeasonalOptions(allInvBulk.filter(AI => AI.is_seasonal && AI.is_seasonal === true && !(menuItemArr.map(MI => MI.product)).includes(AI.item_name) && AI.item_name !== selected).map(AF => AF.item_name))
	};

	const changeDeliveryNav = (type) => {
		setDeliveryNav(type)
		setDeliveryGroup(null)
		setValidationError(null)
	}

	const processOrders = (ordersFiltered) => {
	  	const output = {};

	  	for (const order of ordersFiltered) {
	    	const { _id, site_name, order_type, items } = order;

	    	if (!(site_name in output)) {
	      		output[site_name] = {};
	    	}

	    	if (!(order_type in output[site_name])) {
	      		output[site_name][order_type] = { reference: order._id, items: [] };
	    	}

	    	output[site_name][order_type].items.push(...items);
	  	}

	  	for (const order of ordersFiltered) {
	    	const { site_name, order_type } = order;

	   		if (site_name in output && !(order_type in output[site_name])) {
	      		output[site_name][order_type] = { reference: order._id, items: [] };
	    	}
	  	}

		return output;
	}

	const processOrdersNonUnique = (ordersFiltered) => {
	    let outputN = {};

	    for (let order of ordersFiltered) {
	    	if (order.account_status !== "Inactive") {
		      	let { site_name, order_type, items, delivery_date, site_location, company, box_type, order_code, site_pass } = order;
		      	let day = dateToDay(delivery_date);

		      	if (!(site_name in outputN)) {
		        	outputN[site_name] = {};
		      	}

		      	if (!(day in outputN[site_name])) {
		          	outputN[site_name][day] = [];
		      	}

		      	outputN[site_name][day].push({ order_type, reference: order._id, items, site_location, company, box_type, order_code, site_pass });
	      	}
	    }

	    return outputN;
	};


// 	const checkQueueLength = (day) => {
// 		let allOrdersFiltered = allOrders.filter(AO => (AO.delivery_date && dateToDay(AO.delivery_date) == day) && (new Date() < new Date(AO.delivery_date)))
// 		let deliverySiteObj = processOrders(allOrdersFiltered)
// 
// 		const siteNamesInAllDelivery = allDelivery
// 		  .filter(delivery => delivery.run_day === day)
// 		  .map(delivery => delivery.items.map(item => item.site_name))
// 		  .flat();
// 
// 		const filteredDeliverySiteObj = {};
// 
// 		for (const siteName in deliverySiteObj) {
// 		    if (!siteNamesInAllDelivery.includes(siteName)) {
// 		        filteredDeliverySiteObj[siteName] = deliverySiteObj[siteName];
// 		    }
// 		}
// 
// 		return Object.keys(filteredDeliverySiteObj).length
// 	}

	const getQueue = async (deliverySiteObj, day) => {
		let siteNamesInDelivery = allDelivery
		  	.filter(delivery => delivery.run_day === day)
		  	.flatMap(delivery => 
		  		delivery.items.map(item => ({
		  			site: item.site_name, 
		  			references: item.references
		  		}))
		  	);
		let siteNamesMapped = siteNamesInDelivery.map(s => s.site)

		let filteredDeliverySiteObj = {}
		for (let siteName of Object.keys(deliverySiteObj)) {
			if (!siteNamesMapped.includes(siteName)) {
				filteredDeliverySiteObj[siteName] = deliverySiteObj[siteName]
			}
		}

		return filteredDeliverySiteObj
	}

	const checkQueueLength = async (day) => {
		try {
			let indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery/${day}`, axios_config);
			let allOrdersFiltered = indDataPopup.data.orders
			let deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)

			let siteNamesInDelivery = allDelivery
			  	.filter(delivery => delivery.run_day === day)
			  	.flatMap(delivery => 
			  		delivery.items.map(item => ({
			  			site: item.site_name, 
			  			references: item.references
			  		}))
			  	);
			let siteNamesMapped = siteNamesInDelivery.map(s => s.site)

			let filteredDeliverySiteObj = {}
			for (let siteName of Object.keys(deliverySiteObj)) {
				if (!siteNamesMapped.includes(siteName)) {
					filteredDeliverySiteObj[siteName] = deliverySiteObj[siteName]
				}
			}

			let queueLength = Object.keys(filteredDeliverySiteObj).length

			return queueLength
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const getNextRunNumber = (allDelivery, runDay) => {
	    const runNumbers = allDelivery
	        .filter(delivery => delivery.run_day === runDay)
	        .map(delivery => parseInt(delivery.run_number, 10));

	    const maxRunNumber = runNumbers.length > 0 ? Math.max(...runNumbers) : 0;
	    return maxRunNumber + 1;
	};


	const cleanDeliverySiteObj = (deliverySiteObjN, allDelivery, indDelivery=null) => {
		let deliverySiteObjDup = {...deliverySiteObjN}
		let allDeliveryDup = [...allDelivery]

		if (indDelivery) {
			let deliveryToIgnoreIdx = allDeliveryDup.indexOf(indDelivery)
			allDeliveryDup.splice(deliveryToIgnoreIdx, 1)
		}

	    const allReferences = new Set();

	    allDeliveryDup.forEach(delivery => {
	        delivery.items.forEach(item => {
	            item.references.forEach(ref => {
	                allReferences.add(ref);
	            });
	        });
	    });

	    for (const site in deliverySiteObjDup) {
	        for (const day in deliverySiteObjDup[site]) {
	            deliverySiteObjDup[site][day] = deliverySiteObjDup[site][day].filter(order => !allReferences.has(order.reference));

	            if (deliverySiteObjDup[site][day].length === 0) {
	                delete deliverySiteObjDup[site][day];
	            }
	        }

	        if (Object.keys(deliverySiteObjDup[site]).length === 0) {
	            delete deliverySiteObjDup[site];
	        }
	    }

	    return deliverySiteObjDup
	}

	const handleNewDeliveryData = async (name, input) => {
		let indDataPopup;

		try {
			if (name == "run_day") {
				let nextRunNumber = getNextRunNumber(allDelivery, input.value)
				setNewDeliveryData({...newDeliveryData, [name]: input.value, run_number: nextRunNumber })

				let allOrdersFiltered;
				let deliverySiteObj;
				if (input.value === "Public Holiday") {
					setIndLoading(true)

					indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery`, axios_config);
					allOrdersFiltered = indDataPopup.data.orders

					setIndLoading(false)

					deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)

					let deliveryObjCleaned = cleanDeliverySiteObj(deliverySiteObj, allDelivery)

					setRunOptions(deliveryObjCleaned)
					setRunOptionsOG(deliveryObjCleaned)
				} else {
					setIndLoading(true)

					indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery/${input.value}`, axios_config);
					allOrdersFiltered = indDataPopup.data.orders

					setIndLoading(false)

					deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)

					let siteNamesInAllDelivery = allDelivery
					  	.filter(delivery => delivery.run_day === input.value)
					  	.flatMap(delivery => 
					  		delivery.items.map(item => ({
					  			site: item.site_name, 
					  			references: item.references
					  		}))
					  	);
					let siteNamesMapped = siteNamesInAllDelivery.map(s => s.site)

					let filteredDeliverySiteObj = await getQueue(deliverySiteObj, input.value)

					setRunOptions(filteredDeliverySiteObj)
					setRunOptionsOG(deliverySiteObj)
				}

			} else {
				if (input.target) {
					setNewDeliveryData({ ...newDeliveryData, [name]: input.target.value });
				} else {
					setNewDeliveryData({ ...newDeliveryData, [name]: input.value });
				}
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	};

	const handleRunArr = async (name, selected, id) => {
		try {
			let indDataPopup;
			let runArrDup = [ ...runArr ]
			let runArrIdx = runArrDup.indexOf(runArrDup.filter(RA => RA.id == id)[0])
			let runOptionsDup = {...runOptions}

			if (name === "other") {
				runArrDup[runArrIdx].other = selected
			} else if (name === "day") {
				runArrDup[runArrIdx]['references'] = runOptionsOG[runArrDup[runArrIdx]['site']][selected].map(ord => ord.reference)
				runArrDup[runArrIdx]['day'] = selected

				let runFruit = runOptionsOG[runArrDup[runArrIdx]['site']][selected].filter(o => o.order_type === "Fruit").length > 0
				let runMilk = runOptionsOG[runArrDup[runArrIdx]['site']][selected].filter(o => o.order_type === "Milk").length > 0
				let runBread = runOptionsOG[runArrDup[runArrIdx]['site']][selected].filter(o => o.order_type === "Bread").length > 0
				let runCoffee = runOptionsOG[runArrDup[runArrIdx]['site']][selected].filter(o => o.order_type === "Coffee").length > 0
				let runCakes = runOptionsOG[runArrDup[runArrIdx]['site']][selected].filter(o => o.order_type === "Cakes").length > 0
				let runCatering = runOptionsOG[runArrDup[runArrIdx]['site']][selected].filter(o => o.order_type === "Catering").length > 0

				runArrDup[runArrIdx]['fruit'] = (runFruit ? 'FRUIT' : '-')
				runArrDup[runArrIdx]['milk'] = (runMilk ? 'MILK' : '-')
				runArrDup[runArrIdx]['bread'] = (runBread ? 'BREAD' : '-')
				runArrDup[runArrIdx]['coffee'] = (runCoffee ? 'COFFEE' : '-')
				runArrDup[runArrIdx]['cakes'] = (runCakes ? 'CAKES' : '-')
				runArrDup[runArrIdx]['catering'] = (runCatering ? 'CATERING' : '-')
			} else {
				let { [selected]: _, ...runOptionsFiltered } = runOptions;
				runArrDup[runArrIdx][name] = selected

				let allOrdersFiltered;
				let deliverySiteObj;
				if (newDeliveryData.run_day === "Public Holiday") {
					setIndLoading(true)
					indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery`, axios_config);
					allOrdersFiltered = indDataPopup.data.orders
					setIndLoading(false)

					deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)
					let deliveryObjCleaned = cleanDeliverySiteObj(deliverySiteObj, allDelivery)
					delete deliveryObjCleaned[selected]

					setRunOptions(deliveryObjCleaned)
				} else {
					delete runOptionsDup[selected]
					setRunOptions(runOptionsDup)
				}

				if (name == "site") {
					if (newDeliveryData.run_day === "Public Holiday") {
						runArrDup[runArrIdx]['fruit'] = '/'
						runArrDup[runArrIdx]['milk'] = '/'
						runArrDup[runArrIdx]['bread'] = '/'
						runArrDup[runArrIdx]['coffee'] = '/'
						runArrDup[runArrIdx]['cakes'] = '/'
						runArrDup[runArrIdx]['catering'] = '/'
					} else {
						runArrDup[runArrIdx]['references'] = runOptions[selected][newDeliveryData.run_day].map(ord => ord.reference)
						let objInDeliverySite = runOptions[selected]
						let ordersInDelivery = objInDeliverySite && runOptions[selected][newDeliveryData.run_day]

						runArrDup[runArrIdx]['orders'] = ordersInDelivery || []
					}
				}
			}

			setRunArr(runArrDup)
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	};

	const handleRunItem = async (control, id) => {
		try {
			switch (control) {
				case 'add':
					if (Object.keys(runOptions).length === 0) {
						setValidationError("There are no more sites with orders to add to this run.");
						return false;
					} else {
						// if (Object.keys(runArr).length === Object.keys(runOptions).length) {
						// 	setValidationError("There are no more sites with orders to add to this run.");
						// 	return false;
						// }

						let runItemArrID = (runArr.length > 0) ? runArr[runArr.length-1].id + 1 : 0
						let runItemTemplate = {
							id: runItemArrID,
							site: '',
							orders: [],
							references: [],
							other: ''
						}

						setValidationError(null);
						setRunArr([ ...runArr, runItemTemplate ])
					}
					break;
				case 'delete':
					const confirm_close = window.confirm(`Are you sure you would like to discard item ${id+1}?`)
					if (confirm_close) {
						let runArrDup = [ ...runArr ]
						let runArrFinal = runArrDup.filter(RA => RA.id !== id)
						let runItemFound = runArrDup.filter(RA => RA.id === id)[0]

						for (let r=0; r < runArrFinal.length; r++) {
							runArrFinal[r].id = r
						}
						setRunArr(runArrFinal)

						let allOrdersFiltered;
						let deliverySiteObj;
						let indDataPopup;
						if (newDeliveryData.run_day === "Public Holiday") {
							setIndLoading(true)
							indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery`, axios_config);
							allOrdersFiltered = indDataPopup.data.orders
							setIndLoading(false)

							deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)
							let deliveryObjCleaned = cleanDeliverySiteObj(deliverySiteObj, allDelivery)
							setRunOptions({...runOptions, [runItemFound.site]: deliveryObjCleaned[runItemFound.site]})
						} else {
							setRunOptions({...runOptions, [runItemFound.site]: runOptionsOG[runItemFound.site]})
						}
					}
					break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const onDragEndRun = (result) => {
		if (!result.destination) {
	  		return;
		}

		let items = [...runArr];
		const sourceId = result.source.index
	    const sourceIndex = items.findIndex(item => item.id === sourceId);
	    const destinationIndex = result.destination.index

	    if (sourceIndex === -1 || destinationIndex < 0 || destinationIndex >= items.length) {
	        return items;
	    }

	    const [movedItem] = items.splice(sourceIndex, 1);
	    items.splice(destinationIndex, 0, movedItem);

	    const finalItems = items.map((item, index) => ({ ...item, id: index }));
		setRunArr(finalItems);
	};

	function currentDate() {
		const currentDate = new Date();
		const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

		const formattedDate = `${addLeadingZero(currentDate.getDate())}/${addLeadingZero(currentDate.getMonth() + 1)}/${currentDate.getFullYear()}`;
		return formattedDate;
	}

	function getCurrentWeekDate(dayName) {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const date = new Date();
		const dayIndex = daysOfWeek.indexOf(dayName);

		const todayIndex = date.getDay();
		const dayDifference = dayIndex - todayIndex;
		date.setDate(date.getDate() + dayDifference);

		return date
	}

	const handleRunSheet = (control, input=null, manual_name=null) => {
		switch (control) {
			case 'open':
				setRunPopup(true)
				break;
			case 'close':
				setRunPopup(false)
				break;
			case 'set':
				if (manual_name) {
					setRunDetails({...runDetails, [manual_name]: input.value})
				} else {
					setRunDetails({...runDetails, [input.target.name]: input.target.value})
				}
				break;
			case 'submit':
				if (!(runDetails.hasOwnProperty('driver_name')) || newSuppliersData['driver_name'] == '') {
					setValidationError(`Please enter the drivers name.`)
					return false;
				}

				if (!(runDetails.hasOwnProperty('vehicle_rego')) || newSuppliersData['vehicle_rego'] == '') {
					setValidationError(`Please enter the vehicle registration.`)
					return false;
				}

				setValidationError(null)
				setRunPopup(false)
				handleWorkbook(runDetails)
				break;
		}
	}

	const handleWorkbook = async (runDetails, dateSel=null) => {
		try {
			setInternalLoading(true);

			const runName = `${indDelivery.run_day} - Run ${indDelivery.run_number}`
			const driverName = runDetails.driver_name
			const carRego = runDetails.vehicle_rego

			let dateToCheck;
			if (dateSel) {
				dateToCheck = new Date(dateSel)
			} else {
				if (isAfterWed1pm()) {
					dateToCheck = nextDeliveryDate(indDelivery.run_day)
				} else {
					dateToCheck = new Date(getCurrentWeekDate(indDelivery.run_day))
				}
			}

			let indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery/${indDelivery.run_day}`, axios_config);
			let allOrdersFiltered = indDataPopup.data.orders

			if (allOrdersFiltered.length === 0) {
				setInternalLoading(false);
				window.alert("Sorry, no orders were found for this date on this delivery run.");
				return { status: false, message: "Sorry, no orders were found for this date on this delivery run." };
			}

			let deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)

			let newRowData = []
			for (let i=0; i < indDelivery.items.length; i++) {
				if (deliverySiteObj.hasOwnProperty(indDelivery.items[i].site_name)) {
					let orderFoundInFilt = allOrdersFiltered.find(ord => ord.site_name === indDelivery.items[i].site_name)

					let newRowObj = {
						site: orderFoundInFilt.site_name,
						pass: orderFoundInFilt.site_pass === "Yes" ? true : false,
						level: orderFoundInFilt.site_location.level || '-',
						street: orderFoundInFilt.site_location.street || '-',
						suburb: orderFoundInFilt.site_location.city || '-',
						other: (!(indDelivery.items[i].other) || indDelivery.items[i].other == '') ? '-' : indDelivery.items[i].other,
					}

					let site_instructions = orderFoundInFilt.site_instructions
					if (site_instructions) {
						newRowObj['delivery_instructions'] = site_instructions
					} else {
						newRowObj['delivery_instructions'] = ''
					}

					let runFruit = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Fruit")
					let runMilk = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Milk")
					let runBread = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Bread")
					let runCoffee = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Coffee")
					let runCakes = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Cakes")
					let runCatering = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Catering")

					newRowObj['fruit'] = (runFruit.length > 0 ? runFruit[0]['items'].length : '-')
					newRowObj['milk'] = (runMilk.length > 0 ? 'MILK' : '-')
					newRowObj['bread'] = (runBread.length > 0 ? 'BREAD' : '-')
					newRowObj['coffee'] = (runCoffee.length > 0 ? 'COFFEE' : '-')
					newRowObj['cakes'] = (runCakes.length > 0 ? 'CAKES' : '-')
					newRowObj['catering'] = (runCatering.length > 0 ? 'CATERING' : '-')

					newRowData.push(newRowObj)
				}
			}

			const workbook_output = await axios.post(`/api/admin/warehouse/generateRun/${param._id}`, {
				runName: runName,
				driverName: driverName,
				carRego: carRego,
				newRowData: newRowData
			},
            {
                responseType: 'blob'
            });

	        const blob = new Blob([workbook_output.data], {
	            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	        });

	        FileSaver.saveAs(blob, `${runName}.xlsx`);

			// generateAndDownload(workbook_output.data.excelData, runName);

			setInternalLoading(false);
		} catch (error) {
			console.error('Error:', error);
			setInternalLoading(false);
			if (error.response && error.response.data && error.response.data.logout) {
				handleLogout()
			}
		}
	};

	const handleWorkbookPacking = async (dateSel=null) => {
		try {
			setInternalLoading(true);
			let dateToCheck;
			if (dateSel) {
				dateToCheck = new Date(dateSel)
			} else {
				if (isAfterWed1pm()) {
					dateToCheck = nextDeliveryDate(indDelivery.run_day)
				} else {
					dateToCheck = new Date(getCurrentWeekDate(indDelivery.run_day))
				}
			}

			let indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery/${indDelivery.run_day}`, axios_config);
			// let allOrdersFiltered = indDataPopup.data.orders.filter(AO => AO.delivery_date && new Date(AO.delivery_date).setHours(0, 0, 0, 0) == dateToCheck.setHours(0, 0, 0, 0))
			let allOrdersFiltered = indDataPopup.data.orders
			if (allOrdersFiltered.length === 0) {
				setInternalLoading(false);
				window.alert("Sorry, no orders were found for this date on this delivery run.");
				return { status: false, message: "Sorry, no orders were found for this date on this delivery run." }
			}

			let deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)

			let newRowData = []
			for (let i=0; i < indDelivery.items.length; i++) {
				if (deliverySiteObj.hasOwnProperty(indDelivery.items[i].site_name)) {
					let orderFoundInFilt = allOrdersFiltered.find(ord => ord.site_name === indDelivery.items[i].site_name)

					let newRowObj = {
						site: indDelivery.items[i].site_name,
						other: (!(indDelivery.items[i].other) || indDelivery.items[i].other == '') ? '-' : indDelivery.items[i].other,
					}

					let runFruit = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Fruit")
					let runMilk = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Milk")
					let runBread = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Bread")
					let runCoffee = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Coffee")
					let runCakes = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Cakes")
					let runCatering = deliverySiteObj[indDelivery.items[i].site_name][indDelivery.run_day].filter(o => o.order_type === "Catering")

					newRowObj['fruit'] = (runFruit.length > 0 ? runFruit[0] : '-')
					newRowObj['milk'] = (runMilk.length > 0 ? runMilk[0] : '-')
					newRowObj['bread'] = (runBread.length > 0 ? runBread[0] : '-')
					newRowObj['coffee'] = (runCoffee.length > 0 ? runCoffee[0] : '-')
					newRowObj['cakes'] = (runCakes.length > 0 ? runCakes[0] : '-')
					newRowObj['catering'] = (runCatering.length > 0 ? runCatering[0] : '-')

					newRowData.push(newRowObj)
				}
			}

			const runName = `${indDelivery.run_day} - Run ${indDelivery.run_number} | Packing`

			const stringifyData = JSON.stringify({rowData: newRowData, runName: runName});
			const compressedData = pako.gzip(stringifyData);
			const dataBlob = new Blob([compressedData], { type: 'application/gzip' });

			const workbook_output = await axios.post(`/api/admin/warehouse/generatePacking/${param._id}`, dataBlob, {
			    headers: {
			        'Content-Encoding': 'gzip',
			        'Content-Type': 'application/gzip'
			    }, responseType: 'blob'
			});

	        const blob = new Blob([workbook_output.data], {
	            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	        });

	        FileSaver.saveAs(blob, `${runName}.xlsx`);

			// generateAndDownload(workbook_output.data.excelData, runName);
			setInternalLoading(false);
		} catch (error) {
			console.error('Error:', error);
			setInternalLoading(false);
			if (error.response && error.response.data && error.response.data.logout) {
				handleLogout()
			}
		}
	};

	const handleWorkbookContacts = async () => {
		try {
			setInternalLoading(true);

			let newRowData = []
			for (let i=0; i < indDelivery.items.length; i++) {
				let newRowObj = {
					site: indDelivery.items[i].site_name,
					name: `${allSites.filter(AS => AS.site_name == indDelivery.items[i].site_name)[0].site_contact.first_name} ${allSites.filter(AS => AS.site_name == indDelivery.items[i].site_name)[0].site_contact.last_name}`,
					email: `${allSites.filter(AS => AS.site_name == indDelivery.items[i].site_name)[0].site_contact.email}`,
					phone: `${allSites.filter(AS => AS.site_name == indDelivery.items[i].site_name)[0].site_contact.phone}`,
				}
				newRowData.push(newRowObj)
			}

			const runName = `${formatDate(nextDeliveryDate(indDelivery.run_day), true)} - Run ${indDelivery.run_number} | Contacts`

			const workbook_output = await axios.post(`/api/admin/warehouse/generateContacts/${param._id}`, {
				newRowData: newRowData,
				runName: runName
			},
            {
                responseType: 'blob'
            });
	            
	        const blob = new Blob([workbook_output.data], {
	            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	        });

	        FileSaver.saveAs(blob, `${runName}.xlsx`);

			// generateAndDownload(workbook_output.data.excelData, runName);
			setInternalLoading(false);
		} catch (error) {
			console.error('Error:', error);
			setInternalLoading(false);
		}
	};

	async function generateAndDownload(excelData, runName) {
		const excelDataUint8Array = new Uint8Array(atob(excelData).split('').map((char) => char.charCodeAt(0)));

		const workbook = new ExcelJS.Workbook();
		await workbook.xlsx.load(excelDataUint8Array);

		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		FileSaver.saveAs(blob, `${runName}.xlsx`);
	}

	const changeIndNav = async (type) => {
		setIndNav(type)

	  	if (type == "Map") {
	    	await initializeMapWithAccessToken(param, 10)
	  	}
	}

	const indPopUpControls = async (control, ind, external=null) => {
		if (external) {
			switch (external) {
				case 'Inventory':
					setNavType("Inventory")
					setIndInventoryPopup(true);
					setIndInventory(ind)
					break;
				case 'Suppliers':
					setNavType("Suppliers")
					setIndSuppliersPopup(true);
					setIndSuppliers(ind)
					break;
				case 'Delivery':
					setNavType("Delivery")
					setIndDeliveryPopup(true);
					setIndDelivery(ind)
					break;
				case 'Purchasing':
					setNavType("Purchasing")
					setIndPurchasingPopup(true);
					setIndPurchasing(ind)
					break;
			}

			return false;
		}

		let indData;

		try {
			switch(control) {
				case 'open':
					switch (navType) {
						case 'Inventory':
							setIndInventoryPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/warehouse/ind/${param._id}/${navType}/${ind._id}`, axios_config);
							setIndInventory(indData.data)

							setIndLoading(false)
							break;
						case 'Suppliers':
							setIndSuppliersPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/warehouse/ind/${param._id}/${navType}/${ind._id}`, axios_config);
							setIndSuppliers(indData.data)

							setIndLoading(false)
							break;
						case 'Delivery':
							setIndDeliveryPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/warehouse/ind/${param._id}/${navType}/${ind._id}`, axios_config);
							setIndDelivery(indData.data)

							setIndLoading(false)
							break;
						case 'Purchasing':
							setIndPurchasingPopup(true);
							setIndLoading(true)

							indData = await axios.get(`/api/admin/warehouse/ind/${param._id}/${navType}/${ind._id}`, axios_config);
							setIndPurchasing(indData.data)

							setIndLoading(false)
							break;
					}
					break
				case 'edit':
					switch (navType) {
						case 'Inventory':
							// hig
							setIndLoading(true)

							let editinvoiceprops = await axios.get(`/api/admin/warehouse/popup/${param._id}/${navType}`, axios_config);
							setNewInventoryData({
								supplier_names: editinvoiceprops.data.supplierNames,
								subcat_map: editinvoiceprops.data.subCategoriesMap
							})

							setIndLoading(false)


							setNewInventoryData({
								supplier_names: editinvoiceprops.data.supplierNames,
								subcat_map: editinvoiceprops.data.subCategoriesMap,

								item_name: indInventory.item_name,
								purchase_price: indInventory.purchase_price,
								sale_price: indInventory.sale_price,
								gst: indInventory.gst ? "Yes" : "No",
								stock: indInventory.stock,
								category: indInventory.category,
								subcategory: indInventory.subcategory,
								is_seasonal: indInventory.is_seasonal,
								states_available: indInventory.states_available,
								supplier: indInventory.supplier_name,
								crate_qty: indInventory.crate_qty,
								width: indInventory.width,
								length: indInventory.length,
								custom_cutoff: indInventory.custom_cutoff ? indInventory.custom_cutoff : "",

								uid: indInventory.uid,
								rowversion: indInventory.rowversion,
								item_code: indInventory.item_code,

								edit: true,
								inventoryID: indInventory._id
							})

							// let allSubCats = allInventory.filter(AI => AI.category == indInventory.category).map(AS => AS.subcategory)
							// let uniqueAllSubCats = [...new Set(allSubCats)];

							// setSubCatOptions(uniqueAllSubCats)

							setIndInventoryPopup(false)
							setIndInventory(null)
							setInventoryPopup(true)
							break;
						case 'Suppliers':
							setNewSuppliersData({
								supplier_name: indSuppliers.supplier_name,
								service: indSuppliers.service,
								ordering_email: indSuppliers.ordering_email,
								account_reference: indSuppliers.account_reference,
								uid: indSuppliers.uid,
								rowversion: indSuppliers.rowversion,
								supplier_code: indSuppliers.supplier_code,

								edit: true,
								supplierID: indSuppliers._id
							})

							setNewSuppliersContact({
								role: indSuppliers.contact[0].role,
								name: indSuppliers.contact[0].name,
								email: indSuppliers.contact[0].email,
								phone: indSuppliers.contact[0].phone
							})

							if (indSuppliers.contact.length === 2) {
								setNewSuppliersSecondaryContact({
									role: indSuppliers.contact[1].role,
									name: indSuppliers.contact[1].name,
									email: indSuppliers.contact[1].email,
									phone: indSuppliers.contact[1].phone
								})
							}
							
							setIndSuppliersPopup(false)
							setIndSuppliers(null)
							setSuppliersPopup(true)
							break;
						case 'Delivery':
							setNewDeliveryData({
								run_day: indDelivery.run_day,
								run_number: indDelivery.run_number,
								edit: true,
								deliveryID: indDelivery._id
							})

							// edit delivery here
							setIndLoading(true)
							let indDataPopup = await axios.get(`/api/admin/warehouse/popup/${param._id}/Delivery/${indDelivery.run_day}`, axios_config);
							let allOrdersFiltered = indDataPopup.data.orders
							setIndLoading(false)

							let deliverySiteObj = processOrdersNonUnique(allOrdersFiltered)
							let filteredDeliverySiteObj = await getQueue(deliverySiteObj, indDelivery.run_day)

							let run_arr_edit = []
							for (let i=0; i < indDelivery.items.length; i++) {
								let objSiteName = indDelivery.items[i].site_name
								let objInDeliverySite = deliverySiteObj[objSiteName]
								let ordersInDelivery = objInDeliverySite && deliverySiteObj[objSiteName][indDelivery.run_day]

								if (objInDeliverySite && ordersInDelivery) {
									run_arr_edit.push({
										id: indDelivery.items[i].order,
										site: indDelivery.items[i].site_name,
										references: indDelivery.items[i].references,
										orders: ordersInDelivery,

										other: indDelivery.items[i].other
									})
								}
							}

							setRunOptions(filteredDeliverySiteObj)
							setRunOptionsOG(deliverySiteObj)
							setRunArr(run_arr_edit)

							setIndDeliveryPopup(false)
							setIndDelivery(null)
							setDeliveryPopup(true)
							break;
						case 'Purchasing':
							let editPurchaseObj = {
								type: indPurchasing.type,
								items: indPurchasing.items,
								categories: indPurchasing.categories || [],

								edit: true,
								purchasingID: indPurchasing._id
							}

							if (indPurchasing.type === "date") {
								editPurchaseObj['delivery_date'] = indPurchasing.delivery_date
							} else if (indPurchasing.type === "date_range") {
								editPurchaseObj['delivery_date_range'] = [dayjs(indPurchasing.delivery_date_range[0]), dayjs(indPurchasing.delivery_date_range[1])]
							}

							setPurchasingData(editPurchaseObj)
							setPopUpPage(2)

							setIndPurchasingPopup(false)
							setIndPurchasing(null)
							setPurchasingPopup(true)
							break;
					}
					break
				case 'close':
					switch (navType) {
						case 'Inventory':
							setIndInventoryPopup(false)
							setIndNav("Details")
							setIndInventory(null)
							break;
						case 'Suppliers':
							setIndSuppliersPopup(false)
							setIndNav("Details")
							setIndSuppliers(null)
							break;
						case 'Delivery':
							setIndDeliveryPopup(false)
							setIndNav("Details")
							setIndDelivery(null)
							break;
						case 'Purchasing':
							setIndPurchasingPopup(false)
							setIndNav("Details")
							setIndPurchasing(null)
							break;
					}
					break;
				case 'navigate':
					switch (navType) {
						case 'Inventory':
							setIndInventoryPopup(false)
							setIndNav("Details")
							setIndInventory(null)

							setNavType("Suppliers")
							setIndSuppliersPopup(true)
							setIndSuppliers(ind)
							break;
						case 'Suppliers':
							setIndSuppliersPopup(false)
							setIndNav("Details")
							setIndSuppliers(null)

							setNavType("Inventory")
							setIndInventoryPopup(true)
							setIndInventory(ind)
							break;
						case 'Menu':
							setNavType("Inventory")
							setIndInventoryPopup(true)
							setIndInventory(ind)
							break;
						case 'Purchasing':
							setIndPurchasingPopup(false)
							setIndNav("Details")
							setIndPurchasing(null)

							setNavType("Inventory")
							setIndInventoryPopup(true)
							setIndInventory(allInventory.filter(item => item._id === ind)[0])
							break;
					}
					break;
				case 'excel':
					const purchaseName = `${ind.delivery_date ? formatDate(ind.delivery_date, true) : `${formatDate(ind.delivery_date_range[0])} - ${formatDate(ind.delivery_date_range[1])}`}`
					const workbook_output = await axios.post(`/api/admin/warehouse/generatePurchasing/${param._id}`, {
						purchaseItems: ind.items,
						purchaseName: purchaseName
					},
		            {
		                responseType: 'blob'
		            });

			        const blob = new Blob([workbook_output.data], {
			            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			        });

			        FileSaver.saveAs(blob, `${purchaseName}.xlsx`);

					// generateAndDownload(workbook_output.data.excelData, purchaseName);
					break;
				case 'delete':
					let confirm_status;
					switch (navType) {
						case 'Inventory':
							if (allMenu.seasonal === ind.item_name) {
								window.alert("You can not delete an item that is the active Seasonal Product in the menu")
								return false;
							}

							confirm_status = window.confirm(`Deleting this item will also delete from menu, overflow, tiers and user's preferences.`)
							if (confirm_status === true) {
								await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
									type: navType,
									ind: ind
								});
								window.location.reload(false)
							}
							break;
						case 'Delivery':
							confirm_status = window.confirm(`Are you sure you would like to delete this?`)
							if (confirm_status === true) {
								await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
									type: navType,
									ind: ind
								});
								window.location.reload(false)
							}
							break;
						case 'Purchasing':
							confirm_status = window.confirm(`Are you sure you would like to delete this?`)
							if (confirm_status === true) {
								await axios.post(`/api/admin/dashboard/delete/${param._id}`, {
									type: navType,
									ind: ind
								});
								window.location.reload(false)
							}
							break;
					}
					break;
				case 'nav_item':
					const confirm_close = window.confirm(`You are about to discard this new item and open the existing item.`)
					if (confirm_close) {
						setInventoryPopup(false);
						setNewInventoryData({})
						setPopUpPage(1)
						setValidationError(null)

						setIndInventoryPopup(true)
						setIndInventory(ind)
					}
					break;
				case 'toggle_model':
					setShowIndModel(!showIndModel)
					break;
				case 'nav_boxes':
					setBoxModelShowing(ind)
					break;
			}
		} catch (e) {
			if (e.response && e.response.data && e.response.data.logout) {
				handleLogout()
			}
		}
	}

	const handleBulk = (control, selectedOptions=null, index=null, name=null, input=null) => {
		switch (control) {
			case 'add':
			    const selectedItemsForCategory = selectedOptions.map(o => o.value);

			    setBulkProducts(prev => {
			      const previousSelectedItems = prev[bulkCategory] || [];
			      const fullItemsForNewSelections = allInvBulk.filter(item => 
			        selectedItemsForCategory.includes(item.item_name) &&
			        item.category.toLowerCase() === bulkCategory.toLowerCase()
			      );

			      const updatedSelections = {
			        ...prev,
			        [bulkCategory]: fullItemsForNewSelections,
			      };

			      return updatedSelections;
			    });

				break;
			case 'update':
				let editBulkProducts = {...bulkProducts}

				if (name === "is_seasonal") {
					if (editBulkProducts[bulkCategory][index]['subcategory'] === "Extra Items" && input.value === true) {
						return false;
					} else {
						editBulkProducts[bulkCategory][index][name] = input.value
					}
				} else {
					editBulkProducts[bulkCategory][index][name] = input.target.value
				}
				
				setBulkProducts(editBulkProducts)
				break;
		}
	}

	function hasValidDecimalPlaces(value) {
		const decimalCount = value.toString().split('.')[1];
		return decimalCount === undefined || decimalCount.length <= 2;
	}

	const handleOverflow = (control, name, input) => {
		switch (control) {
			case 'update':
				if (name == "day") {
					setOverflowData({
						day: input.value,
						items: allOverflow.filter(AO => AO.day === input.value)[0].items.map(AT => ({
							item: allInventory.filter(AI => AI._id.toString() === AT.item.toString())[0].item_name,
							percentage: AT.percentage
						}))
					})
				} else {
					if (!(!isNaN(parseFloat(input.target.value)) && isFinite(input.target.value))) {
						setValidationError(`Please enter a real number for ${name}`)
					} else if (!(input.target.value >= 0 && input.target.value <= 100)) {
						setValidationError(`Please enter a number between 0 and 100 for ${name}`)
					} else if (!hasValidDecimalPlaces(input.target.value)) {
						setValidationError(`The percentage can not have more than 2 decimals for ${name}`)
					} else {
						setValidationError(null)
					}

					let currentOverflowItems = [...overflowData.items]
					currentOverflowItems.find(item => item.item === name).percentage = parseFloat(input.target.value) || 0

					setOverflowData({...overflowData, items: currentOverflowItems})
				}

				break;
		}
	}

	const handlePurchasing = (control, name, input) => {
		switch (control) {
			case 'set':
				let purchaseDataDup = { ...purchasingData }

				if (name === "categories") {
					let selMap = input.map(inp => inp.value)

					if (selMap[0] === "Add all") {
						selMap = ["Fruit", "Milk", "Bread", "Coffee", "Cakes", "Catering"]
					}

					purchaseDataDup[name] = selMap
				} else { 
					purchaseDataDup[name] = input.value
				}

				setPurchasingData(purchaseDataDup) 
				break;
		}
	}

	const changeStatus = async (control, type=null, ind=null, status=null) => {
		switch (control) {
			case 'toggle':
				setChangingStatus(!changingStatus)
				break;
			case 'set':
				const confirm_status = window.confirm(`Are you sure you would like to change the status to ${status}?`)
				if (confirm_status) {
					await axios.post(`/api/admin/dashboard/status/${param._id}`, {
						type: type,
						ind: ind._id,
						status: status
					});
					window.location.reload(false)
				}
				break;
		}
	}

	const handleNewSecondarySuppliersContact = (name, input) => {
		if (input.target) {
			setNewSuppliersSecondaryContact({ ...newSuppliersSecondaryContact, [name]: input.target.value });
		} else {
			setNewSuppliersSecondaryContact({ ...newSuppliersSecondaryContact, [name]: input.value });
		}
	};

	const changeBulkCategory = (type) => {
		setBulkCategory(type)
	}

	const handleDeliveryRecords = async (control, selection?) => {
		switch (control) {
			case 'open':
				setIndDeliveryPopup(false)
				setDeliveryRecordsPopup(true)
				break;
			case 'close':
				setDeliveryRecordsPopup(false)
				setIndDeliveryPopup(true)
				setSelectedRecordDate(null)
				setValidationError(null)
				break;
			case 'set':
				setSelectedRecordDate(selection.value)
				break;
			case 'submit':
				if (!selectedRecordDate || selectedRecordDate == '') {
					setValidationError(`Please select a delivery date.`)
					return false;
				}

				// also get delivery run
				// handleWorkbook
				let valStatDel = await handleWorkbook(selectedRecordDate)
				let valStat = await handleWorkbookPacking(selectedRecordDate)

				if (valStatDel.status === false) {
					setValidationError(valStat.message)
					return false;
				}

				if (valStat.status === false) {
					setValidationError(valStat.message)
					return false;
				}
				break;
		}
	}

	const handleChristmasClosures = (control, input) => {
		switch (control) {
			case 'set':
				setChristmasClosuresData(input.value) 
				break;
		}
	}

	const searchDeliveryControl = (event) => {
	    let input = event.target.value;
	    let filter = input.toUpperCase();
	    let target_div = document.getElementById('delivery_table').children

	    for (let i = 0; i < target_div.length; i++) {
	    	let containerTitle = target_div[i].children[0].children[0].textContent
	        let txtValues = [containerTitle.toUpperCase()];

	        let showItem = false;
	        txtValues.forEach((value) => {
	            if (value.indexOf(filter) > -1) {
	                showItem = true;
	            }
	        });

	        if (showItem) {
	            target_div[i].style.display = "grid";
	        } else {
	            target_div[i].style.display = "none";
	        }
	    }
	};

	return (
		<div key={key}>
			<Detector
				polling={{ timeout: 7000 }}
				render={({ online }) => (
					<>
						{!online && (
							<div className="offline_container">
								<div className="offline_inner animate__animated animate__slideInDown">
									<i className="fa-solid fa-heart-crack"></i>
									<div className="offline_content">
										<p className="offline_title">You are offline</p>
										<p className="offline_subtitle">Please check your internet connect.</p>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			/>

			{!isLoading
				?
					<>
						{isAuthenticated
							?
							<div className={collapseFilters ? styles.main_container : styles.main_container_full}>
								<NavigationBar 
									param={param}
									active={"Warehouse"}
									handleLogout={handleLogout}
									collapseDeg={collapseDeg}
									setCollapseDeg={setCollapseDeg}
									collapseFilters={collapseFilters}
									setCollapseFilters={setCollapseFilters}
								/>
								<WarehousePage {...{ param, navType, changeNavType, allInventory, popUpControls, inventoryPopup, suppliersPopup, menuPopup, popUpPage, validationError, formatOptions, handleMenuItem, menuItemArr, handleMenuArr, onDragEndItems, handleSeasonalItem, menuSeasonalItem, allSuppliers, seasonalOptions, allMenu, deliveryPopup, changeDeliveryNav, deliveryNav, handleNewDeliveryData, newDeliveryData, runOptions, handleRunArr, runArr, handleRunItem, onDragEndRun, allSites, allDelivery, formatDate, nextDeliveryDate, indPopUpControls, indDeliveryPopup, indInventoryPopup, indSuppliersPopup, bulkPopup, handleBulk, bulkProducts, overflowPopup, purchasingPopup, allPurchasing, indPurchasingPopup, searchControl, checkQueueLength, changeBulkCategory, bulkCategory, deliveryRecordsPopup, runOptionsOG, sortDeliveries, setAllInventory, axios_config, setAllSuppliers, indLoading, allInvBulk, christmasClosuresPopup, fetchDeliveriesWithQueueLength, handleLogout, searchDeliveryControl, deliveryGroup, setDeliveryGroup }} />

								{inventoryPopup && <PopUpInventory {...{ param, popUpPage, popUpControls, newInventoryData, validationError, allInventory, handleNewInventoryData, subCatOptions, formatOptions, allSuppliers, indPopUpControls, itemExisting, indLoading }} />}
								{suppliersPopup && <PopUpSuppliers {...{ param, popUpPage, popUpControls, newSuppliersData, validationError, allSuppliers, handleNewSuppliersData, formatOptions, newSuppliersContact, handleNewSuppliersContact, handleNewSecondarySuppliersContact, newSuppliersSecondaryContact }} />}
								{overflowPopup && <PopUpOverflow {...{ param, popUpPage, popUpControls, newInventoryData, validationError, allInventory, handleNewInventoryData, subCatOptions, formatOptions, allSuppliers, handleOverflow, overflowData }} />}
								{purchasingPopup && <PopUpPurchasing {...{ param, popUpPage, popUpControls, validationError, formatOptions, handlePurchasing, purchasingData, formatDate }} />}
								{christmasClosuresPopup && <PopUpChristmasClosures {...{ param, popUpControls, validationError, formatOptions, formatDate, christmasClosuresData, handleChristmasClosures }} />}

								{indInventoryPopup && <PopUpIndInventory {...{ param, indPopUpControls, indInventory, indNav, changeIndNav, allSuppliers, changeStatus, changingStatus, allLogs, formatDate, searchControl, indLoading }} />}
								{indSuppliersPopup && <PopUpIndSuppliers {...{ param, indPopUpControls, indSuppliers, indNav, changeIndNav, allInventory, allSuppliers, changeStatus, changingStatus, searchControl, indLoading }} />}
								{(indDeliveryPopup && !deliveryRecordsPopup) && <PopUpIndDelivery {...{ param, indPopUpControls, indDelivery, indNav, changeIndNav, formatDate, nextDeliveryDate, handleRunSheet, handleWorkbookPacking, handleWorkbookContacts, allSites, internalLoading, runPopup, searchControl, mapLoading, boxModelShowing, showIndModel, handleDeliveryRecords, indLoading }} />}
								{indPurchasing && <PopUpIndPurchasing {...{ param, indPopUpControls, indPurchasing, indNav, changeIndNav, allPurchasing, allInventory, formatDate, searchControl, indLoading }} />}
								{runPopup && <PopUpRunSheet {...{ param, handleRunSheet, validationError, allFleet, formatOptions }} />}
								{(!indDeliveryPopup && deliveryRecordsPopup) && <PopUpDeliveryRecords {...{ handleDeliveryRecords, selectedRecordDate, indDelivery, validationError }} />}
							</div>
							: <Forbidden />
						}
					</>
				:
					<Loading current={"Warehouse"} />
			}
		</div>
	);
};

export default Warehouse;