import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import ofglogo from '../../images/logo.png'
import citysvg from '../../images/city.svg'
import styles from "./Login.module.css"


const Login = () => {
	const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");
	const [showError, setShowError] = useState(false)


	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = "/api/auth";
			let res;

			try {
				res = await axios.post(url, data)

				localStorage.setItem("role_token", res.data.data.jwt_token);
				localStorage.setItem("session_token", res.data.data.sessionToken);
				localStorage.setItem("token", res.data.data.token);

				switch (res.data.data.specific_role_name) {
					case 'Account':
						window.location = "/dashboard/" + res.data._id;
						break;
					case 'Admin':
						window.location = "/admin/dashboard/" + res.data._id;
						break
					case 'SuperAdmin':
						window.location = "/admin/dashboard/" + res.data._id;
						break
				}
			} catch (e) {
				setShowError(true)
				return
			}

		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};

	return (

		<div className={styles.bg}>
			<div className={styles.container}>
			  <div className={styles.outerSquare}>
			    <img className={styles.logo} src={ofglogo} />
			    <div className={styles.leftSide}>
			      <h1>Login to our web-based order management system</h1>
			      <p>Welcome, please sign in to access your account</p>
			      <form onSubmit={handleSubmit}>

			      	<div className={styles.formWrapper}>
				        <label htmlFor="email">
				          Email Address
				        </label>
				        <input
				          type="email"
				          name="email"
				          placeholder="johndoe@gmail.com"
				          onChange={handleChange}
				          value={data.email}
				          required
				        />
			        </div>

			        <div className={styles.formWrapper}>
				        <label htmlFor="pwd">
				          Password
				        </label>
				        <input
				          className={styles.input_line}
					    	  type="password"
					    	  name="password"
					    	  placeholder="*********"
					    	  onChange={handleChange}
					    	  value={data.password}
					    	  required
				        />
			        </div>
			        <div className={styles.controls}>
				        <a href="/forgot-password" className={styles.fgtPwd}>Forgot Password?</a>

				        <div className={styles.field}>
				          <label className={styles.checkbox}>
				            <input type="checkbox" name="remember" />
				            <p>Remember me</p>
				          </label>
				        </div>
				        <button
				          className={styles.loginBtn}
				          type="submit"
				          style={{ cursor: "pointer" }}
				        >
				          Login
				        </button>
			        </div>
 			      </form>
			      <div className={styles.followus}>
			        <p>follow us</p>
			        <div className={styles.socials}>
			          <a href="https://officefruit.com.au" target="_blank">
			            <p>website</p>
			          </a>
			          <a href="https://www.instagram.com/officefruitgroup" target="_blank">
			            <p>instagram</p>
			          </a>
			          <a href="https://www.facebook.com/officefruitgroup" target="_blank">
			            <p>facebook</p>
			          </a>
			        </div>
			      </div>

			      {showError &&
			        <div className={`${styles.notification} ${styles.error}`}>
			          <p>You have entered an invalid username or password</p>
			        </div>
			      }

			    </div>
			    <div className={styles.rightSide}>
			      <img className={styles.citySvg} src={citysvg} />
			    </div>
			  </div>

		  </div>
		</div>


	);
};

export default Login;
