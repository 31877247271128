import styles from "./Users.module.css"
import ResizableDiv from '../Utils/ResizableDiv';


const PopUpIndDriver = ({ param, indPopUpControls, indDriver, changeIndNav, indNav, formatDate, searchControl, customShiftEdit, changeStatus, changingStatus, handleCustomShift }) => {
	return (
		<div className={styles.indsite_container}>
			<ResizableDiv className="indsite_popup" startingWidth="50">

				<div className={styles.indsite_inner}>
					<button className={styles.closePopup} onClick={() => indPopUpControls('close')}><i className="fa-solid fa-x"></i></button>
					<button className={styles.editPopup} onClick={() => indPopUpControls('edit')}><i className="fa-solid fa-pen"></i></button>
					{indDriver.shifts.length === 0 && <button className={styles.nextPopup} onClick={() => indPopUpControls('delete', indDriver)}><i className="fa-solid fa-trash-can"></i></button>}

					<h1 className={styles.medium_font}>Driver {indNav.toLowerCase()}</h1>
					<p className={styles.smaller_font}>Please find the specific {indNav.toLowerCase()} for your driver here.</p>


					<div className={styles.indsite_nav}>
						<div className={styles.sitenav}>
							<button className={`${styles.sitenav_box} ${indNav == 'Details' && styles.sitenav_active}`} disabled={changingStatus} onClick={() => changeIndNav('Details')}><p>Details</p></button>
							<button className={`${styles.sitenav_box} ${indNav == 'Shifts' && styles.sitenav_active}`} disabled={changingStatus} onClick={() => changeIndNav('Shifts')}><p>Shifts</p></button>
						</div>
					</div>

					{indNav == "Details" &&
						<div className={`${styles.sites_tablecontainer} ${changingStatus && styles.hidden_container}`}>
							<div className={`${styles.indinfo_grid} ${styles.indsiteinfo_gap}`}>
								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Driver code</label>
									<p>{indDriver.driver_code}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Name</label>
									<p>{indDriver.first_name} {indDriver.last_name}</p>
								</div>

								<div className={styles.popup_formbox} style={{position: 'relative'}}>
									<div style={{position: 'absolute'}}>
										<div style={{display: 'grid', gridTemplateRows: 'auto', rowGap: '2.5px'}}>
											<label className={styles.smaller_font}>Status</label>
							    			<button onClick={() => changeStatus('toggle')} className={`${styles.site_status} ${(indDriver.status === "Active") && styles.status_active} ${indDriver.status === "Inactive" && styles.status_inactive}`}>
							    				<p className={styles.smaller_font}>{indDriver.status}</p>
							    			</button>
						    			</div>
					    			</div>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>No. shifts</label>
									<p>{indDriver.shifts.length}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Last Shift</label>
									<p>{indDriver.shifts.length > 0 ? `${formatDate(indDriver.shifts[0].clocked_on, false, true)} - ${indDriver.shifts[0].clocked_off ? formatDate(indDriver.shifts[0].clocked_off, false, true) : "..."}` : "-"}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>Phone</label>
									<p>{indDriver.phone_number}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>DOB</label>
									<p>{formatDate(indDriver.dob)}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>State</label>
									<p>{indDriver.state || "-"}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>License number</label>
									<p>{indDriver.license_number ? indDriver.license_number : "-"}</p>
								</div>

								<div className={styles.popup_formbox}>
									<label className={styles.smaller_font}>License expiration</label>
									<p>{indDriver.license_expiration ? formatDate(indDriver.license_expiration) : '-'}</p>
								</div>
							</div>
						</div>
					}

	    			{changingStatus &&
	    				<div className={styles.statusOptions}>
	    					<button className={`${styles.closePopup} ${styles.closeStatusPopup}`} onClick={() => changeStatus('toggle')}><i className="fa-solid fa-x"></i></button>

							<h1 className={styles.medium_font}>Change status</h1>
							<p className={`${styles.smaller_font}`}>Use this module to update the status for {indDriver.first_name} {indDriver.last_name}</p>

							<div className={styles.statusOuter}>
								<div className={styles.statusInner}>
					    			{indDriver.status !== "Active" &&
						    			<button onClick={() => changeStatus('set', 'Drivers', indDriver, 'Active')} className={`${styles.site_status} ${styles.status_active}`}>
						    				<p className={styles.smaller_font}>Active</p>
						    			</button>
						    		}

						    		{indDriver.status !== "Inactive" &&
						    			<button onClick={() => changeStatus('set', 'Drivers', indDriver, 'Inactive')} className={`${styles.site_status} ${styles.status_inactive}`}>
						    				<p className={styles.smaller_font}>Inactive</p>
						    			</button>
						    		}
					    		</div>
				    		</div>
	    				</div>
	    			}

					{indNav === "Shifts" &&
						<div className={styles.sites_tablecontainer}>
							<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
								<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
									<div className={styles.sites_search}>
										<i className="fa-solid fa-magnifying-glass"></i>
										<input
										    type="text"
										    className={styles.smaller_font}
										    placeholder="Search for any of the column headings..."
										    onKeyUp={(event) => searchControl(event, "ind_table")}
										/>
									</div>
								</div>

								<button
									className={styles.indsitebtn_driver}
									onClick={() => handleCustomShift('open')}
									disabled={indDriver.hasOpenShift}
								>
									<i className="fa-solid fa-plus"></i>
									<p className={styles.smaller_font}>Add shift</p>
								</button>
							</div>

							<div className={styles.table_container}>
								<table className={styles.sites_table} id="ind_table">
								    <thead className={styles.sitesind_thead}>
								      <tr className={styles.table_rowth}>
								      	<th colspan="2">Clocked on</th>
								      	<th colspan="2">Clocked off</th>
								      	<th colspan="2">Clicked clock off</th>
								      	<th colspan="1">Tools</th>
								      </tr>
								    </thead>
								    <tbody className={styles.indtbody}>
								    	{indDriver
								    		?
								    		<>
								    			{indDriver.shifts.length > 0
								    				? 
								    				<>
									    				{indDriver.shifts.map((IS) => {
									    					return (
														    	<tr className={styles.sitesind_tr}>
														    		<td colspan="2"><a className={styles.clickable_nav} href={IS.clocked_on_img} target="_blank"><p>{formatDate(IS.clocked_on, false, true)}</p></a></td>
														    		<td colspan="2">{IS.clocked_off ? <a className={styles.clickable_nav} href={IS.clocked_off_img} target="_blank"><p>{formatDate(IS.clocked_off, false, true)} {IS.custom_clocked_off && `(CUSTOM)`}</p></a> : "-"}</td>
														    		<td colspan="2">
														    			{IS.admin_override
														    				? "ADMIN OVERRIDE"
														    				:
														    					<>
																	    			{IS.clocked_off
																	    				? formatDate(IS.clocked_off_logged, false, true)
																	    				: "-"
																	    			}
																    			</>
														    			}
														    		</td>
														    		<td colspan="1" className={styles.toolsFlex}>
														    			<button className={styles.toolsBtn} onClick={() => customShiftEdit('open', IS)}><i className="fa-solid fa-pen"></i></button>
														    			<button className={styles.toolsBtn} onClick={() => indPopUpControls('delete', IS)}><i className="fa-solid fa-trash-can"></i></button>
														    		</td>
														    	</tr>
													    	)
													    })}
												    </>
								    				: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This driver does not have any shifts yet!</h1>
								    			}
								    		</>
								    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>This driver does not have any shifts yet!</h1>
								    	}
								    </tbody>
								</table>
							</div>
						</div>
					}


				</div>
			</ResizableDiv>
		</div>
	)
}

export default PopUpIndDriver;