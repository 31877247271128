import { useEffect, useState, useCallback } from "react";
import styles from "./Warehouse.module.css"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';


const PopUpDelivery = ({ param, popUpPage, popUpControls, validationError, formatOptions, allInventory, handleMenuItem, menuItemArr, handleMenuArr, onDragEndRun, handleSeasonalItem, menuSeasonalItem, seasonalOptions, changeDeliveryNav, deliveryNav, handleNewDeliveryData, newDeliveryData, runOptions, handleRunArr, runArr, handleRunItem, allSites, runOptionsOG, indLoading, searchDeliveryControl, deliveryGroup, setDeliveryGroup }) => {
	const handleDeliveryGroup = (control, ind) => {
		switch (control) {
			case 'toggle':
				if (deliveryGroup) {
					if (deliveryGroup === ind) {
						setDeliveryGroup(null)
					} else {
						setDeliveryGroup(null)
						setDeliveryGroup(ind)
					}
				} else {
					setDeliveryGroup(ind)
				}

				break;
		}
	}

	return (
		<div className={`${popUpPage == 1 && styles.popup_container} ${popUpPage === 2 && styles.popup_container_custom_inv} ${(popUpPage == 3) && styles.fixed_container_delivery}`}>
			{indLoading
				?
					<div className={styles.loadpage_container_white}>
						<div className={styles.load}>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				:
					<>
						<button className={styles.closePopup} onClick={() => popUpControls('close')}><i class="fa-solid fa-x"></i></button>

						{popUpPage == 1 &&
							<>
								<h1 className={styles.medium_font}>Delivery details</h1>
								<p className={styles.smaller_font}>Please enter the specific delivery details to create.</p>

								<div className={styles.popup_form}>
									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Run day</label>
										<Select
											closeMenuOnSelect={true}
											options={[{label: 'Monday', value: 'Monday'}, {label: 'Tuesday', value: 'Tuesday'}, {label: 'Wednesday', value: 'Wednesday'}, {label: 'Thursday', value: 'Thursday'}, {label: 'Friday', value: 'Friday'}, {label: 'Public Holiday', value: 'Public Holiday'}]}
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: 'none !important',
												   '&:hover': {
												       borderColor: '#fce7eb !important'
												    },
													borderColor: state.isFocused ? '#fce7eb' : '#fce7eb',
												}),
											}}
											onChange={(selected) => handleNewDeliveryData("run_day", selected)}
											value={{label: newDeliveryData["run_day"], value: newDeliveryData["run_day"]}}
											isDisabled={newDeliveryData.edit && newDeliveryData.edit}
										/>
									</div>

									<div className={styles.popup_formbox}>
										<label className={styles.smaller_font}>Run number</label>
										<input type="text" value={newDeliveryData && newDeliveryData['run_number']} disabled={true}/>
									</div>
								</div>
							</>
						}

						{popUpPage == 2 &&
							<>
								<h1 className={styles.medium_font}>Delivery details</h1>
								<p className={styles.smaller_font}>Please enter the specific delivery details to create.</p>


								<div className={`${styles.indsite_navnew}`}>
									<div className={styles.sitenav_new}>
										<button className={`${styles.sitenav_box} ${deliveryNav == 'Run' && styles.sitenav_active}`} onClick={() => changeDeliveryNav('Run')}><p>Run</p></button>
										<button className={`${styles.sitenav_box} ${deliveryNav == 'Queue' && styles.sitenav_active}`} onClick={() => changeDeliveryNav('Queue')}><p>Queue</p></button>
									</div>
								</div>

								{deliveryNav == "Run" &&
									<div className={`${styles.single_flex}`}>
										<div className={styles.sites_tablecontainer_new}>
											<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
												<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
													<div className={styles.sites_search}>
														<i className="fa-solid fa-magnifying-glass"></i>
														<input
														    type="text"
														    className={styles.smaller_font}
														    placeholder="Search for any of the column headings..."
														    onKeyUp={(event) => searchDeliveryControl(event)}
														/>
													</div>
												</div>
											</div>

											<div className={`${styles.draggableContainerDelivery}`} style={{marginTop: '10px'}}>
										    	{runArr
										    		?
										    			<>
															<DragDropContext onDragEnd={onDragEndRun}>
																<Droppable droppableId="menuItems">
																	{(provided) => (
																		<div {...provided.droppableProps} ref={provided.innerRef} id="delivery_table">
														    				{runArr.map((IO, idx) => {
																				return (
																					<Draggable key={IO.id} draggableId={IO.id.toString()} index={IO.id} id={IO.id}>
																						{(provided) => (
																							<div ref={provided.innerRef} {...provided.draggableProps}>
																								<div className={`${idx !== runArr.length - 1 && styles.marginedItem}`}>

																									<div className={styles.invoice_item_container} id="deliveryNewModule">
																										<Select
																											closeMenuOnSelect={true}
																											options={formatOptions(Object.keys(runOptions))}
																											styles={{
																												control: (baseStyles, state) => ({
																													...baseStyles,
																													boxShadow: 'none !important',
																													borderWidth: '0 !important',
																													background: 'none !important',
																													minHeight: 'auto !important',
																													width: '50% !important',
																												}),
																											}}
																											onChange={(selected) => handleRunArr("site", selected.value, IO.id)}
																											value={{label: IO.site, value: IO.site}}
																										/>


																										<div className={styles.draggableTools}>
																											<i className="fa-solid fa-arrows-up-down" {...provided.dragHandleProps}></i>
																											<button className={styles.clickable_tools} onClick={() => handleRunItem('delete', IO.id)}><i className={`fa-solid fa-trash-can`}></i></button>

																											<button className={styles.clickable_tools} onClick={() => handleDeliveryGroup("toggle", IO)}>
																												{(deliveryGroup && deliveryGroup.id === IO.id)
																													? <i className="fa-solid fa-chevron-up"></i>
																													: <i className="fa-solid fa-chevron-down"></i>
																												}
																											</button>
																										</div>
																									</div>

																									{(deliveryGroup && deliveryGroup.id === IO.id) &&
																										<div className={styles.invoice_item_info}>
																											<div className={styles.custom_invoice_sub}>
																												<a className={`${styles.medium_font} ${styles.clickable_nav}`} href={`http://maps.google.com/?q=${IO.orders[0].site_location.street}, ${IO.orders[0].site_location.city} ${IO.orders[0].site_location.state} ${IO.orders[0].site_location.postcode}, ${IO.orders[0].site_location.country}`} target="_blank">
																													<p>{`${(IO.orders[0].site_location.hasOwnProperty('department') && IO.orders[0].site_location.department) ? `${IO.orders[0].site_location.department}, ` : ""}${(IO.orders[0].site_location.hasOwnProperty('level') && IO.orders[0].site_location.level) && `${/\d/.test(IO.orders[0].site_location.level) ? "Level " : ""} ${IO.orders[0].site_location.level}, `}${IO.orders[0].site_location.street}, ${IO.orders[0].site_location.city}, ${IO.orders[0].site_location.postcode}, ${IO.orders[0].site_location.state}, ${IO.orders[0].site_location.country}`}</p>
																												</a>
																												<p className={styles.mid_font}>{IO.other}</p>
																											</div>

																											
																											<div className={styles.mb_2}>
																												<table className={`${styles.custom_site_table} ${styles.auto_height}`} id="sites_table">
																												    <thead className={`${styles.sitesind_thead} ${styles.invoice_newthead}`}>
																												      <tr className={styles.table_rowth}>
																												      	<th colspan="1">Order code</th>
																												      	<th colspan="1">Order type</th>
																												      	<th colspan="1">Total qty</th>
																												      </tr>
																												    </thead>


																												    <tbody className={`${styles.indtbody} ${styles.invoice_newtbody}`}>
																														{IO.orders.map((GO, index) => {
																															let orderQuantity = GO.items.flat().reduce((sum, item) => sum + (parseInt(item.quantity) || 0), 0)

																															return (
																																<div className={(index !== IO.orders.length - 1) && styles.invoice_item_grid}>
																																	<tr className={styles.sitesind_tr}>
																																		<td colspan="1"><p className={styles.mid_font}>{GO.order_code}</p></td>
																																		<td colspan="1">{GO.order_type}</td>
																																		<td colspan="1">{orderQuantity} items</td>
																																	</tr>
																																</div>
																															)
																														})}
																													</tbody>
																												</table>
																											</div>
																										</div>
																									}
																								</div>
																							</div>

																						)}
																					</Draggable>
																			    )
																		    })}
																		</div>
																	)}
																</Droppable>
															</DragDropContext>

															<div className={styles.menuControls}>
																<button type="button" className={styles.addBtn} onClick={() => handleRunItem('add')}>
																	<i className="fa-solid fa-plus"></i>
																</button>
															</div>
														</>
										    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>There were no orders found with the configuration applied.</h1>
										    	}
											</div>
										</div>
									</div>
								}



								{deliveryNav == "Queue" &&
									<div className={`${styles.single_flex}`}>
										<div className={styles.sites_tablecontainer_new}>
											<div className={`${styles.sites_controls} ${styles.indsite_controls_wh}`}>
												<div className={`${styles.sites_searchcontainer} ${styles.autowidth}`}>
													<div className={styles.sites_search}>
														<i className="fa-solid fa-magnifying-glass"></i>
														<input
														    type="text"
														    className={styles.smaller_font}
														    placeholder="Search for any of the column headings..."
														    onKeyUp={(event) => searchDeliveryControl(event)}
														/>
													</div>
												</div>
											</div>

											<div className={`${styles.draggableContainerDelivery}`} style={{marginTop: '10px'}}>
										    	{runOptions
										    		?
										    			<div className={styles.invoice_grid_new}>
										    				{Object.keys(runOptions).map((opt, idx) => {
										    					let IO = runOptions[opt][newDeliveryData.run_day]

																return (
																	<div>
																		<div className={styles.invoice_item_container} id="deliveryNewModule">
																			<p>{opt}</p>


																			<div className={styles.draggableTools}>
																				<button className={styles.clickable_tools} onClick={() => handleDeliveryGroup("toggle", opt)}>
																					{(deliveryGroup && deliveryGroup === opt)
																						? <i className="fa-solid fa-chevron-up"></i>
																						: <i className="fa-solid fa-chevron-down"></i>
																					}
																				</button>
																			</div>
																		</div>

																		{(deliveryGroup && deliveryGroup === opt) &&
																			<div className={styles.invoice_item_info}>
																				<div className={styles.custom_invoice_sub}>
																					<a className={`${styles.medium_font} ${styles.clickable_nav}`} href={`http://maps.google.com/?q=${IO[0].site_location.street}, ${IO[0].site_location.city} ${IO[0].site_location.state} ${IO[0].site_location.postcode}, ${IO[0].site_location.country}`} target="_blank">
																						<p>{`${(IO[0].site_location.hasOwnProperty('department') && IO[0].site_location.department) ? `${IO[0].site_location.department}, ` : ""}${(IO[0].site_location.hasOwnProperty('level') && IO[0].site_location.level) ? `${/\d/.test(IO[0].site_location.level) ? "Level " : ""} ${IO[0].site_location.level}, ` : ""}${IO[0].site_location.street}, ${IO[0].site_location.city}, ${IO[0].site_location.postcode}, ${IO[0].site_location.state}, ${IO[0].site_location.country}`}</p>
																					</a>
																				</div>

																				
																				<div className={styles.mb_2}>
																					<table className={`${styles.custom_site_table} ${styles.auto_height}`} id="sites_table">
																					    <thead className={`${styles.sitesind_thead} ${styles.invoice_newthead}`}>
																					      <tr className={styles.table_rowth}>
																					      	<th colspan="1">Order code</th>
																					      	<th colspan="1">Order type</th>
																					      	<th colspan="1">Total qty</th>
																					      </tr>
																					    </thead>


																					    <tbody className={`${styles.indtbody} ${styles.invoice_newtbody}`}>
																							{IO.map((GO, index) => {
																								let orderQuantity = GO.items.flat().reduce((sum, item) => sum + (parseInt(item.quantity) || 0), 0)

																								return (
																									<div className={(index !== IO.length - 1) && styles.invoice_item_grid}>
																										<tr className={styles.sitesind_tr}>
																											<td colspan="1"><p className={styles.mid_font}>{GO.order_code}</p></td>
																											<td colspan="1">{GO.order_type}</td>
																											<td colspan="1">{orderQuantity} items</td>
																										</tr>
																									</div>
																								)
																							})}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		}
																	</div>
																)
															})}
														</div>
										    		: <h1 className={`${styles.medium_font} ${styles.create_first2}`}>There were no orders found with the configuration applied.</h1>
										    	}
											</div>
										</div>
									</div>
								}
							</>
						}

						{validationError &&
							<div className={`${styles.notification} ${styles.validationError} ${styles.customValidation}`}>
								<p className={styles.smaller_font}>{validationError}</p>
							</div>
						}

						<div className={`${styles.popUpControls} ${styles.controls_bottom}`}>
							<div className={styles.popUpControlsContainer}>
					      		{popUpPage != 1 &&
					      			<button type="button" className={styles.prevBtn} onClick={() => popUpControls('prev')}>Back</button>
					      		}

					      		{popUpPage != 2 &&
					      			<button type="button" className={styles.nextBtn} onClick={() => popUpControls('next')}>Next</button>
					      		}

					      		{popUpPage == 2 &&
					      			<button type="button" className={styles.submitBtn} onClick={() => popUpControls('submit')}>Submit</button>
					       		}
				       		</div>
			       		</div>
					</>
			}
		</div>
	)
}

export default PopUpDelivery;